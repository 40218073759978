import { Api } from '@config/api';

export const companyService = {
    create,
    update,
    get,
    getAll,
    getAllDeleted,
    getCapitals,
    remove,
    uploadLogo,
    deleteLogo,
    getLogo,
};

function create(properties) {
    return Api.post('/companies', properties).then(handleResponse);
}

function update(id, properties) {
    return Api.patch(`/companies/${id}`, properties).then(handleResponse);
}

function get(id) {
    return Api.get(`/companies/${id}`).then(handleResponse);
}

function getAll() {
    return Api.get('/companies').then(handleResponse);
}

function getAllDeleted() {
    return Api.get('/companies/deleted').then(handleResponse);
}

function uploadLogo(id, properties) {
    return Api.post(`/companies/${id}/logo`, properties, { headers: { 'Content-Type': 'multipart/form-data' } }).then(handleResponse);
}

function deleteLogo(id) {
    return Api.delete(`/companies/${id}/logo`).then(handleResponse);
}

function getLogo(id) {
    return Api.get(`/companies/${id}/logo`, { responseType: 'blob' }).then(handleResponse);
}

function getCapitals(id) {
    return Api.get(`/companies/${id}/capitals`).then(handleResponse);
}

function remove(id) {
    return Api.delete(`/companies/${id}`).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;

        return Promise.reject(error);
    }
}
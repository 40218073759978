const initialState = {
    deposits: []
}

export default function exportReducer(state = initialState, action) {
    switch (action.type) {

        case 'export/getDepositsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            };
        case 'export/getDepositsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            };
        case 'export/getDepositsSuccess':
            return {
                ...state,
                action: action.type,
                deposits: action.deposits
            };

        case 'export/clearSuccess':
            return {
                ...state,
                action: action.type,
            };
        default:
            return state;
    }
}
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ component: Component, isAuthenticated, name, role, ...rest }) {
    const user = useSelector(state => state.userReducer.userInfos);

    return (
        <Route
            {...rest}
            render={props => (
                user.loggedIn
                ? role.includes(user.role)
                    ? <Component {...props} name={name} key={window.location.pathname} />
                    : <Redirect to='/not-found' />
                :  <Redirect to='/auth/login' />
            )}
        />
    );
}
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { certificationActions } from '@actions/certification.actions';

const typesAvailable = [
    { key: 'Certification APSAD', label: 'Certification APSAD' },
    { key: 'Reserve rapport de visite', label: 'Reserve rapport de visite' },
    { key: 'Maintenance', label: 'Maintenance' },
    { key: 'Bonne pratique', label: 'Bonne pratique' }
]

export default function UpdateCertification(props) {
    const { register, handleSubmit } = useForm();
    const history = useHistory();

    const dispatch = useDispatch();
    const certificationReducer = useSelector(state => state.certificationReducer);
    const certification = props.location.state.certification;
    const [selectedType, setSelectedType] = useState(typesAvailable[0].key);

    const form = {
        title: `Modification du type d'évènement ${certification.label} - ${certification.description}`,
        lines: [
            [
                { label: 'Certification', field: 'label', data: certification.label },
            ],
            [
                { label: 'Commentaire', field: 'comment', data: certification.comment },
            ],
            [
                { label: 'Description', field: 'description', data: certification.description },
            ],
            [
                { label: 'Type', field: 'type', data: selectedType, setData: setSelectedType, type: 'select', options: typesAvailable }
            ]
        ]
    }

    const onSubmit = (properties) => dispatch(certificationActions.update(certification.id, { ...properties, type: selectedType }));
    const onClick = () => history.goBack();

    useEffect(() => {
        if (certificationReducer.action === 'certification/updateSuccess') {
            dispatch(certificationActions.clearSuccess());
            history.goBack();
        }
    }, [certificationReducer, dispatch, history]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 50 }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={form.lines} register={register} />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <SaveButton />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
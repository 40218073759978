import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { reserveActions } from '@actions/reserve.actions';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';

export const NewReserve = ({ setCreate }) => {
    const { register, handleSubmit } = useForm();
    const { eventId, cycleId } = useParams();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const dispatch = useDispatch();
    const reserveReducer = useSelector(state => state.reserveReducer);

    const [priority, setPriority] = useState(1);

    const priorityOptions = [
        { key: 1, label: 'Haute' },
        { key: 2, label: 'Moyenne' },
        { key: 3, label: 'Basse' }
    ]

    const form = {
        title: "Création d'une réserve",
        lines: [
            [
                { label: 'Réserve', field: 'label' }
            ],
            [
                { label: 'Description', field: 'description' }
            ],
            [
                { label: 'Priorité', field: 'priority', type: 'select', options: priorityOptions, data: priority, setData: setPriority }
            ],
            [
                { label: 'Date de réalisation', field: 'start_date', type: 'date' }
            ]
        ]
    }

    const onSubmit = (properties) => {
        setIsButtonDisabled(true);

        properties.status = 0;
        dispatch(reserveActions.create(eventId, cycleId, properties));
    }

    const onClick = () => {
        setCreate(false);
    }

    useEffect(() => {
        if (reserveReducer.action === 'reserve/createSuccess') {
            dispatch(reserveActions.getAll(eventId, cycleId));
        } else if (reserveReducer.action === 'reserve/getAllSuccess') {
            setCreate(false);
        }
    }, [reserveReducer, cycleId, dispatch, eventId, setCreate]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={form.lines} register={register} />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <SaveButton disabled={isButtonDisabled} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
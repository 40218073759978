import React, {useEffect, useState} from 'react';
import CustomTable from '@components/Table/CustomTable';
import { officeActions } from '../../../actions/office.actions';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '@mui/material/Modal';
import { Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { Grid } from '@material-ui/core';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


export default function Capitals(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { companyId, officeId } = props
    const { register, handleSubmit } = useForm();

    const columns = [
        { field: 'building', headerName: 'Bâtiment' },
        { field: 'materials', headerName: 'Matériaux' },
        { field: 'merchandise', headerName: 'Marchandise' },
        { field: 'max', headerName: 'Maximum' },
        { field: 'mean', headerName: 'Moyenne' },
        { field: 'created_at', headerName: 'Date de création', type: 'date' },
    ];

    const newCapital = {
        year: undefined,
        building: undefined,
        merchandise: undefined,
        materials: undefined,
        max: undefined,
        mean: undefined,
    }

    useEffect(() => {
        dispatch(officeActions.getCapital(companyId, officeId))
    }, [dispatch, officeId, companyId])



    useEffect(() => {
        if (props.office.action === 'office/createCapitalsSuccess') {
            handleClose()
        }
    }, [props.office, dispatch]);

    const onSubmit = (properties) => {
        setIsButtonDisabled(true);
        newCapital.building = properties.building ?? undefined
        newCapital.merchandise = properties.merchandise ?? undefined
        newCapital.materials = properties.materials ?? undefined
        newCapital.max = properties.max ? parseInt(properties.max) : undefined
        newCapital.mean = properties.mean ? parseInt(properties.mean) : undefined

        dispatch(officeActions.createCapital(companyId, officeId, newCapital));
    }

    const form = {
        title: "Création du montant de capital",
        lines: [
            [
                { label: 'Bâtiment', field: 'building', data: newCapital.building, required: true },
            ],
            [
                { label: 'Marchandise', field: 'merchandise', data: newCapital.merchandise, required: true },
            ],
            [
                { label: 'Matériels', field: 'materials', data: newCapital.materials, required: true },
            ],
            [
                { label: 'Maximum', field: 'max', data: newCapital.max, required: true },
                { label: 'Moyenne', field: 'mean', data: newCapital.mean, required: true },
            ],
        ]
    }

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={style}>
                        <Grid container direction="column" spacing={2} alignItems="center">
                            <Grid item>
                                <CustomForm title={'Création d\'un montant de capital'} lines={form.lines} register={register} />
                            </Grid>
                            <Grid item>
                                <SaveButton disabled={isButtonDisabled} />
                            </Grid>
                        </Grid>

                    </Box>
                </form>
            </Modal>
            <div style={{ margin: '50px' }}>
                <CustomTable
                    columns={columns}
                    rows={props.office.capitals ?? []}
                    title="Liste des montants de capitaux"
                    onCreate={handleOpen}
                    createLabel={"Créer un montant"}
                />
            </div>
        </>
    )
}

import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { officeActions } from '@actions/office.actions';
import OfficeSubAppBar from '../Office';
import Header from '../Header/Header';

export default function UpdateLease(props) {
    const { register, handleSubmit } = useForm();
    const { companyId, officeId } = useParams();
    const history = useHistory();
    const user = useSelector(state => state.userReducer.userInfos);

    const lease = props.location.state.lease;

    const [keepData] = useState({
        comment: lease?.comment ?? '',
        description: lease?.description ?? '',
    });

    const [options] = useState([
        { key: 0, label: 'Propriétaires avec renonciation à recours réciproques' },
        { key: 1, label: 'Propriétaire sans renonciation à recours' },
        { key: 2, label: 'Agissement pour compte du propriétaire' },
        { key: 3, label: 'Renonciation unilatérale envers le propriétaire' },
        { key: 4, label: 'Renonciation unilatérale envers le locataire' },
        { key: 5, label: 'Locataire sans renonciation à recours' },
        { key: 6, label: 'Locataires avec renonciation à recours réciproques' },
        { key: 7, label: 'Problème contradiction de la clause' },
    ])

    const currentOption = options.find((opt) => opt.label === lease.description)?.key
    const [selectedOption, setSelectedOption] = useState(keepData.description);

    const dispatch = useDispatch();
    const officeReducer = useSelector(state => state.officeReducer);

    const onSubmit = (properties) => {
        properties.comment = properties.comment ?? keepData.comment;
        properties.description = selectedOption.label ?? keepData.description;
        dispatch(officeActions.updateLease(companyId, officeId, lease.id, properties))
    }

    const onClick = () => history.goBack();


    const form = {
        title: "Modification d'un rapport d'un bail",
        lines: [
            [
                { label: 'Date', field: 'comment', type: 'date', data: keepData.comment },
                { label: 'Type', field: 'description', type: 'select-input', setData: setSelectedOption, data: selectedOption, options: options }
            ],
        ]
    }

    useEffect(() => {
        if (officeReducer.action === 'office/updateLeaseSuccess' || user.role !== 'admin')
            history.goBack();
    }, [officeReducer, history, user]);

    return (
        <div>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {form.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '50px 0' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={11} style={{ textAlign: 'center' }}>
                                <CustomForm title={''} lines={form.lines} register={register} />
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 50 }}>
                                <Grid container justifyContent="center">
                                    <SaveButton />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
const initialState = {
    action: '',
    loading: false,
    error: {},
    reserves: []
}

export default function reserveReducer(state = initialState, action) {
    switch (action.type) {
        case 'reserve/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'reserve/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                reserves: state.reserves.concat(action.reserve)
            };
        case 'reserve/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'reserve/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'reserve/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'reserve/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'reserve/removeRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'reserve/removeSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                reserves: [...state.reserves.filter(reserve => reserve.id !== action.id)]
            };
        case 'reserve/removeFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'reserve/getAllRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'reserve/getAllSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                reserves: action.reserves
            };
        case 'reserve/getAllFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'reserve/clearSuccess':
            return {
                ...state,
                action: action.type
            }
        default:
            return state;
    }
}
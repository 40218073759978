import { eventService } from '@services/event.services';
import { alertActions } from '@actions/alert.actions';

export const eventActions = {
    create,
    update,
    updateEvent,
    getEvent,
    getAll,
    getExportedEvents,
    uploadFile,
    getFile,
    close,
    deleteProof,
    remove,
    removeAllNext,
    clearSuccess
};

function create(properties) {
    const request = () => ({ type: 'event/createRequest' });
    const success = (event) => ({ type: 'event/createSuccess', event });
    const failure = (error) => ({ type: 'event/createFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.create(properties)
            .then(event => {
                dispatch(success(event));
                dispatch(alertActions.success("L'événement a bien été créé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la création de cet événement."));
            });
    };
}

function update(eventId, cycleId, properties) {
    const request = () => ({ type: 'event/updateRequest' });
    const success = () => ({ type: 'event/updateSuccess' });
    const failure = (error) => ({ type: 'event/updateFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.update(eventId, cycleId, properties)
            .then(event => {
                dispatch(success(event));
                dispatch(alertActions.success("L'événement a bien été édité."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche l'édition de cet événement."));
            });
    };
}

function updateEvent(eventId, properties) {
    const request = () => ({ type: 'event/updateRequest' });
    const success = () => ({ type: 'event/updateSuccess' });
    const failure = (error) => ({ type: 'event/updateFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.updateEvent(eventId, properties)
            .then(event => {
                dispatch(success(event));
                dispatch(alertActions.success("L'événement a bien été édité."));
            }).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Une erreur empêche l'édition de cet événement."));
        });
    };
}

function remove(eventId, cycleId) {
    const request = () => ({ type: 'event/removeRequest' });
    const success = (id) => ({ type: 'event/removeSuccess', id });
    const failure = (error) => ({ type: 'event/removeFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.remove(eventId, cycleId)
            .then(() => {
                dispatch(success(cycleId));
                dispatch(alertActions.success("L'événement a bien été supprimé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la suppression de cet événement."));
            });
    };
}

function removeAllNext(eventId, properties) {
    const request = () => ({ type: 'event/removeAllNextRequest' });
    const success = () => ({ type: 'event/removeAllNextSuccess' });
    const failure = (error) => ({ type: 'event/removeAllNextFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.removeAllNext(eventId, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("Les événements ont bien été supprimés."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la suppression des événements."));
            });
    };
}

function getEvent(id) {
    const request = () => ({ type: 'event/getRequest' });
    const success = (event) => ({ type: 'event/getSuccess', event });
    const failure = (error) => ({ type: 'event/getFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.getEvent(id)
            .then(event => dispatch(success(event)))
            .catch(error => dispatch(failure(error)));
    };
}

function getAll(companyId, officeId) {
    const request = () => ({ type: 'event/getAllRequest' });
    const success = (events) => ({ type: 'event/getAllSuccess', events });
    const failure = (error) => ({ type: 'event/getAllFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.getAll(companyId, officeId)
            .then(events => dispatch(success(events)))
            .catch(error => dispatch(failure(error)));
    };
}

function getExportedEvents(properties) {
    const request = () => ({ type: 'event/getExportedEventsRequest' });
    const success = (events) => ({ type: 'event/getExportedEventsSuccess', events });
    const failure = (error) => ({ type: 'event/getExportedEventsFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.getExportedEvents(properties)
            .then(events => dispatch(success(events)))
            .catch(error => dispatch(failure(error)));
    };
}

function uploadFile(eventId, cycleId, properties) {
    const request = () => ({ type: 'event/uploadFileRequest' });
    const success = () => ({ type: 'event/uploadFileSuccess' });
    const failure = (error) => ({ type: 'event/uploadFileFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.uploadFile(eventId, cycleId, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("Le fichier a bien été mis en ligne."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la mis en ligne du fichier."));
            });
    };
}

function getFile(eventId, cycleId, fileId) {
    const request = () => ({ type: 'event/getFileRequest' });
    const success = (file) => ({ type: 'event/getFileSuccess', file });
    const failure = (error) => ({ type: 'event/getFileFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.getFile(eventId, cycleId, fileId)
            .then(file => dispatch(success(file)))
            .catch(error => dispatch(failure(error)));
    };
}

function close(eventId, cycleId, properties) {
    const request = () => ({ type: 'event/closeRequest' });
    const success = () => ({ type: 'event/closeSuccess' });
    const failure = (error) => ({ type: 'event/closeFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.close(eventId, cycleId, properties)
            .then(() => dispatch(success()))
            .catch(error => dispatch(failure(error)));
    };
}

function deleteProof(eventId, cycleId, proofId) {
    const request = () => ({ type: 'event/deleteProofRequest' });
    const success = (cycleId, id) => ({ type: 'event/deleteProofSuccess', cycleId, id });
    const failure = (error) => ({ type: 'event/deleteProofFailure', error });

    return dispatch => {
        dispatch(request());
        eventService.deleteProof(eventId, cycleId, proofId)
            .then(() => {
                dispatch(success(cycleId, proofId));
                dispatch(alertActions.success("Le fichier a bien été supprimé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la supression du fichier."));
            });
    };
}

function clearSuccess() {
    return { type: 'event/clearSuccess' };
}
import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';

const useStyles = makeStyles((theme) => ({
    button: {
        background: theme.palette.secondary.main,
        width: 300,
        height: 60,
        fontSize: 20,
        borderRadius: 10,
        marginBottom: 30
    }
}));

function Submit() {
    const classes = useStyles();

    return (
        <Grid>
            <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<LockOpenIcon />}
                type='submit'
            >
                Se connecter
            </Button>
        </Grid>
    );
}

export default Submit;
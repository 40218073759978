import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        margin: '0 0 50px'
    },
    span: {
        color: theme.palette.secondary.main
    }
}));

function Header() {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <h1 className={classes.title}>Bienvenue sur l'<span className={classes.span}>Extranet<br />Risk Management</span></h1>
        </Grid>
    );
}

export default Header;
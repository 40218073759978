import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export default function PublicRoute({ component: Component, restricted, isAuthenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={props => (
                (isAuthenticated && restricted)
                ? <Redirect to='/' />
                : <Component {...props} key={window.location.pathname} />
            )}
        />
    );
}
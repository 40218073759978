import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FAQ from '@assets/FAQ.pdf';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    deconnexion: {
        position: 'absolute',
        top: '50%',
        color: '#F18844',
        transform: 'translateY(-50%)',
    },
    tab: {
        color: 'red',
        background: 'none'
    }
}));

export default function CustomSubAppBar({ items, company, office, user }) {
    const classes = useStyles();

    const indexValue = items.findIndex(item => item.path === window.location.pathname);

    const [value, setValue] = useState(indexValue === -1 ? 1 : indexValue);

    const handleChange = (e, newValue) => setValue(newValue);


    const downloadFaq = () => {
        let link = document.createElement('a');
        link.setAttribute('href', FAQ);
        link.setAttribute('download', 'Présentation Extranet V2.pdf');
        document.body.appendChild(link);
        link.click();
    }

    const getNavItem = (item, office, company, user, index) => {
        if (item.label === "FAQ") {
            return (
                <Tab
                    key={index}
                    selected={false}
                    onClick={downloadFaq}
                    value={index}
                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                    label={item.label}
                />
            )
        }

        return (
            <Tab
                key={index}
                selected={false}
                component={Link}
                value={index}
                to={{
                    pathname: item.path,
                    state: {
                        office: office,
                        company: company,
                        user: user
                    }
                }}
                style={{ color: '#FFFFFF', textDecoration: 'none' }}
                label={item.label}
            />
        );
    }


    return (
        <div className={classes.root}>
            <AppBar position="fixed" style={{ marginTop: 90 }}>
                <Toolbar style={{ height: 90, background: '#1D71B8' }}>
                    <Grid container direction="row" spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid container justifyContent="center">
                            <Tabs
                                indicatorColor='secondary'
                                value={value}
                                onChange={handleChange}
                                centered
                            >
                                {
                                    items.map((item, index) => {
                                        return getNavItem(item, office, company, user, index)
                                    })
                                }
                            </Tabs>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar style={{ height: 90 }} />
        </div>
    );
}
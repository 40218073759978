import React from 'react';
import { Checkbox } from '@material-ui/core';

export default function CheckboxLabels({ item, register, checked, disabled, check, setChecked }) {

    return (
        <>
            <Checkbox
                checked={check}
                onClick={setChecked}
                value={check}
                {...register(item.field)}
                disabled={disabled}
            />
            {item.label}
        </>
    );
}

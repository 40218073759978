import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { Card, Grid, makeStyles } from '@material-ui/core';
import { officeActions } from '@actions/office.actions';
import { companyActions } from '@actions/company.actions';
import isEmpty from '@helpers/isEmpty';

const useStyles = makeStyles(() => ({
    header: {
        margin: 20,
        padding: 20
    },
    logo: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    infosSite: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        '& h1': {
            margin: '5px 0',
            fontWeight: 700,
            fontSize: 30
        },
        '& h2': {
            margin: '5px 0',
            fontWeight: 500,
            fontSize: 25
        },
        '& h3': {
            margin: '5px 0',
            fontWeight: 400,
            fontSize: 20,
            fontStyle: 'italic'
        }
    }
}));

export default function Header() {
    const classes = useStyles();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();
    const companyReducer = useSelector(state => state.companyReducer);
    const company = useSelector(state => state.companyReducer.company);
    const companyLogo = useSelector(state => state.companyReducer.logo);
    const officeReducer = useSelector(state => state.officeReducer);
    const office = useSelector(state => state.officeReducer.office);
    const officeLogo = useSelector(state => state.officeReducer.logo);

    useEffect(() => {
        if (isEmpty(company)) {
            dispatch(companyActions.get(companyId));
            dispatch(officeActions.get(companyId, officeId));
        }
    }, [dispatch, company, companyId, officeId]);

    useEffect(() => {
        if (officeReducer.action === 'office/getSuccess' && !isEmpty(office.logo))
            dispatch(officeActions.getDocument(companyId, officeId, office.logo.id, 'logo'));
        if (companyReducer.action === 'company/getSuccess' && !isEmpty(company.logo))
            dispatch(companyActions.getLogo(companyId));
    }, [dispatch, officeReducer, companyReducer, companyId, office, company, officeId]);

    useEffect(() => {
        if (officeReducer.action === 'office/getDocumentSuccess')
            dispatch(officeActions.clearSuccess());
    }, [dispatch, officeReducer]);

    return (
        <Card className={classes.header}>
            <Grid container justifyContent='center' alignItems='center' style={{ height: '100%' }}>
                {
                    officeLogo instanceof Blob
                    ? <Grid item xs={4} className={classes.logo}>
                        <img src={URL.createObjectURL(officeLogo)} alt='Logo du site' height={130} />
                    </Grid>
                    : <></>
                }
                {
                    office.id
                    ? <Grid item xs={4} className={classes.infosSite}>
                        <h1>{company.name}</h1>
                        <h2>{office.label}</h2>
                        <h3>{office.address.street_number + ' ' + office.address.street_name}</h3>
                    </Grid>
                    : <></>
                }
                {
                    companyLogo instanceof Blob
                    ? <Grid item xs={4} className={classes.logo}>
                        <img src={URL.createObjectURL(companyLogo)} alt='Logo de la société' height={130} />
                    </Grid>
                    : <></>
                }
            </Grid>
        </Card>
    );
}
import { Api } from '@config/api';

export const reserveService = {
    create,
    update,
    remove,
    getAll
};

function create(eventId, cycleId, properties) {
    return Api.post(`/reminders/${eventId}/cycles/${cycleId}/reserves`, properties).then(handleResponse);
}

function update(eventId, properties) {
    return Api.patch(`/reminders/${eventId}`, properties).then(handleResponse);
}

function remove(eventId) {
    return Api.delete(`/reminders/${eventId}`).then(handleResponse);
}

function getAll(eventId, cycleId) {
    return Api.get(`/reminders/${eventId}/cycles/${cycleId}/reserves`).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;

        return Promise.reject(error);
    }
}
import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import CustomTable from '@components/Table/CustomTable';
import { officeActions } from '@actions/office.actions';
import { stringToLocalDate } from '@helpers/formatDate';
import Header from '../Header/Header';
import OfficeSubAppBar from '../Office';

const columns = [
    { field: 'originalname', headerName: 'Nom du fichier' },
    { field: 'file', headerName: 'Fichier', type: 'officeFile' },
    { field: 'description', headerName: 'Type' },
    { field: 'created_at', headerName: 'Date de mise en ligne' },
    { field: 'fileDate', headerName: 'Date du bail' }
];

export default function Leases() {
    const history = useHistory();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();
    const fileInfos = useSelector(state => state.officeReducer.file);
    const officeReducer = useSelector(state => state.officeReducer);
    const leases = useSelector(state => state.officeReducer.office.leases);
    const user = useSelector(state => state.userReducer.userInfos);

    const [fileName, setFileName] = useState(undefined);
    const [files, setFiles] = useState([]);

    const onCreate = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/leases/create`);
    }

    const onDelete = (file) => {
        let newFileArray = [...files];

        setFiles(newFileArray.filter(everyFile => everyFile.id !== file.id));
        dispatch(officeActions.deleteDocument(companyId, officeId, file.id, 'leases',));
    }

    const handleDownload = (file) => {
        dispatch(officeActions.getDocument(companyId, officeId, file.id, 'leases'));
        setFileName(file.originalname);
    };

    useEffect(() => {
        dispatch(officeActions.get(companyId, officeId));
    }, [dispatch, companyId, officeId]);

    useEffect(() => {
        let filesTmp = [];

        if (leases) {
            leases.forEach(file => {
                let limiteDate = new Date(file.created_at);
                const dateAssociated = stringToLocalDate(file.created_at);
                file.created_at = dateAssociated ?? "";
                file.fileDate = stringToLocalDate(file.comment);
                limiteDate.setDate(limiteDate.getDate() + 1);
                if (user.role === 'admin')
                    file.canDelete = true;
                else
                    file.canDelete = limiteDate > new Date();
                filesTmp.push(file);
            });
        }
        setFiles(filesTmp);

    }, [leases, user]);

    useEffect(() => {
        if (officeReducer.action === 'office/getDocumentSuccess'
            && fileName !== undefined) {
            fileDownload(fileInfos, fileName);
            setFileName(undefined)
            dispatch(officeActions.clearSuccess());
        }
    }, [officeReducer, dispatch, fileInfos, fileName]);

    const onUpdate = (click) => {
        history.push({ pathname: `/companies/${companyId}/offices/${officeId}/leases/${click.id}/update`, state: { lease: click } });
    };

    return (
        <div height="800">
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <CustomTable
                columns={columns}
                rows={files}
                title="Liste des baux"
                onCreate={user.role === 'admin' ? onCreate : null}
                createLabel="Ajouter un bail"
                onUpdate={user.role === 'admin' ? onUpdate : null}
                onDelete={user.role === 'admin' ? onDelete : null}
                handleDownload={handleDownload}
            />
        </div>
    );
}
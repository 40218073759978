import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import { IconButton, Popper, Fade, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomTable from '@components/Table/CustomTable';
import { userActions } from '@actions/user.actions';

const useStyles = makeStyles((theme) => ({
    footerButton: {
        position: 'fixed',
        zIndex: 10,
        bottom: 10,
        left: 10,
        background: theme.palette.secondary.light,
        borderRadius: 10,
        '&:hover': {
            background: theme.palette.secondary.main,
        }
    }
}));


const columns = [
    { field: 'lastname', headerName: 'Nom', style: { 'textTransform': 'uppercase' } },
    { field: 'firstname', headerName: 'Prénom', style: { 'textTransform': 'uppercase' } },
    { field: 'phone', headerName: 'Téléphone' },
    { field: 'email', headerName: 'E-mail' }
];


const extractCompaniesIdFromAccessControlList = (accessControlList) => {

    if (accessControlList === null || accessControlList === undefined) {
        return []
    }

    return accessControlList.map((accessControl) => accessControl.company.id)
}

const extractOfficesIdFromAccessControlList = (accessControlList) => {

    if (accessControlList === null || accessControlList === undefined) {
        return []
    }

    let officesId = []

    for (const accessControl of accessControlList) {
        officesId = [...officesId, ...accessControl.offices.map((office) => office.id)]
    }
    return officesId;
}

const haveCommonId = (ids1, ids2) => {

    for (const id of ids1) {
        if (ids2.includes(id)) {
            return true
        }
    }
    return false;
}

const isAdminInList = (admin, list) => {
    const found = list.filter((tmp) => { return tmp.id === admin.id })
    return found.length > 0
}

export default function Footer() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.userInfos);
    const admins = useSelector(state => state.userReducer.admins);
    const companySelected = useSelector(state => state.companyReducer.company);
    const officeSelected = useSelector(state => state.officeReducer.office);
    const [filteredAdmins, setFilteredAdmins] = useState([])
    const handleFooter = (e) => {
        setAnchorEl(e.currentTarget);
        setOpen(!open);
    }

    useEffect(() => {

        if (admins.length === 0) {
            dispatch(userActions.getAllAdmins())
        } else {
            let userCompanies = []
            let userOffices = []
            setFilteredAdmins([])
            if (companySelected && companySelected.id) {
                userCompanies = [companySelected.id]
            } else {
                userCompanies = extractCompaniesIdFromAccessControlList(user.__access_control_list__)
            }

            if (officeSelected && officeSelected.id) {
                userOffices = [officeSelected.id]
            } else {
                userOffices = extractOfficesIdFromAccessControlList(user.__access_control_list__)
            }

            admins.map((admin) => {

                const adminCompanies = extractCompaniesIdFromAccessControlList(admin.__access_control_list__);
                const adminOffices = extractOfficesIdFromAccessControlList(admin.__access_control_list__);

                if (haveCommonId(adminCompanies, userCompanies) && haveCommonId(adminOffices, userOffices)) {

                    if (filteredAdmins.find((tmp) => tmp.id === admin.id)) {
                    } else {
                        setFilteredAdmins([admin, ...filteredAdmins,])
                    }
                } else if (isAdminInList(admin, filteredAdmins)) {
                    const copy = [];
                    copy.map((tmp) => {
                        if (tmp.id !== admin.id) {
                            copy.push(tmp)
                        }
                        return {};
                    })
                    setFilteredAdmins(copy)
                }
                return {}
            })
        }
    }, [admins, companySelected, officeSelected]);

    return (
        <>
            <IconButton onClick={handleFooter} className={classes.footerButton} size='medium'>
                <ImportContactsIcon />
            </IconButton>
            <Popper
                open={open}
                anchorEl={anchorEl}
                transition
                placement="top-start"
                disablePortal={false}
                style={{ zIndex: 10 }}
            >
                {
                    ({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={300}>
                            <Paper style={{ border: '1px solid lightgray' }}>
                                <CustomTable
                                    columns={columns}
                                    rows={filteredAdmins}
                                    title="Liste des Admins IBS"
                                />
                            </Paper>
                        </Fade>
                    )
                }
            </Popper>
        </>
    );
}
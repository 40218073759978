import { Api } from '@config/api';

export const eventService = {
    create,
    update,
    updateEvent,
    remove,
    removeAllNext,
    getEvent,
    getAll,
    getExportedEvents,
    uploadFile,
    getFile,
    close,
    deleteProof
};

function create(properties) {
    return Api.post('/reminders', properties).then(handleResponse);
}

function update(eventId, cycleId, properties) {
        return Api.patch(`/reminders/${eventId}/cycles/${cycleId}`, properties).then(handleResponse);
}

function updateEvent(eventId, properties) {
    return Api.patch(`/reminders/${eventId}`, properties).then(handleResponse);
}
function remove(eventId, cycleId) {
    return Api.delete(`/reminders/${eventId}/cycles/${cycleId}`).then(handleResponse);
}

function removeAllNext(eventId, properties) {
    return Api.delete(`/reminders/${eventId}?startDate=${properties.startDate}&endDate=${properties.endDate}`).then(handleResponse);
}

function getEvent(id) {
    return Api.get(`/reminders/${id}`).then(handleResponse);
}

function getAll(companyId, officeId) {
    return Api.get(`/companies/${companyId}/offices/${officeId}/reminders`).then(handleResponse);
}

function getExportedEvents(properties) {
    return Api.post(`/companies/reminders`, properties).then(handleResponse);
}

function uploadFile(eventId, cycleId, properties) {
    return Api.post(`/reminders/${eventId}/cycles/${cycleId}/proofs`, properties, { headers: { 'Content-Type': 'multipart/form-data' } }).then(handleResponse);
}

function getFile(eventId, cycleId, proofId) {
    return Api.get(`/reminders/${eventId}/cycles/${cycleId}/proofs/${proofId}`, { responseType: 'blob' }).then(handleResponse);
}

function close(eventId, cycleId, properties) {
    return Api.patch(`/reminders/${eventId}/cycles/${cycleId}/close`, properties).then(handleResponse);
}

function deleteProof(eventId, cycleId, proofId) {
    return Api.delete(`/reminders/${eventId}/cycles/${cycleId}/proofs/${proofId}`).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;

        return Promise.reject(error);
    }
}
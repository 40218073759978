import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Divider, TextField, Button, Typography, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { eventActions } from '@actions/event.actions';
import { companyActions } from '@actions/company.actions';
import { officeActions } from '@actions/office.actions';
import { certificationActions } from '@actions/certification.actions';
import formatDate from '@helpers/formatDate';
import isEmpty from '@helpers/isEmpty';
import SelectInput from '@components/Input/SelectInput';

export default function CreateEvent() {
    const { register, handleSubmit, reset } = useForm();
    const { companyId, officeId } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const eventReducer = useSelector(state => state.eventReducer);
    const certificationReducer = useSelector(state => state.certificationReducer);
    const company = useSelector(state => state.companyReducer.company);
    const office = useSelector(state => state.officeReducer.office);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedCertification, setSelectedCertification] = useState('');
    const [priority, setPriority] = useState(1);
    const [frequency, setFrequency] = useState('none');
    const [frequencyBeforeEvent, setFrequencyBeforeEvent] = useState(0);
    const [frequencyAfterEvent, setFrequencyAfterEvent] = useState(0);
    const [keepData] = useState({
        label: undefined,
        certification: undefined,
        priority: [
            { key: 1, label: 'Haute' },
            { key: 2, label: 'Moyenne' },
            { key: 3, label: 'Basse' }
        ],
        start_date: formatDate(new Date()),
        frequency: [
            { key: "none", label: "Aucune" },
            { key: "Hebdomadaire", label: "Hebdomadaire" },
            { key: "Mensuelle", label: "Mensuelle" },
            { key: "Trimestrielle", label: "Trimestrielle" },
            { key: "Semestrielle", label: "Semestrielle" },
            { key: "Annuelle", label: "Annuelle" },
            { key: "2 ans", label: "Tous les 2 ans" },
            { key: "3 ans", label: "Tous les 3 ans" },
            { key: "4 ans", label: "Tous les 4 ans" },
            { key: "5 ans", label: "Tous les 5 ans" },
        ],
        frequency_before_event: [
            { key: 0, label: "Aucune" },
            { key: 7, label: "7 jours avant" },
            { key: 14, label: "14 jours avant" },
            { key: 30, label: "30 jours avant" },
        ],
        frequency_after_event: [
            { key: 0, label: "Aucune" },
            { key: 1, label: "Quotidienne" },
            { key: 7, label: "Hebdomadaire" },
            { key: 30, label: "Mensuelle" },
            { key: 60, label: "Semestrielle" }
        ],
        compulsory_proof: undefined
    });
    const typesAvailable = [
        { key: 'Bonne pratique', label: 'Bonne pratique' },
        { key: 'Maintenance', label: 'Maintenance' },
        { key: 'Reserve rapport de visite', label: 'Réserve de rapport de visite' },
        { key: 'Certification APSAD', label: 'Certification APSAD' }
    ]

    const [eventTypes, setEventTypes] = useState([]);
    const [eventTypesFilter, setEventTypesFilter] = useState([]);
    const [description, setDescription] = useState("");

    const onSubmit = (properties) => {
        setIsButtonDisabled(true);
        const certification = selectedType?.key ? typesAvailable.find(type => type.key === selectedType.key)?.key : null;
        const newEvent = {
            label: description ?? selectedCertification,
            office: parseInt(officeId),
            priority: parseInt(priority),
            start_date: new Date(properties.start_date),
            frequency: frequency,
            frequency_after_event: frequencyAfterEvent,
            frequency_before_event: frequencyBeforeEvent,
            compulsory_proof: properties.compulsory_proof === 'true',
            type: selectedType?.key ? selectedType.key : selectedType,
            certification: Number.isInteger(parseInt(certification)) ? parseInt(certification) : null,
        }
        if (Number.isInteger(parseInt(certification))) {
            newEvent.type = "certification"
        }
        dispatch(eventActions.create(newEvent));
        reset({});
    }
    useEffect(() => {
        if (eventReducer.action === 'event/createSuccess') {
            dispatch(eventActions.clearSuccess());
            history.goBack();
        } else if (eventReducer.action === 'event/createFailure') {
            setIsButtonDisabled(false);
        }
        if (certificationReducer === 'certification/getAllSuccess' || !isEmpty(certificationReducer.certifications)) {
            setEventTypes(certificationReducer.certifications);
        }
    }, [eventReducer, certificationReducer, dispatch, history]);


    const onClick = () => history.goBack();

    const form = {
        title: "",
        lines: [
            [
                { label: 'Société', field: 'company', data: company.name, disabled: true }
            ],
            [
                { label: 'Site', field: 'office', data: office.label, disabled: true }
            ],
            [
                { label: 'Priorité', field: 'priority', data: priority, setData: setPriority, type: 'select', options: keepData.priority }
            ],
            [
                { label: 'Date', field: 'start_date', data: keepData.start_date, type: 'date' }
            ],
            [
                { label: 'Périodicité de l\'événement', field: 'frequency', data: frequency, setData: setFrequency, type: 'select', options: keepData.frequency }
            ],
            [
                { label: 'Périodicité du rappel pré-événement', field: 'frequency_before_event', data: frequencyBeforeEvent, setData: setFrequencyBeforeEvent, type: 'select', options: keepData.frequency_before_event },
                { label: 'Périodicité du rappel post-événement', field: 'frequency_after_event', data: frequencyAfterEvent, setData: setFrequencyAfterEvent, type: 'select', options: keepData.frequency_after_event }
            ],
            [
                { label: 'Preuve obligatoire', field: 'compulsory_proof', data: keepData.compulsory_proof, type: 'boolean' }
            ]
        ]
    }

    useEffect(() => {
        dispatch(certificationActions.getAll());
        dispatch(companyActions.get(companyId));
        dispatch(officeActions.get(companyId, officeId));
    }, [dispatch, companyId, officeId]);

    useEffect(() => {
        if (eventReducer.action === 'event/createSuccess') {
            dispatch(eventActions.clearSuccess());
            history.goBack();
        }
        if (certificationReducer === 'certification/getAllSuccess' || !isEmpty(certificationReducer.certifications)) {
            setEventTypes(certificationReducer.certifications)
        }
    }, [eventReducer, certificationReducer, dispatch, history]);

    useEffect(() => {
        const selectOptions = eventTypes.filter((eventType) => eventType.type === selectedType)
        const options = selectOptions.map((option) => {
            return { key: option.id, label: `${option.label} - ${option.description}` }
        })
        setEventTypesFilter(options)
    }, [selectedType])

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 50 }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center" spacing={4} >

                                <Grid item xs={12}>
                                    <Typography variant="h6" id="tableTitle" component="div">
                                        {"Créer un évènement"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>{"Type d'évènement"}</InputLabel>
                                        <Select value={selectedType} label={"type"} onChange={(e) => setSelectedType(e.target.value)}>
                                            {
                                                typesAvailable.map((option, index) => (<MenuItem key={index} value={option.key}>{option.label}</MenuItem>))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    {
                                        (selectedType === 'Bonne pratique' || selectedType === 'Maintenance' || selectedType === 'Reserve rapport de visite') ?
                                            <SelectInput options={eventTypesFilter} label={"description"} setData={setDescription} value={description} />
                                            :
                                            <FormControl fullWidth>
                                                <InputLabel>{selectedType}</InputLabel>
                                                <Select value={description} label={"type"} onChange={(e) => setDescription(e.target.value)}>
                                                    {
                                                        eventTypesFilter.map((option, index) => (<MenuItem key={index} value={option.label}>{option.label}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                    }
                                </Grid>
                            </Grid>
                            <CustomForm title={''} lines={form.lines} register={register} />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <SaveButton disabled={isButtonDisabled} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
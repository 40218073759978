import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Card, FormControl, InputLabel, Input, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import CustomTable from '@components/Table/CustomTable';
import AdminPanelSubAppBar from '../AdminPanel';
import { Api } from '@config/api';

const useStyles = makeStyles(() => ({
    searchField: {
        width: '20%',
        margin: '30px auto',
        textAlign: 'center'
    }
}));

const columns = [
    { field: 'type', headerName: 'Type' },
    { field: 'label', headerName: 'Certification' },
    { field: 'description', headerName: 'Description' },
    { field: 'comment', headerName: 'Commentaire' },
];

export default function Certifications() {
    const classes = useStyles();
    const history = useHistory();
    const [certifications, setCertifications] = useState([])
    const [certificationsFiltered, setCertificationsFiltered] = useState(undefined);

    useEffect(() => {
        if (certifications.length === 0)
            Api.get('/certifications').then((response) => {
                setCertifications(response.data)
            })
    }, [])

    const filterArray = (e) => {
        let input = e.target.value;
        let newCertifications = [];

        certifications.forEach(certification => {
            for (const [key, value] of Object.entries(certification)) {
                if (['label', 'description'].includes(key)
                    && value.toLowerCase().includes(input.toLowerCase())) {
                    newCertifications.push(certification);
                    break;
                }
            }
        });
        setCertificationsFiltered(newCertifications);
    }

    const onCreate = () => history.push('/admin/certifications/create');

    const onUpdate = (click) => {
        const newCertification = {
            id: click.id,
            label: click.label,
            description: click.description
        }
        history.push({ pathname: '/admin/certifications/update', state: { certification: newCertification } });
    };

    const onDelete = (click) => {
          Api.delete(`/certifications/${click.id}`).then(() => {
            setCertifications(certifications.filter(certification => certification.id !== click.id));
        })
    };

    return (
        <>
            <AdminPanelSubAppBar />
            <div className={classes.searchField}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">Rechercher...</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        onKeyUp={filterArray}
                        endAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <Grid container>
                <Grid item xs={12}>
                    <Card style={{ margin: 20 }}>
                        <CustomTable
                            columns={columns}
                            rows={certificationsFiltered ?? certifications}
                            title="Liste des types d'évènement"
                            onCreate={onCreate}
                            createLabel={"Créer un type d'évènement"}
                            onUpdate={onUpdate}
                            onDelete={onDelete}
                        />
                    </Card>
                </Grid>
            </Grid>
        </>
    );
}
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, CardContent, CardHeader, Divider, FormControl, Grid, makeStyles, Select, MenuItem } from '@material-ui/core';
import { Brightness1 } from '@material-ui/icons';
import { eventActions } from '@actions/event.actions';
import { stringToLocalDate } from '@helpers/formatDate';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    resumeCard: {
        width: '100vw',
        margin: 20,
    },
    resumeInfo: {
        '& > h5': {
            fontSize: 18,
            marginBottom: 10
        },
        '& > h6': {
            fontSize: 18,
            fontWeight: 400,
            color: '#63686f',
            margin: 0,
            marginBottom: 10
        },
    }
}));

const priorityColor = ['#cc0000', '#FF7900', '#4BB543'];

export default function Resume({ event, eventId }) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const office = useSelector(state => state.officeReducer.office);
    const user = useSelector(state => state.userReducer.userInfos);

    const [priority, setPriority] = useState(1);

    const cycleStatus = ['En cours', 'Terminé', 'Sans suite'];
    const start_date = stringToLocalDate(event.start_date);

    const handleChange = (e) => {
        setPriority(e.target.value);
        dispatch(eventActions.update(eventId, undefined, { priority: e.target.value }));
    }

    useEffect(() => {
        if (event.priority)
            setPriority(event.priority);
    }, [event]);

    return (
        <Card className={classes.resumeCard}>
            <CardHeader title="Résumé de l'événement" />
            <CardContent className={classes.resumeContent}>
                <Divider variant='fullWidth' />
                <Grid container>
                    <Grid item xs={6} className={classes.resumeInfo}>
                        <h5>Type d'événement</h5>
                        <h6>{event.label}</h6>
                    </Grid>
                    <Grid item xs={6} className={classes.resumeInfo}>
                        <h5>Priorité</h5>
                        <FormControl>
                            <Select
                                value={priority}
                                onChange={handleChange}
                                disabled={(user.role === 'user' && event.author && event.author.id !== user.id) || user.role === 'viewer'}
                            >
                                <MenuItem value={1}><Brightness1 style={{ color: priorityColor[0] }} /></MenuItem>
                                <MenuItem value={2}><Brightness1 style={{ color: priorityColor[1] }} /></MenuItem>
                                <MenuItem value={3}><Brightness1 style={{ color: priorityColor[2] }} /></MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} className={classes.resumeInfo}>
                        <h5>Nom du site</h5>
                        <h6>{office.label}</h6>
                    </Grid>
                    <Grid item xs={6} className={classes.resumeInfo}>
                        <h5>Date de réalisation prévue</h5>
                        <h6>{start_date}</h6>
                    </Grid>
                    <Grid item xs={6} className={classes.resumeInfo}>
                        <h5>Statuts</h5>
                        <h6>{cycleStatus[event.status]}</h6>
                    </Grid>
                    <Grid item xs={6} className={classes.resumeInfo}>
                        <h5>Fréquence de l'événement</h5>
                        <h6>{event.frequency === 'none' ? 'Aucune' : event.frequency}</h6>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
import React from 'react';

function LegalNotice() {
    return (
        <div>
            <h1 style={{ margin: 50, textAlign: 'center' }}>©2021 I-B-S. Tous droits réservés.</h1>
        </div>
    );
}

export default LegalNotice;
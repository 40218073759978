const initialState = {
    action: '',
    loading: false,
    error: {},
    office: {
        visit_reports: [],
        visit_plans: [],
        fire_permits: [],
        leases: [],
    },
    capitals: [],
    deposits: [],
    deposit: {},
    offices: [],
    logo: {},
    picture: {},
    file: {},
};

export default function officeReducer(state = initialState, action) {
    switch (action.type) {
        case 'office/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'office/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                office: action.office
            };
        case 'office/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'office/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'office/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                office: action.office
            };
        case 'office/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'office/getRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'office/getSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                office: action.office
            };
        case 'office/getFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'office/uploadDocumentRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'office/uploadDocumentSuccess':
            return {
                ...state,
                action: action.type,
                loading: false
            };
        case 'office/uploadDocumentFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'office/deleteDocumentRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'office/deleteDocumentSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                logo: action.fileType === 'logo' ? {} : state.logo,
                picture: action.fileType === 'picture' ? {} : state.picture,
                file: action.fileType !== 'picture' && action.fileType !== 'logo' ? {} : state.file,
            };
        case 'office/deleteDocumentFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'office/getDocumentRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'office/getDocumentSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                logo: action.fileType === 'logo' ? action.document : state.logo,
                picture: action.fileType === 'picture' ? action.document : state.picture,
                file: action.fileType !== 'picture' && action.fileType !== 'logo' ? action.document : state.file,
            };
        case 'office/getDocumentFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                logo: action.fileType === 'logo' ? {} : state.logo,
                picture: action.fileType === 'picture' ? {} : state.picture,
                file: action.fileType !== 'picture' && action.fileType !== 'logo' ? {} : state.file,
            };

        case 'office/getCapitalsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                capitals: []
            }
        case 'office/getCapitalsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                capitals: action.capitals
            }
        case 'office/getCapitalsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                capitals: []
            }
        case 'office/createCapitalsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/createCapitalsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                capitals: [...state.capitals, action.capital]
            }
        case 'office/createCapitalsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/createDepositsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/createDepositsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                deposits: [...state.deposits, action.deposit],
                deposit: action.deposit
            }
        case 'office/createDepositsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/getDepositsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                deposits: []
            }
        case 'office/getDepositsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                deposits: action.deposits
            }
        case 'office/getDepositsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                capitals: []
            }

        case 'office/deleteDepositsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/deleteDepositsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                deposits: [...state.deposits.filter(deposit => deposit.id !== action.id)]
            }
        case 'office/deleteDepositsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/updateDepositsRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/updateDepositsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/updateDepositsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/uploadWaiverRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/uploadWaiverSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                status: true,
                error: {},
            }
        case 'office/uploadWaiverFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/downloadWaiverRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/downloadWaiverSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                status: true,
                error: {},
                file: action.document,
            }
        case 'office/downloadWaiverFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/downloadWaiverTemplateRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/downloadWaiverTemplateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                status: true,
                error: {},
                file: action.document,
            }
        case 'office/downloadWaiverTemplateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/updateLeaseRequest':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
            }
        case 'office/updateLeaseSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                office: {
                    ...state.office,
                    leases: [...state.office.leases, action.lease]
                },
            }
        case 'office/updateLeaseFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            }

        case 'office/clearSuccess':
            return {
                ...state,
                action: action.type,
            };
        default:
            return state;
    }
}
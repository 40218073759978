import { exportService } from '@services/export.services';

export const exportActions = {
    getExportedDeposits,
    clearSuccess,
};

function getExportedDeposits(properties) {
    const request = () => ({ type: 'export/getDepositsRequest' });
    const success = (deposits) => ({ type: 'export/getDepositsSuccess', deposits });
    const failure = (error) => ({ type: 'export/getDepositsFailure', error });

    return dispatch => {
        dispatch(request());
        exportService.getExportedDeposits(properties)
            .then(deposits => dispatch(success(deposits)))
            .catch(error => dispatch(failure(error)));
    };
}

function clearSuccess() {
    return { type: 'company/clearSuccess' };
}
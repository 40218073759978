export default function formatDate(date) {
    let d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}

/**
 * Convert string to local date string
 * @param {String} str Date string
 * @returns date string in locale format
 */
export function stringToLocalDate(str) {
    const options = { weekday: 'short', day: 'numeric', year: 'numeric', month: 'long' };

    return new Date(str).toLocaleDateString('fr-Fr', options);
}
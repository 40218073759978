import React from 'react';
import CustomSubAppBar from '@components/AppBar/CustomSubAppBar';

export default function AdminPanelSubAppBar() {
    const items = [
        { label: 'Gestion des utilisateurs', path: '/admin/users' },
        { label: 'Gestion des types d\'événements', path: '/admin/certifications' },
        {label : 'Gestion des sociétés inactives', path: '/admin/company-disabled'}
    ];

    return (<CustomSubAppBar items={items} />);
}
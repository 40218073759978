import { officeService } from '@services/office.services';
import { alertActions } from './alert.actions';

export const officeActions = {
    create,
    update,
    get,
    uploadDocument,
    getDocument,
    deleteDocument,
    getCapital,
    createCapital,
    getDeposits,
    createDeposit,
    clearSuccess,
    deleteDeposit,
    updateDeposit,
    downloadWaiver,
    uploadWaiver,
    updateLease,
    downloadTemplateWaiver
};

function create(companyId, properties) {
    const request = () => ({ type: 'office/createRequest' });
    const success = (file) => ({ type: 'office/createSuccess', file });
    const failure = (error) => ({ type: 'office/createFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.create(companyId, properties)
            .then(office => {
                dispatch(success(office));
                dispatch(alertActions.success(`Le site '${office.label}' a bien été créé.`));
            }).catch(error => {
                let messageError = 'Une erreur empêche la création du site.';
                let messageResponse = error.response.data.message;

                dispatch(failure(error));
                if (Array.isArray(messageResponse)) {
                    messageResponse.some(message => {
                        if (message.includes('email')) {
                            messageError = 'L\'e-mail de la personne de contact n\'est pas valide';
                            return true;
                        } else
                            return false;
                    });
                } else if (messageResponse.includes('email'))
                    messageError = 'L\'e-mail de la personne de contact n\'est pas valide';
                dispatch(alertActions.error(messageError));
            });
    };
}

function update(companyId, officeId, properties) {
    const request = () => ({ type: 'office/updateRequest' });
    const success = (office) => ({ type: 'office/updateSuccess', office });
    const failure = (error) => ({ type: 'office/updateFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.update(companyId, officeId, properties)
            .then(office => {
                dispatch(success(office));
                dispatch(alertActions.success(`Le site '${office.label}'' a bien été modifié.`));
            }).catch(error => {
                let messageError = 'Une erreur empêche la modification du site.';
                let messageResponse = error.response.data.message;

                dispatch(failure(error));
                if (Array.isArray(messageResponse)) {
                    messageResponse.some(message => {
                        if (message.includes('email')) {
                            messageError = 'L\'e-mail de la personne de contact n\'est pas valide';
                            return true;
                        } else
                            return false;
                    });
                } else if (messageResponse.includes('email'))
                    messageError = 'L\'e-mail de la personne de contact n\'est pas valide';
                dispatch(alertActions.error(messageError));
            });
    };
}

function get(companyId, officeId) {
    const request = () => ({ type: 'office/getRequest' });
    const success = (office) => ({ type: 'office/getSuccess', office });
    const failure = (error) => ({ type: 'office/getFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.get(companyId, officeId)
            .then(office => dispatch(success(office)))
            .catch(error => dispatch(failure(error)));
    };
}

function uploadDocument(companyId, officeId, type, properties) {
    const request = () => ({ type: 'office/uploadDocumentRequest' });
    const success = () => ({ type: 'office/uploadDocumentSuccess' });
    const failure = (error) => ({ type: 'office/uploadDocumentFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.uploadDocument(companyId, officeId, type, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("Le document a bien été mis en ligne."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la mise en ligne du document."));
            });
    };
}

function getDocument(companyId, officeId, fileId, fileType) {
    const request = () => ({ type: 'office/getDocumentRequest' });
    const success = (document, fileType) => ({ type: 'office/getDocumentSuccess', document, fileType });
    const failure = (error, fileType) => ({ type: 'office/getDocumentFailure', error, fileType });

    return dispatch => {
        dispatch(request());
        officeService.getDocument(companyId, officeId, fileId, fileType)
            .then(document => dispatch(success(document, fileType)))
            .catch(error => dispatch(failure(error, fileType)));
    };
}

function deleteDocument(companyId, officeId, fileId, fileType) {
    const request = () => ({ type: 'office/deleteDocumentRequest' });
    const success = (fileType) => ({ type: 'office/deleteDocumentSuccess', fileType });
    const failure = (error) => ({ type: 'office/deleteDocumentFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.deleteDocument(companyId, officeId, fileId, fileType)
            .then(() => {
                dispatch(success(fileType));
                dispatch(alertActions.success("Le document a bien été supprimé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la supression du document."));
            });
    };
}

function getCapital(officeId) {
    const request = () => ({ type: 'office/getCapitalsRequest' });
    const success = (capitals) => ({ type: 'office/getCapitalsSuccess', capitals });
    const failure = (error) => ({ type: 'office/getCapitalsFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.getCapital(officeId)
            .then(capitals => dispatch(success(capitals)))
            .catch(error => dispatch(failure(error)));
    };
}

function createCapital(companyId, officeId, properties) {
    const request = () => ({ type: 'office/createCapitalsRequest' });
    const success = (capital) => ({ type: 'office/createCapitalsSuccess', capital });
    const failure = (error) => ({ type: 'office/createCapitalsFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.createCapital(companyId, officeId, properties)
            .then(capital => dispatch(success(capital)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Les champs 'maximum' et 'moyenne' doivent être des nombres valide."));
            });
    };
}

function getDeposits(companyId, officeId) {
    const request = () => ({ type: 'office/getDepositsRequest' });
    const success = (deposits) => ({ type: 'office/getDepositsSuccess', deposits });
    const failure = (error) => ({ type: 'office/getDepositsFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.getDeposits(companyId, officeId)
            .then(deposits => dispatch(success(deposits)))
            .catch(error => dispatch(failure(error)));
    };
}

function createDeposit(companyId, officeId, properties) {
    const request = () => ({ type: 'office/createDepositsRequest' });
    const success = (deposit) => ({ type: 'office/createDepositsSuccess', deposit });
    const failure = (error) => ({ type: 'office/createDepositsFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.createDeposit(companyId, officeId, properties)
            .then(deposit => dispatch(success(deposit)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors de la création de la marchandise."));
            });
    };
}

function deleteDeposit(companyId, officeId, id) {
    const request = () => ({ type: 'office/deleteDepositsRequest' });
    const success = (id) => ({ type: 'office/deleteDepositsSuccess', id });
    const failure = (error) => ({ type: 'office/deleteDepositsFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.deleteDeposit(companyId, officeId, id)
            .then(() => {
                dispatch(success(id))
            })
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors de la suppression de la marchandise."));
            });
    };
}

function updateLease(companyId, officeId, id, properties) {
    const request = () => ({ type: 'office/updateLeaseRequest' });
    const success = (lease) => ({ type: 'office/updateLeaseSuccess', lease });
    const failure = (error) => ({ type: 'office/updateLeaseFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.updateLease(companyId, officeId, id, properties)
            .then(lease => dispatch(success(lease)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors de la modification du bail."));
            });
    };
}

function updateDeposit(companyId, officeId, id, properties) {
    const request = () => ({ type: 'office/updateDepositsRequest' });
    const success = (deposit) => ({ type: 'office/updateDepositsSuccess', deposit });
    const failure = (error) => ({ type: 'office/updateDepositsFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.updateDeposit(companyId, officeId, id, properties)
            .then(deposit => dispatch(success(deposit)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors de la modification de la marchandise."));
            });
    };
}

function uploadWaiver(companyId, officeId, id, file) {

    const request = () => ({ type: 'office/uploadWaiverRequest' });
    const success = (status) => ({ type: 'office/uploadWaiverSuccess', status });
    const failure = (error) => ({ type: 'office/uploadWaiverFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.uploadWaiver(companyId, officeId, id, file)
            .then(status => dispatch(success(status)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors de l'upload de la renonciation."));
            });
    };
}

function downloadWaiver(companyId, officeId, id) {
    const request = () => ({ type: 'office/downloadWaiverRequest' });
    const success = (document) => ({ type: 'office/downloadWaiverSuccess', document });
    const failure = (error) => ({ type: 'office/downloadWaiverFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.downloadWaiver(companyId, officeId, id)
            .then((document) => dispatch(success(document)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors du téléchargement de la renonciation."));
            });
    };
}

function downloadTemplateWaiver(companyId, officeId, depositId) {
    const request = () => ({ type: 'office/downloadWaiverTemplateRequest' });
    const success = (document) => ({ type: 'office/downloadWaiverTemplateSuccess', document });
    const failure = (error) => ({ type: 'office/downloadWaiverTemplateFailure', error });

    return dispatch => {
        dispatch(request());
        officeService.downloadTemplateWaiver(companyId, officeId, depositId)
            .then((document) => dispatch(success(document)))
            .catch(error => {
                dispatch(failure(error))
                dispatch(alertActions.error("Une erreur est survenue lors du téléchargement du template de renonciation."));
            });
    };
}

function clearSuccess() {
    return { type: 'office/clearSuccess' };
}
import React from 'react';
import { useHistory } from 'react-router';
import { Button, Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import loginBG from '@assets/Img/loginBG.jpg'

const useStyles = makeStyles(() => ({
    pageContent: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundImage: `url(${loginBG})`,
        backgroundSize: 'cover',
        alignItems: 'center'
    },
    card: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        width: '60%',
        height: '60%',
        background: '#fff',
        borderRadius: 10
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    button: {
        textDecoration: 'none',
        '& > Button': {
            width: 300,
            height: 60,
            fontSize: 18,
            marginTop: 40
        }
    }
}));

export default function NotFound() {
    const classes = useStyles();
    const history = useHistory();

    const goBack = () => history.goBack();

    return (
        <div className={classes.pageContent}>
            <Card className={classes.card}>
                <CardHeader titleTypographyProps={{ variant:'h2' }} title='Page introuvable' />
                <CardContent className={classes.cardContent}>
                    <Typography variant="h6" color="textSecondary" component="p">
                        Il semblerait que la page que vous cherchiez n'existe pas.
                    </Typography>
                    <div className={classes.button}>
                        <Button variant='outlined' color='secondary' onClick={goBack}>
                            Revenir à la page précédente
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
import React from 'react';
import { Grid, Divider, TextField, Button, Typography, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import CheckBox from '@components/Input/CheckBox';
import SelectInput from '@components/Input/SelectInput';
import InputAdornment from '@mui/material/InputAdornment';
import MultipleSelectChip from '../Input/SelectChipInput';

export const CustomForm = ({ title, lines, register, handleFileChanged, onChange }) => {
    const LineItem = ({ item }) => {
        switch (item.type) {
            case 'date':
                return <TextField
                    id="date"
                    label={item.label}
                    type="date"
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    defaultValue={item.data}
                    {...register(item.field)}
                    onChange={onChange}
                    required
                />
            case 'select':
                return <FormControl fullWidth>
                    <InputLabel>{item.label}</InputLabel>
                    <Select
                        value={item.data}
                        label={item.label}
                        onChange={(e) => item.setData(e.target.value)}
                    >
                        {
                            item.options.map((option, index) => (
                                <MenuItem key={index} value={option.key}>{option.label}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            case 'select-input':
                return <SelectInput options={item.options} label={item.label} setData={item.setData} value={item.data} />
            case 'message':
                return <TextField
                    label={item.label}
                    fullWidth
                    multiline
                    rows={6}
                    variant="outlined"
                    {...register(item.field)}
                />
            case 'boolean':
                return <div style={{ textAlign: 'center' }}>
                    <CheckBox
                        item={item}
                        register={register}
                        checked={item.data}
                        disabled={item.disabled}
                        check={item.data}
                        setChecked={item.setData}
                    />
                </div>
            case 'file':
                if (!item.data) {
                    return <label htmlFor='file'>
                        <input
                            style={{ display: 'none' }}
                            id='file'
                            multiple
                            type='file'
                            onChange={handleFileChanged}
                        />
                        <Button
                            component='span'
                            variant='outlined'
                            color='secondary'
                            style={{ width: 350, height: 80 }}
                            startIcon={<CloudUpload />}
                        >
                            Déposer un fichier
                        </Button>
                    </label>
                } else {
                    return <div>
                        <h1>{item.data.name}</h1>
                        <img src={window.URL.createObjectURL(item.data)} height={300} alt="Prévisualisation du fichier" />
                    </div>
                }
            case 'euro':
                return <TextField
                    label={item.label}
                    color="secondary"
                    defaultValue={item.data}
                    variant="outlined"
                    required={item.required}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                    fullWidth={true}
                    disabled={item.disabled}
                    {...register(item.field)}
                    onChange={onChange}
                />

            case 'multiple-select':
                return <MultipleSelectChip choices={item.options} label={item.label} selectedChoices={item.data} handleChange={item.onChange} />

            default:
                return <TextField
                    label={item.label}
                    color="secondary"
                    defaultValue={item.data}
                    variant="outlined"
                    required={item.required}
                    fullWidth={true}
                    disabled={item.disabled}
                    {...register(item.field)}
                    // onChange={onChange}
                />
        }
    }

    const FormLine = ({ line }) => {
        const xs = Math.ceil(12 / line.length);

        return (
            line.map((item, index) => {
                return (
                    <Grid key={index} item xs={xs}>
                        <LineItem key={index} item={item} />
                    </Grid>
                );
            })
        );
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" id="tableTitle" component="div">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={2}>
                {
                    lines.map((line, index) => {
                        return <FormLine line={line} key={index} />
                    })
                }
            </Grid>
        </Grid>
    );
}

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card, FormControl, InputLabel, Input, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search, FormatListBulleted } from '@material-ui/icons';
import CustomTable from '@components/Table/CustomTable';
import DeleteUser from '@components/Modal/DeleteUser';
import { userActions } from '@actions/user.actions';
import AdminPanelSubAppBar from '../AdminPanel';
import LinkUser from './LinkUser';
import LinkAdmin from './LinkAdmin';

const useStyles = makeStyles(() => ({
    searchField: {
        width: '20%',
        margin: '30px auto',
        textAlign: 'center'
    }
}));

const columns = [
    { field: 'lastname', headerName: 'Nom', style: { 'textTransform': 'uppercase' } },
    { field: 'firstname', headerName: 'Prénom', style: { 'textTransform': 'Capitalize' } },
    { field: 'email', headerName: 'E-mail', style: { 'textTransform': 'lowercase' } },
    { field: 'phone', headerName: 'Téléphone' },
    { field: 'role', headerName: 'Rôle', type: 'role' },
    { field: 'companies', headerName: 'Sociétés', type: 'list', style: { 'textTransform': 'uppercase' } },
    { field: 'offices', headerName: 'Sites', type: 'list', style: { 'textTransform': 'uppercase' } },
    { field: 'soft_deleted', headerName: 'Actif', type: 'switch' },
    { field: 'link', headerName: '', type: 'custom' },
];

export default function Users() {
    const classes = useStyles();
    const history = useHistory();

    const [openLinkUser, setOpenLinkUser] = useState(false);
    const [openLinkAdmin, setOpenLinkAdmin] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [newUsers, setNewUsers] = useState([]);
    const [userSelected, setUserSelected] = useState({ __access_control_list__: [] });
    const [newUsersFiltered, setNewUsersFiltered] = useState(undefined);

    const dispatch = useDispatch();
    const users = useSelector(state => state.userReducer.users);
    const userReducer = useSelector(state => state.userReducer);


    const onLink = (user) => {
        setUserSelected(user);
        setOpenLinkUser(true);
    };

    const onUserDisabledList = () => {
        history.push(`/admin/users-disabled`);
    }

    const handleChangeRole = (e, user) => {
        dispatch(userActions.update(user.id, { role: e.target.value }));
    }

    const filterArray = (e) => {
        let input = e.target.value;
        let newUsersTmp = [];

        newUsers.forEach(user => {
            for (const [key, value] of Object.entries(user)) {
                if (['lastname', 'firstname', 'email', 'phone', 'role'].includes(key)
                    && value.toLowerCase().includes(input.toLowerCase())) {
                    newUsersTmp.push(user);
                    break;
                } else if (['companies', 'offices'].includes(key) &&
                    value.some(string => string.toLowerCase().includes(input.toLowerCase()))) {
                    newUsersTmp.push(user);
                    break;
                }
            }
        });
        setNewUsersFiltered(newUsersTmp);
    }

    const onCreate = () => history.push('/admin/users/create');

    const onUpdate = (click) => {
        const user = {
            id: click.id,
            lastname: click.lastname,
            firstname: click.firstname,
            phone: click.phone,
            email: click.email,
            mailing_before_event_medium: click.mailing_before_event_medium ?? false,
            mailing_before_event_low: click.mailing_before_event_low ?? false,
            mailing_before_event_high: click.mailing_before_event_high ?? false,
            mailing_after_event_high: click.mailing_after_event_high ?? false,
            mailing_after_event_medium: click.mailing_after_event_medium ?? false,
            mailing_after_event_low: click.mailing_after_event_low ?? false,
            mailing_d_day_high: click.mailing_d_day_high ?? false,
            mailing_d_day_medium: click.mailing_d_day_medium ?? false,
            mailing_d_day_low: click.mailing_d_day_low ?? false,
            mailing_before_maintenance_low: click.mailing_before_maintenance_low ?? false,
            mailing_before_maintenance_medium: click.mailing_before_maintenance_medium ?? false,
            mailing_before_maintenance_high: click.mailing_before_maintenance_high ?? false,
            mailing_after_maintenance_low: click.mailing_after_maintenance_low ?? false,
            mailing_after_maintenance_medium: click.mailing_after_maintenance_medium ?? false,
            mailing_after_maintenance_high: click.mailing_after_maintenance_high ?? false,
            mailing_d_day_maintenance_low: click.mailing_d_day_maintenance_low ?? false,
            mailing_d_day_maintenance_medium: click.mailing_d_day_maintenance_medium ?? false,
            mailing_d_day_maintenance_high: click.mailing_d_day_maintenance_high ?? false,
            mailing_before_apsad_low: click.mailing_before_apsad_low ?? false,
            mailing_before_apsad_medium: click.mailing_before_apsad_medium ?? false,
            mailing_before_apsad_high: click.mailing_before_apsad_high ?? false,
            mailing_after_apsad_low: click.mailing_after_apsad_low ?? false,
            mailing_after_apsad_medium: click.mailing_after_apsad_medium ?? false,
            mailing_after_apsad_high: click.mailing_after_apsad_high ?? false,
            mailing_d_day_apsad_low: click.mailing_d_day_apsad_low ?? false,
            mailing_d_day_apsad_medium: click.mailing_d_day_apsad_medium ?? false,
            mailing_d_day_apsad_high: click.mailing_d_day_apsad_high ?? false,
            mailing_before_reserve_low: click.mailing_before_reserve_low ?? false,
            mailing_before_reserve_medium: click.mailing_before_reserve_medium ?? false,
            mailing_before_reserve_high: click.mailing_before_reserve_high ?? false,
            mailing_after_reserve_low: click.mailing_after_reserve_low ?? false,
            mailing_after_reserve_medium: click.mailing_after_reserve_medium ?? false,
            mailing_after_reserve_high: click.mailing_after_reserve_high ?? false,
            mailing_d_day_reserve_low: click.mailing_d_day_reserve_low ?? false,
            mailing_d_day_reserve_medium: click.mailing_d_day_reserve_medium ?? false,
            mailing_d_day_reserve_high: click.mailing_d_day_reserve_high ?? false,
        }

        history.push({ pathname: '/admin/users/update', state: { user: user } });
    }

    const handleChangeWorking = (e, user) => {
        dispatch(userActions.remove(user.id));
    }

    useEffect(() => {
        if (users.length === 0)
            dispatch(userActions.getAll());
    }, []);

    useEffect(() => {
        let newUsersTmp = [];

        users.forEach((user) => {
            user.link = <Button onClick={(e) => { onLink(user) }} color="secondary" variant='outlined'>Lier un site</Button>;
            user.companies = [];
            user.offices = [];
            user.__access_control_list__.forEach(list => {
                user.companies.push(list.company.name);
                user.offices.push(list.offices.map(office => office.label).join(', '));
            });
            newUsersTmp.push(user);
        });
        setNewUsers(newUsersTmp);
    }, [users]);

    useEffect(() => {
        if (userReducer.action === 'user/updateSuccess')
            dispatch(userActions.getAll());
    }, [dispatch, userReducer]);

    return (
        <>
            <AdminPanelSubAppBar />
            <LinkUser open={openLinkUser} setOpen={setOpenLinkUser} user={userSelected} />
            <LinkAdmin open={openLinkAdmin} setOpen={setOpenLinkAdmin} user={userSelected} />
            <div style={{ textAlign: 'center' }}>
                <Button
                    variant='outlined'
                    startIcon={<FormatListBulleted />}
                    component='div'
                    style={{ height: 60, width: 300, marginTop: 20 }}
                    color='secondary'
                    onClick={onUserDisabledList}
                >
                    Voir la liste d'utilisateurs inactifs
                </Button>
            </div>
            <div className={classes.searchField}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">Rechercher...</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        onKeyUp={filterArray}
                        endAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <Card style={{ margin: 20 }}>
                <CustomTable
                    columns={columns}
                    rows={newUsersFiltered ?? newUsers}
                    title="Liste des utilisateurs"
                    onCreate={onCreate}
                    createLabel={"Créer un utilisateur"}
                    onUpdate={onUpdate}
                    handleChangeRole={handleChangeRole}
                    handleChangeWorking={handleChangeWorking}
                />
            </Card>
            <DeleteUser
                open={openDelete}
                setOpen={setOpenDelete}
                user={userSelected}
            />
        </>
    );
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { companyActions } from '@actions/company.actions';
import { officeActions } from '@actions/office.actions';
import Loading from '@components/Loading/Loading';
import isEmpty from '@helpers/isEmpty';
import Company from './Company/Company';
import Office from './Office/Office';
import Users from './Users/Users';
import OfficeSubAppBar from '../Office';
import { Api } from '@config/api';

const useStyles = makeStyles(() => ({
    container: {
        margin: 20
    }
}));

function Infos() {
    const classes = useStyles();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();

    const companyReducer = useSelector(state => state.companyReducer);
    const company = useSelector(state => state.companyReducer.company);

    const officeReducer = useSelector(state => state.officeReducer);
    const office = useSelector(state => state.officeReducer.office);

    const officePicture = useSelector(state => state.officeReducer.picture);
    const userReducer = useSelector(state => state.userReducer);

    const [users, setUsers] = React.useState([]);
    const [passedUsers, setPassedUsers] = React.useState(false);

    useEffect(() => {
        if (passedUsers === false)
            Api.get(`/companies/${companyId}/offices/${officeId}/users`).then(({ data }) => {
                setUsers(data)
                setPassedUsers(true)
            }).catch((err) => {
                setUsers([])
                setPassedUsers(true)
            })
    }, [users, setUsers])

    const [companyLogo, setCompanyLogo] = React.useState({});
    const [passedCompanyLogo, setPassedCompanyLogo] = React.useState(false);

    useEffect(() => {
        if (passedCompanyLogo === false) {
            Api.get(`/companies/${companyId}/logo`, { responseType: 'blob' }).then(({ data }) => {
                setCompanyLogo(data)
                setPassedCompanyLogo(true)
            }).catch((err) => {
                setCompanyLogo({})
                setPassedCompanyLogo(true)
            })
        }
    }, [companyLogo, setCompanyLogo])


    const [officeLogo, setOfficeLogo] = React.useState({})
    const [passed, setPassed] = React.useState(false)

    useEffect(() => {
        if (passed === false)
            Api.get(`/companies/${companyId}/offices/${officeId}`).then((response) => {
                const office = response.data
                Api.get(`/companies/${companyId}/offices/${officeId}/documents/${office?.logo?.id}?type=logo`, { responseType: 'blob' }).then(({ data }) => {
                    setOfficeLogo(data)
                    setPassed(true)
                }).catch((err) => {
                    setOfficeLogo({})
                    setPassed(true)
                })
            })
    }, [officeLogo, setOfficeLogo])


    useEffect(() => {
        if (company === undefined || company === {} || company?.id !== companyId) {
            dispatch(companyActions.get(companyId));
        }
        if (office === undefined || office === {} || office.id !== officeId) {
            dispatch(officeActions.get(companyId, officeId));
        }
    }, [dispatch, companyId, officeId]);

    return (
        <div>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Card className={classes.container}>
                {
                    userReducer.loading || companyReducer.loading || officeReducer.loading
                        || isEmpty(company) || isEmpty(office)
                        ? <Loading />
                        : <>
                            <Company company={company} logo={companyLogo} />
                            <Office company={company} office={office} logo={officeLogo} picture={officePicture} />
                            <Users users={users} />
                        </>
                }
            </Card>
        </div>
    );
}

export default Infos;
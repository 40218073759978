import React from 'react';
import { useHistory } from 'react-router';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@mui/material';
import { Check, AttachEmail } from '@mui/icons-material';
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    data: {
        display: 'flex',
        justifyContent: 'center',
        width: '80%',
        margin: 'auto',
        '& > h6': {
            fontSize: 18,
            fontWeight: 'normal',
            margin: 10
        }
    }
}));

export default function PasswordModal({ open, setOpen, user }) {
    const classes = useStyles();
    const history = useHistory();

    const ownUser = useSelector(state => state.userReducer.userInfos);

    const handleClose = () => {
        setOpen(false);
        history.goBack();
    }

    const downloadTemplate = () => {

        if (document.getElementById('fileLink'))
            document.getElementById('fileLink').remove()
        if (document.getElementById('a'))
            document.getElementById('a').remove()

        let emlContent = "data:message/rfc822 eml;charset=utf-8,";

        emlContent += 'Date: ' + new Date() + '\n';
        emlContent += 'From: ' + ownUser.email + '\n';
        emlContent += 'Subject: Nouveaux identifiants\n';
        emlContent += 'To: ' + user.email + '\n';
        emlContent += 'X-Unsent: 1\n';
        emlContent += 'Content-Type: text/html\n';
        emlContent += '\n';
        emlContent += `Voici les nouveaux identifiants de l'utilisateur:\nE-mail: ${user.email}\nMot de passe: ${user.password}`;

        let encodedUri = encodeURI(emlContent);
        let a = document.createElement('a');
        let linkText = document.createTextNode("fileLink");

        a.appendChild(linkText);
        a.href = encodedUri;
        a.id = 'fileLink';
        a.download = 'mail.eml';
        a.style = "display: none;";
        document.body.appendChild(a);
        document.getElementById('fileLink').click();
        document.getElementById('fileLink').remove();

        if (document.getElementById('a'))
            document.getElementById('a').remove()

        return;
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
            <DialogTitle>
                Identifiants de l'utilisateur
                <IconButton
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: 5,
                        right: 0,
                        margin: 0,
                        color: '#000',
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className={classes.data}>
                    <Typography variant='h6'>E-mail:</Typography>
                    <Typography variant='h6'>{user.email}</Typography>
                </div>
                <div className={classes.data}>
                    <Typography variant='h6'>Mot de passe:</Typography>
                    <Typography variant='h6'>{user.password}</Typography>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
                <Button
                    style={{ margin: 'auto' }}
                    onClick={handleClose}
                    startIcon={<Check />}
                    variant='contained'
                    color='secondary'>
                        Ok
                </Button>
                <IconButton
                    style={{ margin: 'auto' }}
                    onClick={downloadTemplate}
                    variant='contained'
                    color='secondary'
                >
                    <AttachEmail />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
}
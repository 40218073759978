import { IconButton, Switch } from '@material-ui/core';

export const SwitchButton = ({ row, onDelete }) => {
    if (!onDelete)
        return <></>;

    return (
        <IconButton component="span" onClick={() => {onDelete(row)}}>
            <Switch checked={!row.soft_deleted} />
        </IconButton>
    );
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel, TextField, Typography } from '@material-ui/core';
import { eventActions } from '@actions/event.actions';

const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export default function ModalFile({ open, setOpen, event, eventId, cycleId, filesData }) {
    const dispatch = useDispatch();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [noReserve, setNoReserve] = useState(false);
    const [hasReserves, setHasReserves] = useState(false);
    const [error, setError] = useState(false);
    const [choseDate, setChoseDate] = useState(true);

    const [date, setDate] = useState(getFormattedDate());

    const [updateRecurrency, setUpdateRecurrency] = useState(false);
    const [askChangeDate, setAskChangeDate] = useState(false);

    const eventReducer = useSelector(state => state.eventReducer);

    const handleClose = () => setOpen(false);


    const handleDate = () => {
        const dateTmp = new Date(document.getElementById('endDate').value);
        const limitDate = new Date(event.start_date);

        limitDate.setDate(limitDate.getDate() + 14);
        setDate(dateTmp);
        setChoseDate(false);
        if (new Date(dateTmp) > new Date(limitDate))
            setAskChangeDate(true);
    }

    const handleChangeDate = (changeDate) => {
        setUpdateRecurrency(changeDate);
        setAskChangeDate(false);
    }

    const handleGoBack = () => {
        if (askChangeDate) {
            setAskChangeDate(false);
            setChoseDate(true);
        } else if (noReserve === true)
            setNoReserve(false);
        else if (choseDate === false)
            setChoseDate(true);
        else
            setOpen(false);
    }

    const onSubmit = (valueHasReserve) => {
        setIsButtonDisabled(true);

        if (noReserve && !document.getElementById('noReserveCheckbox').checked) {
            setError(true);
        } else {
            setHasReserves(valueHasReserve);
            setError(false);
            dispatch(eventActions.uploadFile(eventId, cycleId, filesData));
        }
    }
    useEffect(() => {
        if (eventReducer.action === 'event/uploadFileSuccess') {
            dispatch(eventActions.clearSuccess());
        } else if (eventReducer.action === 'event/uploadFileFailure') {
            setIsButtonDisabled(false);
        }
    }, [eventReducer, dispatch]);


    useEffect(() => {
        if (eventReducer.action === 'event/uploadFileSuccess') {
            let eventChanged = {
                start_date: new Date(event.start_date),
                hasReserves: hasReserves,
                status: noReserve ? 1 : 0
            };

            dispatch(eventActions.update(eventId, cycleId, eventChanged));
        } else if (eventReducer.action === 'event/updateSuccess') {
            let eventClosed = {
                newStartDate: new Date(),
                updateRecurrency: updateRecurrency,
                closeDate: new Date(date)
            }

            dispatch(eventActions.close(eventId, cycleId, eventClosed));
        } else if (eventReducer.action === 'event/closeSuccess') {
            dispatch(eventActions.clearSuccess);
            setOpen(false);
        }
    }, [eventReducer, dispatch, setOpen, eventId, cycleId, hasReserves, event, noReserve, updateRecurrency, date]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {
                    askChangeDate
                        ?
                        <p>Souhaitez-vous définir la date d'aujourd'hui comme nouvelle date de base de l'événement ?</p>
                        : choseDate
                            ? <p>Sélectionner la date de réalisation de l'événement</p>
                            : noReserve
                                ? <p>Vous venez de confirmer que la pièce justificative ne comporte pas de réserves ou de
                                    points de non-conformité</p>
                                :
                                <p>La pièce justificative comporte-t-elle des réserves ou des points de non-conformité ?</p>
                }
            </DialogTitle>
            <DialogContent>
                {
                    choseDate
                        ? <TextField
                            id='endDate'
                            variant="outlined"
                            type='date'
                            style={{marginBottom: 20}}
                            fullWidth={true}
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                        />
                        : noReserve
                            ? <>
                                <Typography style={{color: 'red', fontSize: 20, fontWeight: 'bold', margin: '20px 0'}}>
                                    Nous vous rappelons que toute fausse déclaration peut entrainer l'annulation de votre
                                    contrat d'assurance
                                </Typography>
                                <FormControlLabel
                                    control={<Checkbox id='noReserveCheckbox'/>}
                                    label='Je certifie ne pas avoir de réserves ou de points de non-conformité'
                                />
                                {
                                    error
                                        ? <h6 style={{color: 'red', fontSize: 14, fontWeight: 'bold', margin: '5px 0'}}>
                                            * Veuillez certifier que vous ne souhaitez pas de reserves avant de valider
                                        </h6>
                                        : <></>
                                }
                            </>
                            : <></>
                }
            </DialogContent>
            <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    disabled={isButtonDisabled}
                    onClick={handleGoBack}
                    variant='outlined'
                    color='secondary'
                >
                    Retour
                </Button>
                {
                    askChangeDate
                        ? <div>
                            <Button
                                disabled={isButtonDisabled}
                                onClick={() => {
                                    handleChangeDate(false)
                                }}
                                style={{background: '#18284D', color: '#F18844', marginRight: 20}}
                            >
                                Non
                            </Button>
                            <Button
                                disabled={isButtonDisabled}
                                onClick={() => {
                                    handleChangeDate(true)
                                }}
                                style={{background: '#18284D', color: '#F18844'}}
                            >
                                {isButtonDisabled ? "En cours de transmission..." : "Oui"}
                            </Button>
                        </div>
                        : choseDate
                            ? <Button
                                disabled={isButtonDisabled}
                                onClick={handleDate}
                                style={{background: '#18284D', color: '#F18844'}}
                            >
                                Continuer
                            </Button>
                            : noReserve
                                ? <Button
                                    disabled={isButtonDisabled}
                                    onClick={() => onSubmit(false)}
                                    autoFocus
                                    style={{background: '#18284D', color: '#F18844'}}
                                >
                                    {isButtonDisabled ? "En cours de transmission..." : "Valider"}
                                </Button>
                                : <div>
                                    <Button
                                        disabled={isButtonDisabled}
                                        onClick={() => {
                                            setNoReserve(true)
                                        }}
                                        style={{background: '#18284D', color: '#F18844', marginRight: 20}}
                                    >
                                        Non
                                    </Button>
                                    <Button
                                        disabled={isButtonDisabled}
                                        onClick={() => onSubmit(true)}
                                        autoFocus
                                        style={{background: '#18284D', color: '#F18844'}}
                                    >
                                        {isButtonDisabled ? "En cours de transmission..." : "Oui"}
                                    </Button>
                                </div>
                }
            </DialogActions>
        </Dialog>
    );
}
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {Grid, Typography, Button, Checkbox} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CustomForm } from '@components/Form/CustomForm';
import { companyActions } from '@actions/company.actions';
import { Api } from '@config/api';
import CustomTable from '@components/Table/CustomTable';


const columns = [
    { field: 'office', headerName: 'Site' },
    { field: 'limitAmountOfCapitals', headerName: 'Limite' },
    { field: 'billing', headerName: 'Cumul du montant des capitaux' },
    { field: 'rate', headerName: 'Taux' },
];

export default function UpdateCompany(props) {
    const { register, handleSubmit, reset } = useForm();
    const history = useHistory();

    const dispatch = useDispatch();
    const companyReducer = useSelector(state => state.companyReducer);
    const companyInfos = props?.location?.state?.company;

    const [fileData, setFileData] = useState(null);
    const [billingSum, setBillingSum] = useState(0);

    const [officeDepositRows, setOfficeDepositRows] = useState([]);
    const [showGoods, setShowGoods] = useState(false);

    const handleGoodsCheckboxChange = (event) => {
        setShowGoods(event.target.checked);
    };
    if (companyInfos === undefined || companyInfos === null) {
        history.push('/');
    }

    useEffect(() => {
        Api.get(`/companies/${companyInfos.id}`).then(({ data }) => {
            const rows = []
            let billing = 0;
            for (const office of data.offices) {
                const res = (office.totalBilling / office.limitAmountOfCapitals)?.toFixed(2) * 100
                if (office.totalBilling)
                    billing += office.totalBilling
                rows.push({
                    office: office.label,
                    limitAmountOfCapitals: office.limitAmountOfCapitals,
                    billing: office.totalBilling ?? 0,
                    rate: office.rate,
                })
            }
            setBillingSum(billing)
            setOfficeDepositRows(rows);
        })
    }, [companyInfos.id])


    const [keepData, setKeepData] = useState(companyInfos);

    const form = {
        title: `Modification de ${keepData.name}`,
        lines: [
            [
                { label: `Société`, field: `name`, data: keepData.name }
            ],
            [
                { label: `RC`, field: `contractRC`, data: keepData?.contractRC },
                { label: `Dommages aux biens`, field: `propertyDamage`, data: keepData?.propertyDamage }
            ],
        ]
    }

    const contactPersonForm = {
        lines: [
            [
                { label: `Nom de la personne de contact`, field: `contactPerson.lastname`, data: keepData?.contactPerson?.lastname },
                { label: `Prénom de la personne de contact`, field: `contactPerson.firstname`, data: keepData?.contactPerson.firstname }
            ],
            [
                { label: `Téléphone de la personne de contact`, field: `contactPerson.phone`, data: keepData?.contactPerson.phone },
                { label: `E-mail de la personne de contact`, field: `contactPerson.email`, data: keepData?.contactPerson.email },
                { label: `Role de la personne de contact`, field: `contactPerson.job`, data: keepData?.contactPerson.job }
            ]
        ],
    }

    const contactAccountingForm = {
        lines: [
            [
                { label: `Nom de la personne de comptabilité`, field: `accountingPerson.lastname`, data: keepData?.accountingPerson?.lastname },
                { label: `Prénom de la personne de comptabilité`, field: `accountingPerson.firstname`, data: keepData?.accountingPerson?.firstname }
            ],
            [
                { label: `Téléphone de la personne de comptabilité`, field: `accountingPerson.phone`, data: keepData?.accountingPerson?.phone },
                { label: `E-mail de la personne de comptabilité`, field: `accountingPerson.email`, data: keepData?.accountingPerson?.email },
                { label: `Role de la personne de comptabilité`, field: `accountingPerson.job`, data: keepData?.accountingPerson?.job }
            ],
        ]
    }

    const depositForm = {
        lines: [
            [
                { label: `Limite des montants de capitaux`, field: `limitAmountOfCapitals`, data: keepData?.limitAmountOfCapitals, type: 'euro' },
                { label: 'Total des montants de capitaux', field: 'preview', data: billingSum, disabled: true, type: 'euro' },
            ],
            [
                { label: 'Taux annuel TTC (‰)', field: 'rate', data: keepData?.rate },
            ],
            [
                { label: `RC`, field: `contractRC`, data: keepData?.contractRC },
                { label: `Dommages aux biens`, field: `propertyDamage`, data: keepData?.propertyDamage }
            ],
        ]
    }

    const formLogo = {
        title: 'Ajouter un logo',
        lines: [
            [
                { label: `Logo`, field: `file`, type: 'file', data: fileData }
            ]
        ]
    }

    const onSubmit = (properties) => {
        if (
            properties.contactPerson.lastname !== '' &&
            properties.contactPerson.firstname !== '' &&
            properties.contactPerson.phone !== '' &&
            properties.contactPerson.email !== '' &&
            properties.contactPerson.job !== ''
        ) {
            if (!showGoods) {
                properties.limitAmountOfCapitals = 0;
                properties.rate = 0;
                properties.accountingPerson = {
                    lastname: '',
                    firstname: '',
                    phone: '',
                    email: '',
                    job: ''
                };
            }

            properties.name = properties.name ?? keepData.name;
            properties.contractRC = properties.contractRC ?? keepData.contractRC;
            properties.propertyDamage = properties.propertyDamage ?? keepData.propertyDamage;

            properties.contactPerson = {
                lastname: properties.contactPerson.lastname !== '' ? properties.contactPerson.lastname : keepData.contactPerson.lastname,
                firstname: properties.contactPerson.firstname !== '' ? properties.contactPerson.firstname : keepData.contactPerson.firstname,
                phone: properties.contactPerson.phone !== '' ? properties.contactPerson.phone : keepData.contactPerson.phone,
                email: properties.contactPerson.email !== '' ? properties.contactPerson.email : keepData.contactPerson.email,
                job: properties.contactPerson.job !== '' ? properties.contactPerson.job : keepData.contactPerson.job
            };

            properties.accountingPerson = {
                lastname: properties.accountingPerson.lastname !== '' ? properties.accountingPerson.lastname : keepData.accountingPerson.lastname,
                firstname: properties.accountingPerson.firstname !== '' ? properties.accountingPerson.firstname : keepData.accountingPerson.firstname,
                phone: properties.accountingPerson.phone !== '' ? properties.accountingPerson.phone : keepData.accountingPerson.phone,
                email: properties.accountingPerson.email !== '' ? properties.accountingPerson.email : keepData.accountingPerson.email,
                job: properties.accountingPerson.job !== '' ? properties.accountingPerson.job : keepData.accountingPerson.job
            };

            properties.offices = keepData.offices;

            dispatch(companyActions.update(companyInfos.id, properties));
            setKeepData(properties);
            reset({});
        }
    };

    const onSubmitLogo = () => {
        const data = new FormData();
        data.append('file', fileData);
        dispatch(companyActions.uploadLogo(companyInfos.id, data));
        history.goBack();
    }

    const onDeleteLogo = () => dispatch(companyActions.deleteLogo(companyInfos.id));
    const onClick = () => history.goBack();
    const handleFileChanged = (e) => setFileData(e.target.files[0]);

    useEffect(() => {
        dispatch(companyActions.getLogo(companyInfos.id));
    }, [companyInfos, dispatch]);

    useEffect(() => {
        if (companyReducer.action === 'company/updateSuccess' ||
            companyReducer.action === 'company/uploadLogoSuccess') {
            dispatch(companyActions.clearSuccess());
            history.goBack();
        }
    }, [companyReducer, dispatch, history]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container style={{ margin: '50px 0' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    Modification de {companyInfos.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" color='secondary' onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Grid item xs={10}>
                                <Grid container spacing={3}>

                                    <Grid item xs={12}>
                                        <CustomForm title={''} lines={form.lines} register={register} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CustomForm title={'Contact'} lines={contactPersonForm.lines} register={register} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Checkbox checked={showGoods} onChange={handleGoodsCheckboxChange} />
                                        <label>Afficher la partie Marchandises pour compte de Tiers et Comptabilité</label>
                                    </Grid>
                                    {showGoods && (
                                    <Grid item xs={12}>
                                        <CustomForm title={'Comptabilité'} lines={contactAccountingForm.lines} register={register} />
                                    </Grid>
                                    )}
                                    {showGoods && (
                                    <Grid item xs={12}>
                                        <CustomForm title={'Marchandises'} lines={depositForm.lines} register={register} />
                                    </Grid>
                                        )}
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ margin: 50 }}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                <Button size="large" variant='outlined' color='secondary' startIcon={<Send />} type="submit">
                                    SAUVEGARDER
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {
                companyReducer.company.logo instanceof Blob
                    ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={window.URL.createObjectURL(companyReducer.company.logo)} alt='Logo de la société' height={300} />
                        <Button onClick={onDeleteLogo} size="large" variant='outlined' style={{ color: '#D11A2A', margin: '50px 0' }} startIcon={<DeleteForeverIcon />} type="submit">
                            SUPPRIMER
                        </Button>
                    </div>
                    : <form onSubmit={handleSubmit(onSubmitLogo)}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={1}></Grid>
                                <Grid item xs={9}>
                                    <Typography variant="h6" fontWeight="fontWeightBold">
                                        Ajouter un logo
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" style={{ textAlign: 'center' }}>
                                    <Grid item xs={10}>
                                        <CustomForm title={''} lines={formLogo.lines} register={register} handleFileChanged={handleFileChanged} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ margin: 50 }}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Button size="large" variant='outlined' color='secondary' startIcon={<Send />} type="submit">
                                            METTRE EN LIGNE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
            }
            <Grid container justifyContent="center">
                <Grid item xs={8} style={{ margin: 50 }}>
                    <Grid container justifyContent="center" mb={10}>
                        <CustomTable
                            columns={columns}
                            rows={officeDepositRows}
                            title="Récapitulatif des marchandises"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
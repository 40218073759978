export const compare = (array, index) => {
    return array.sort((a, b) => {
        if (Object.values(a)[index] > Object.values(b)[index])
            return 1
        else if ((Object.values(b)[index] > Object.values(a)[index]))
            return -1;
        else {
            if (Object.values(a)[5] > Object.values(b)[5])
                return 1
            else if ((Object.values(b)[5] > Object.values(a)[5]))
                return -1;
            else {
                if (Object.values(a)[2] > Object.values(b)[2])
                    return 1
                else if ((Object.values(b)[2] > Object.values(a)[2]))
                    return -1;
                else
                    return 0;
            }
        }
    });
}

export const compareReversed = (array, index) => {
    return array.sort((a, b) => {
        if (Object.values(b)[index] > Object.values(a)[index])
            return 1
        else if ((Object.values(a)[index] > Object.values(b)[index]))
            return -1;
        else {
            if (Object.values(a)[5] > Object.values(b)[5])
                return 1
            else if ((Object.values(b)[5] > Object.values(a)[5]))
                return -1;
            else {
                if (Object.values(a)[2] > Object.values(b)[2])
                    return 1
                else if ((Object.values(b)[2] > Object.values(a)[2]))
                    return -1;
                else
                    return 0;
            }
        }
    });
}

export const compareDate = (array, index) => {
    return array.sort((a, b) => {
        let dateA = new Date(Object.values(a)[index]);
        let dateB = new Date(Object.values(b)[index]);

        if (dateA > dateB)
            return 1
        else if (dateB > dateA)
            return -1;
        else {
            if (Object.values(a)[5] > Object.values(b)[5])
                return 1
            else if ((Object.values(b)[5] > Object.values(a)[5]))
                return -1;
            else {
                if (Object.values(a)[2] > Object.values(b)[2])
                    return 1
                else if ((Object.values(b)[2] > Object.values(a)[2]))
                    return -1;
                else
                    return 0;
            }
        }
    });
}

export const compareDateReversed = (array, index) => {
    return array.sort((a, b) => {
        let dateA = new Date(Object.values(a)[index]);
        let dateB = new Date(Object.values(b)[index]);

        if (dateB > dateA)
            return 1
        else if (dateA > dateB)
            return -1;
        else {
            if (Object.values(a)[5] > Object.values(b)[5])
                return 1
            else if ((Object.values(b)[5] > Object.values(a)[5]))
                return -1;
            else {
                if (Object.values(a)[2] > Object.values(b)[2])
                    return 1
                else if ((Object.values(b)[2] > Object.values(a)[2]))
                    return -1;
                else
                    return 0;
            }
        }
    });
}
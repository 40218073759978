const initialState = {
    action: '',
    loading: false,
    error: {},
    event : {},
    events: [],
    file: {}
}

export default function eventReducer(state = initialState, action) {
    switch (action.type) {
        case 'event/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                events: [...state.events, action.event]
            };
        case 'event/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'event/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/removeRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/removeSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                events: [...state.events.filter(event => event.id !== action.id)]
            };
        case 'event/removeFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/removeAllNextRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/removeAllNextSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'event/removeAllNextFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/getRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/getSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                event: action.event
            };
        case 'event/getFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/getAllRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/getAllSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                events: action.events
            };
        case 'event/getAllFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/getExportedEventsRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/getExportedEventsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                events: action.events
            };
        case 'event/getExportedEventsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/uploadFileRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/uploadFileSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'event/uploadFileFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/getFileRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/getFileSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                file: action.file,
                error: {}
            };
        case 'event/getFileFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/closeRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/closeSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'event/closeFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/deleteProofRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'event/deleteProofSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'event/deleteProofFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'event/clearSuccess':
            return {
                ...state,
                action: action.type
            }
        default:
            return state;
    }
}
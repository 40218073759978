import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fileDownload from 'js-file-download';
import CustomTable from '@components/Table/CustomTable';
import { billActions } from '@actions/bill.actions';
import { CreateBill } from './CreateBill';
import { UpdateBill } from './UpdateBill';
import isEmpty from '@helpers/isEmpty';

export default function Bills({ eventId, cycleId }) {
    const [create, setCreate] = useState(false);
    const [update, setUpdate] = useState(false);
    const [billSelected, setBillSelected] = useState({});
    const [downloadBillSelectedId, setdownloadBillSelectedId] = useState(undefined);

    const dispatch = useDispatch();
    const file = useSelector(state => state.billReducer.file);
    const billReducer = useSelector(state => state.billReducer);
    const bills = useSelector(state => state.billReducer.bills);

    const columns = [
        { field: 'company_intervening.label', headerName: 'Société', align: 'center', style: { 'textTransform': 'uppercase' } },
        { field: 'bill', headerName: 'Facture (en €)', align: 'left' },
        { field: 'file', headerName: 'Fichier', align: 'center', type: 'bill' },
    ];

    const onDelete = (bill) => {
        dispatch(billActions.remove(eventId, cycleId, bill.id));
    }


    const onCreate = () => setCreate(true);

    const onUpdate = (row) => {
        setUpdate(true);
        setBillSelected(row);
    }

    const handleDownload = (billId) => {
        setdownloadBillSelectedId(billId);
        dispatch(billActions.getFile(eventId, cycleId, billId));
    }

    useEffect(() => {
        if (billReducer.action === 'bill/getFileSuccess') {
            const selectedBill = bills.find(bill => bill.id === downloadBillSelectedId)
            if (selectedBill) {
                fileDownload(file, selectedBill.file.originalname);
            }
            dispatch(billActions.clearSuccess());
        } else if (billReducer.action === 'bill/updateSuccess') {
            dispatch(billActions.getAll(eventId, cycleId));
        }
    }, [dispatch, file, billReducer, bills, eventId, cycleId, downloadBillSelectedId]);

    if (create)
        return <CreateBill setCreate={setCreate} eventId={eventId} cycleId={cycleId} />

    if (update && !isEmpty(billSelected))
        return <UpdateBill setUpdate={setUpdate} eventId={eventId} cycleId={cycleId} bill={billSelected} />

    return (
        <div style={{ width: '100%', margin: 20 }}>
            <CustomTable
                columns={columns}
                rows={bills}
                title="Facture(s) de la / des pièce(s) justificative(s)"
                onCreate={onCreate}
                createLabel={"Importer une facture"}
                onUpdate={onUpdate}
                onDelete={onDelete}
                handleDownload={handleDownload}
            />
        </div>
    );
}
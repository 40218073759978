import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, Grid, makeStyles } from '@material-ui/core';
import { userActions } from '@actions/user.actions';
import { alertActions } from '@actions/alert.actions';
import loginBG from '@assets/Img/loginBG.jpg'
import logoIBS from '@assets/Img/logo-ibs.png'
import Header from './Header/Header';
import Entry from './Entry/Entry';
import Submit from './Submit/Submit';

const useStyles = makeStyles(() => ({
    pageContent: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${loginBG})`,
        backgroundSize: 'cover'
    },
    card: {
        width: 500,
        height: 700,
        background: '#fff',
        borderRadius: 10,
        textAlign: 'center'
    },
    form: {
        margin: '50px 0 30px',
    },
    legalNotice: {
        textDecoration: 'none',
        fontSize: 20,
        color: 'gray',
        fontWeight: 500,
        '&:hover': {
            textDecoration: 'underline'
        }
    }
}));

export default function Login() {
    const classes = useStyles();
    const { register, handleSubmit } = useForm();
    const history = useHistory();

    const dispatch = useDispatch();

    const onSubmit = (properties) => {
        const { email, password } = properties;

        if (email && password)
            dispatch(userActions.login(email, password));
    };

    history.listen(() => {
        dispatch(alertActions.clear());
    });

    return (
        <div className={classes.pageContent}>
            <img
                src={logoIBS}
                alt='logo IBS'
                id='background'
                style={{ position: 'absolute', top: 30, left: 0, display: 'none'}}
                onLoad={() => document.getElementById('background').style.display = 'block'}
            />
            <Card className={classes.card}>
                <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                    <Grid container spacing={3} justifyContent='center'>
                        <Header />
                        <Entry
                            id='standard-full-width'
                            label='E-mail'
                            type='Email'
                            register={{...register('email')}}
                        />
                        <Entry
                            id='standard-full-width-password-input'
                            label='Mot de passe'
                            type='password'
                            register={{...register('password')}}
                        />
                        <Submit onSubmit={onSubmit} />
                    </Grid>
                </form>
                <Link to='/legal-notice' className={classes.legalNotice}>Mentions légales</Link>
            </Card>
        </div>
    );
}
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { SaveButton } from '@components/Button/SaveButton';
import { officeActions } from '@actions/office.actions';
import OfficeSubAppBar from '../Office';
import Header from '../Header/Header';
import DepositForm from './DepositForm';
import { generateWaiverTemplate } from "./TemplateService";
import SaveIcon from "@mui/icons-material/Save";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import FilePresentIcon from "@mui/icons-material/FilePresent";

export default function UpdateDeposit({ companyId, officeId, deposit, setUpdate }) {

    const officeReducer = useSelector(state => state.officeReducer);
    const companyReducer = useSelector(state => state.companyReducer);
    const rate = officeReducer?.office?.rate ?? 0;
    const { register, handleSubmit } = useForm({ ...deposit });
    const dispatch = useDispatch();

    const calcDaysBeetweenDates = (date1, date2) => {
        // To calculate the time difference of two dates
        const timeDiff = date2.getTime() - date1.getTime();
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return daysDiff;
    }

    const getPreview = () => {
        const date1 = new Date(deposit.startingDate)
        const date2 = new Date(deposit.endingDate)
        const days = calcDaysBeetweenDates(date1, date2);
        // MONTANT * (5/365 000) * période en jours
        const rate = officeReducer.office.rate ?? 5;
        const preview = (deposit.billing * (rate / 364000) * days).toFixed(2);
        return preview;
    }

    const getMensualPreview = () => {

        const date1 = new Date(deposit.startingDate)
        const date2 = new Date(deposit.endingDate)
        let days = calcDaysBeetweenDates(date1, date2);
        days = days > 365 ? 365 : days < 365 ? days : days;
        // MONTANT * (5/365 000) * période en jours
        const rate = officeReducer.office.rate ?? 5;
        const preview = (deposit.billing * (rate / 365000) * days).toFixed(2);
        return preview;
    }

    const updateDeposit = {
        filingCompany: deposit.filingCompany,
        merchandiseType: deposit.merchandiseType,
        billing: deposit.billing,
        startingDate: new Date(deposit.startingDate),
        endingDate: new Date(deposit.endingDate),
        previewBilling: getPreview(deposit),
        previewMensualBilling: getMensualPreview(deposit),
        type: deposit.type, // Keep the existing type if none selected
        quantity: deposit?.quantity ?? "",
        applicant: {
            email: deposit?.applicant?.email ?? '',
            firstname: deposit?.applicant?.firstname ?? '',
            lastname: deposit?.applicant?.lastname ?? '',
            job: deposit?.applicant?.job ?? '',
            phone: deposit?.applicant?.phone ?? '',
        }
    }

    useEffect(() => {
        if (officeReducer.action === 'office/updateDepositsSuccess') {
            dispatch(officeActions.clearSuccess());
            setUpdate(false)
        }
    }, [officeReducer, setUpdate, dispatch])

    const onSubmit = (properties) => {
        updateDeposit.filingCompany = properties.filingCompany ?? deposit.filingCompany
        updateDeposit.merchandiseType = properties.merchandiseType ?? deposit.merchandiseType

        let billingCopy = properties.billing ? properties.billing : deposit.billing

        if (properties.billing) {
            billingCopy = parseInt(properties.billing.replace(/\s/g, ''));
        }

        updateDeposit.billing = billingCopy
        updateDeposit.startingDate = properties.startingDate ?? deposit.startingDate
        updateDeposit.endingDate = properties.endingDate ?? deposit.endingDate
        updateDeposit.quantity = properties.quantity ?? deposit.quantity

        if (updateDeposit.type === 'Renonciation à recours') {
            const address = officeReducer.office.address;
            const formattedAddress = `${address.street_number} ${address.street_name}, ${address.city}, ${address.zip_code}, ${address.country}`

            // Check if officeReducer.deposit is defined before accessing its properties
            const referentName = officeReducer.deposit?.applicant?.firstname ?
                `${officeReducer.deposit.applicant.firstname} ${officeReducer.deposit.applicant.lastname}` : 'Unknown';

            generateWaiverTemplate(formattedAddress, officeReducer.office.label, officeReducer.deposit.startingDate, referentName, companyReducer.company.name);
        }

        dispatch(officeActions.updateDeposit(companyId, officeId, deposit.id, updateDeposit));
    }

    const onClick = () => setUpdate(false);

    return (
        <div>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {"Modification d'une marchandise."}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '50px 0' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={11} style={{ textAlign: 'center' }}>
                                {/* Place the buttons inside the DepositForm component */}
                                <DepositForm register={register} deposit={updateDeposit} rate={rate} />

                                <Grid container justifyContent="center" spacing={2} style={{ marginTop: '20px' }}>
                                    <Grid item>
                                        <Button onClick={() => { updateDeposit.type = "Renonciation à recours" }}>
                                            <Button size="large" color='secondary' startIcon={<FilePresentIcon />} type="submit" variant='outlined'>
                                                Renonciation à recours
                                            </Button>
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button onClick={() => { updateDeposit.type = "Comptabilité" }}>
                                            <Button size="large" color='secondary' startIcon={<AccountBalanceWalletIcon />} type="submit" variant='outlined'>
                                                Envois à la comptabilité
                                            </Button>
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button onClick={() => { updateDeposit.type = "Ne sais pas" }}>
                                            <Button size="large" color='secondary' startIcon={<SaveIcon />} type="submit" variant='outlined'>
                                                Je ne sais pas
                                            </Button>
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 50 }}>
                                <Grid container justifyContent="center">
                                    <SaveButton />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
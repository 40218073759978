import { billService } from '@services/bill.services';
import { alertActions } from './alert.actions';

export const billActions = {
    create,
    update,
    remove,
    getAll,
    getFile,
    clearSuccess
};

function create(eventId, cycleId, properties) {
    const request = () => ({ type: 'bill/createRequest' });
    const success = (bill) => ({ type: 'bill/createSuccess', bill });
    const failure = (error) => ({ type: 'bill/createFailure', error });

    return dispatch => {
        dispatch(request());
        billService.create(eventId, cycleId, properties)
            .then(bill => {
                dispatch(success(bill));
                dispatch(alertActions.success("La facture a bien été créée."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la création de cette facture."));
            });
    };
}

function update(eventId, cycleId, billId, properties) {
    const request = () => ({ type: 'bill/updateRequest' });
    const success = () => ({ type: 'bill/updateSuccess' });
    const failure = (error) => ({ type: 'bill/updateFailure', error });

    return dispatch => {
        dispatch(request());
        billService.update(eventId, cycleId, billId, properties)
            .then(bill => {
                dispatch(success(bill));
                dispatch(alertActions.success("La facture a bien été édité."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la modification de cette facture."));
            });
    };
}

function remove(eventId, cycleId, billId) {
    const request = () => ({ type: 'bill/removeRequest' });
    const success = (id) => ({ type: 'bill/removeSuccess', id });
    const failure = (error) => ({ type: 'bill/removeFailure', error });

    return dispatch => {
        dispatch(request());
        billService.remove(eventId, cycleId, billId)
            .then(() => {
                dispatch(success(billId));
                dispatch(alertActions.success("La facture a bien été supprimée."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la suppression de cette facture."));
            });
    };
}

function getAll(eventId, cycleId) {
    const request = () => ({ type: 'bill/getAllRequest' });
    const success = (bills) => ({ type: 'bill/getAllSuccess', bills });
    const failure = (error) => ({ type: 'bill/getAllFailure', error });

    return dispatch => {
        dispatch(request());
        billService.getAll(eventId, cycleId)
            .then(bills => dispatch(success(bills)))
            .catch(error => dispatch(failure(error)));
    };
}

function getFile(eventId, cycleId, billId) {
    const request = () => ({ type: 'bill/getFileRequest' });
    const success = (file) => ({ type: 'bill/getFileSuccess', file });
    const failure = (error) => ({ type: 'bill/getFileFailure', error });

    return dispatch => {
        dispatch(request());
        billService.getFile(eventId, cycleId, billId)
            .then(file => dispatch(success(file)))
            .catch(error => dispatch(failure(error)));
    };
}

function clearSuccess() {
    return { type: 'bill/clearSuccess' };
}
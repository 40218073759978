import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { certificationActions } from '@actions/certification.actions';

const typesAvailable = [
    { key: 'Certification APSAD', label: 'Certification APSAD' },
    { key: 'Reserve rapport de visite', label: 'Reserve rapport de visite' },
    { key: 'Maintenance', label: 'Maintenance' },
    { key: 'Bonne pratique', label: 'Bonne pratique' }
]

export default function NewCertification() {
    const { register, handleSubmit, reset } = useForm();
    const history = useHistory();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const dispatch = useDispatch();
    const certificationReducer = useSelector(state => state.certificationReducer);
    const [keepData, setKeepData] = useState({
        label: undefined,
        description: undefined,
        comment: undefined
    });
    const [selectedType, setSelectedType] = useState(typesAvailable[0].key);

    const onSubmit = (properties) => {
        setIsButtonDisabled(true);
        const payload = { ...properties, type: selectedType }
        dispatch(certificationActions.create(payload));
        setKeepData({ ...payload })
        reset({});
    }
    useEffect(() => {
        if (certificationReducer.action === 'certification/createSuccess') {
            setIsButtonDisabled(false);
        } else if (certificationReducer.action === 'certification/createFailure') {
            setIsButtonDisabled(false);
        }
    }, [certificationReducer, dispatch]);

    const onClick = () => history.goBack();

    const form = {
        title: "Création d'une certification",
        lines: [
            [
                { label: "Nom du type d'évènement", field: 'label', data: keepData.label },
                { label: 'Description', field: 'description', data: keepData.description },
            ],
            [
                { label: 'Commentaire', field: 'comment', data: keepData.comment },
            ],
            [
                { label: 'Type', field: 'type', data: selectedType, setData: setSelectedType, type: 'select', options: typesAvailable }
            ]
        ]
    }

    useEffect(() => {
        if (certificationReducer.action === 'certification/createSuccess') {
            dispatch(certificationActions.clearSuccess());
            history.goBack();
        }
    }, [certificationReducer, dispatch, history]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 50 }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={form.lines} register={register} />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <SaveButton disabled={isButtonDisabled} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
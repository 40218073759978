import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '@components/Table/CustomTable';
import { eventActions } from '@actions/event.actions';
import { reserveActions } from '@actions/reserve.actions';
import { NewReserve } from './NewReserve';
import UpdateReserve from './UpdateReserve';

export default function Reserves() {
    const history = useHistory();
    const { companyId, officeId } = useParams();

    const [create, setCreate] = useState(false);
    const [update, setUpdate] = useState(false);
    const [reserves, setReserves] = useState([]);
    const [reserveSelected, setReserveSelected] = useState({});

    const dispatch = useDispatch();
    const reserveReducer = useSelector(state => state.reserveReducer);
    const user = useSelector(state => state.userReducer.userInfos);

    const onCreate = () => setCreate(true);

    const onUpdate = (row) => {
        setReserveSelected(row);
        setUpdate(true);
    }

    const onVisualize = (row) => {
        history.push(`/companies/${companyId}/offices/${officeId}/reminders/${row.id}/cycles/${row.cycles[0].id}`);
    }

    const onDelete = (row) => {
        dispatch(reserveActions.remove(row.id));
    }

    const handleChange = (e, row) => {
        row.status = e.target.value;
        dispatch(eventActions.update(row.id, row.cycles[0].id, row));
    };

    const columns = [
        { field: 'label', headerName: 'Réserve' },
        { field: 'description', headerName: 'Description' },
        { field: 'stringDate', headerName: 'Date de réalisation prévue', type: 'date' },
        { field: 'status', headerName: 'Statut', type: 'status', onChange: handleChange, disabled: user }
    ];

    useEffect(() => {
        if (reserveReducer.action === 'reserve/getAllSuccess' ||
            reserveReducer.action === 'reserve/removeSuccess') {
            let reservesTmp = [];

            reserveReducer.reserves.forEach(reserve => {
                reserve.status = reserve.cycles[0].status;
                if (reserve.isChild)
                    reservesTmp.push(reserve);
            });
            setReserves(reservesTmp);
            dispatch(reserveActions.clearSuccess());
        }
    }, [reserveReducer, dispatch]);

    if (create)
        return <NewReserve setCreate={setCreate} />

    if (update)
        return <UpdateReserve setUpdate={setUpdate} reserve={reserveSelected} />

    return (
        <div style={{ width: '100%', margin: 20 }}>
            <CustomTable
                columns={columns}
                rows={reserves}
                title="Liste des réserves / points de non-conformité"
                onCreate={user.role !== 'viewer' ? onCreate : null}
                createLabel="Créer une réserve"
                onVisualize={onVisualize}
                onDelete={onDelete}
                onUpdate={onUpdate}
                handleChangeStatus={handleChange}
            />
        </div>
    );
}
const initialState = {
    action: '',
    loading: false,
    error: {},
    companies: [],
    company: {},
    capitals: [],
    logo: {},
    companyDisabled: []
}

export default function companyReducer(state = initialState, action) {
    switch (action.type) {
        case 'company/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true,
            };
        case 'company/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                company: action.company,
                companies: [...state.companies, action.company]
            };
        case 'company/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                company: {}
            };
        case 'company/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true,
            };
        case 'company/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                company: action.company,
            };
        case 'company/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                company: {}
            };
        case 'company/getRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'company/getSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                company: action.company,
            };
        case 'company/getCapitalsRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'company/getCapitalsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                capitals: []
            };
        case 'company/getCapitalsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                capitals: action.capitals
            };
        case 'company/getFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                capitals: []
            };
        case 'company/getAllRequest':
            return {
                ...state,
                action: action.type,
                loading: true,
            };
        case 'company/getAllSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                companies: action.companies
            };
        case 'company/getAllFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                companies: []
            };

        case 'company/getAllDeletedSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                companyDisabled: action.company
            };
        case 'user/getAllDeletedFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'company/uploadLogoRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'company/uploadLogoSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                logo: action.logo
            };
        case 'company/uploadLogoFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                logo: {}
            };
        case 'company/removeLogoRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'company/removeLogoSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                logo: {}
            };
        case 'company/removeLogoFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'company/getLogoRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'company/getLogoSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                logo: action.logo
            };
        case 'company/getLogoFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                logo: {}
            };
        case 'company/clearSuccess':
            return {
                ...state,
                action: action.type,
            };
        default:
            return state;
    }
}
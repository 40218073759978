import React, { useEffect, useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import PrivateRoute from '@components/Routing/PrivateRoute';
import PublicRoute from '@components/Routing/PublicRoute';
import CustomAppBar from '@components/AppBar/AppBar';
import BasicAlert from '@components/Alert/BasicAlert';
import Loading from '@components/Loading/Loading';
import { Routes, AppBarItems } from './Routes'
import { AppTheme } from './AppTheme';
import Footer from '@components/Modal/Footer';

export default function App() {
    const [openAlert, setOpenAlert] = useState(false);

    const userReducer = useSelector(state => state.userReducer);
    const companyReducer = useSelector(state => state.companyReducer);
    const certificationReducer = useSelector(state => state.certificationReducer);
    const officeReducer = useSelector(state => state.officeReducer);
    const alert = useSelector(state => state.alertReducer);

    const routes = Routes.map((route, index) => {
        const Route = route.public === true ? PublicRoute : PrivateRoute;

        return (
            <Route
                key={index}
                role={route.role}
                exact={route.exact}
                restricted={route.restricted}
                isAuthenticated={userReducer.userInfos.loggedIn}
                path={route.path}
                component={route.component}
            />
        );
    });

    useEffect(() => {
        if (alert.type)
            setOpenAlert(true);
    }, [alert]);

    return (
        <div style={{ height: '100%' }}>
            <ThemeProvider theme={AppTheme}>
                {
                    userReducer.userInfos.loggedIn
                    ? <CustomAppBar navigations={() => AppBarItems(userReducer.userInfos.role)} />
                    : <></>
                }
                {
                    userReducer.loading || companyReducer.loading || certificationReducer.loading ||
                    officeReducer.loading
                    ? <Loading />
                    : <></>
                }
                {
                    userReducer.userInfos.loggedIn
                    ? <Footer />
                    : <></>
                }
                <Switch>
                    {routes}
                    <Redirect to='/not-found' />
                </Switch>
            </ThemeProvider>
            {
                alert.type ?
                <BasicAlert open={openAlert} setOpen={setOpenAlert} message={alert.message} type={alert.type} />
                : <></>
            }
        </div>
    );
}
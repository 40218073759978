import { Button } from "@material-ui/core"
import AddIcon from '@mui/icons-material/Add';

export const NewButton = ({ createLabel, onCreate }) => {
    if (!onCreate)
        return <></>

    return (
        <Button variant="outlined" color="secondary" fullWidth={true} startIcon={<AddIcon />} onClick={onCreate}>
            {createLabel}
        </Button>
    );
};
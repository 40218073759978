import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button
} from '@material-ui/core';
import { userActions } from '@actions/user.actions';
import { companyActions } from '@actions/company.actions';

export default function LinkAdmin({ open, setOpen, user }) {
    const [newOffices, setNewOffices] = useState([]);

    const dispatch = useDispatch();
    const companies = useSelector(state => state.companyReducer.companies);
    const userReducer = useSelector(state => state.userReducer);

    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        setNewOffices(e.target.value);
    };

    const onSubmit = () => {
        let companyId, officeId, index;
        let access_control_list = [];

        newOffices.forEach(id => {
            companyId = companies.find(company => company.offices.some(office => office.id === id)).id;
            officeId = id;
            index = access_control_list.findIndex(list => list.companyId === companyId);
            if (index < 0) {
                access_control_list.push({
                    companyId: companyId,
                    officeIds: [officeId]
                });
            } else
                access_control_list[index].officeIds.push(officeId);
        });

        dispatch(userActions.changeOffices(user.id, { access_control_list }));
    }

    useEffect(() => {
        let newOfficesTmp = [];

        if (companies.length === 0) {
            dispatch(companyActions.getAll());
        }
        user.__access_control_list__.forEach(list => {
            list.offices.forEach(office => {
                newOfficesTmp.push(office.id);
            });
        });
        setNewOffices(newOfficesTmp);
    }, [dispatch, user, companies]);

    useEffect(() => {
        if (userReducer.action === 'user/changeOfficesSuccess') {
            dispatch(userActions.clearSuccess());
            dispatch(userActions.getAll());
            setOpen(false);
        }
    }, [userReducer, dispatch, setOpen]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
            <DialogTitle>Lier l'administrateur à un site</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} style={{ margin: '50px 0' }}>
                    <InputLabel>Sites</InputLabel>
                    <Select
                        multiple
                        value={newOffices}
                        onChange={handleChange}
                        renderValue={ids => (
                            ids.map(id => (
                                companies.find(company => (
                                    company.offices.some(office => office.id === id))
                                ).name + ' - ' + companies[companies.findIndex(company => (
                                    company.offices.find(office => office.id === id))
                                )].offices.find(office => office.id === id).label)
                            ).join(', ')
                        )}
                    >
                        {
                            companies.map(company => (
                                company.offices.map(office => (
                                    <MenuItem key={office.id} value={office.id}>
                                        <Checkbox checked={newOffices.indexOf(office.id) > -1} />
                                        <ListItemText primary={company.name + ' - ' + office.label} />
                                    </MenuItem>
                                ))
                            ))
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">Annuler</Button>
                <Button onClick={onSubmit} color="secondary">Sauvegarder</Button>
            </DialogActions>
        </Dialog>
    );
}
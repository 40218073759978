import React from 'react';
import CustomSubAppBar from '@components/AppBar/CustomSubAppBar';
import {useSelector} from "react-redux";


export default function OfficeSubAppBar({ companyId, officeId }) {
    const companyReducer = useSelector(state => state.officeReducer);
    let items = []
    console.log(companyReducer.office.limitAmountOfCapitals);
    if(companyReducer.office.limitAmountOfCapitals > 0) {
        items = [
            {
                label: 'Infos',
                path: `/companies/${companyId}/offices/${officeId}/infos`
            },
            {
                label: 'événements',
                path: `/companies/${companyId}/offices/${officeId}/events`
            },
            {
                label: 'Rapport de visite',
                path: `/companies/${companyId}/offices/${officeId}/visit-reports`
            },
            {
                label: 'Plan de suivi et de visite',
                path: `/companies/${companyId}/offices/${officeId}/visit-plans`
            },
            {
                label: 'Permis de feu',
                path: `/companies/${companyId}/offices/${officeId}/fire-permits`
            },
            {
                label: 'Marchandises pour compte de tiers',
                path: `/companies/${companyId}/offices/${officeId}/deposits`
            },
            {
                label: 'Bail',
                path: `/companies/${companyId}/offices/${officeId}/leases`
            }
        ]
    }else{
        items = [
            {
                label: 'Infos',
                path: `/companies/${companyId}/offices/${officeId}/infos`
            },
            {
                label: 'événements',
                path: `/companies/${companyId}/offices/${officeId}/events`
            },
            {
                label: 'Rapport de visite',
                path: `/companies/${companyId}/offices/${officeId}/visit-reports`
            },
            {
                label: 'Plan de suivi et de visite',
                path: `/companies/${companyId}/offices/${officeId}/visit-plans`
            },
            {
                label: 'Permis de feu',
                path: `/companies/${companyId}/offices/${officeId}/fire-permits`
            },
            {
                label: 'Bail',
                path: `/companies/${companyId}/offices/${officeId}/leases`
            }
        ]
    }
    return <CustomSubAppBar items={items} />
}
import React, { useEffect, useState } from 'react';
import CustomTable from '@components/Table/CustomTable';

const columns = [
    { field: 'lastname', headerName: 'Nom', align: 'right', style: { 'textTransform': 'uppercase' } },
    { field: 'firstname', headerName: 'Prénom', align: 'center', style: { 'textTransform': 'capitalize' } },
    { field: 'email', headerName: 'E-mail', align: 'center', style: { 'textTransform': 'lowercase' } },
    { field: 'phone', headerName: 'Téléphone', align: 'left' },
    { field: 'role', headerName: 'Rôle', align: 'center', style: { 'textTransform': 'capitalize' } },
];

function Users({ users, office }) {
    const [usersAssigned, setUsersAssigned] = useState([]);

    useEffect(() => {
        let usersAssignedTmp = [];
        users?.forEach(user => {
            switch (user.role) {
                case 'admin':
                    user.role = 'Admin IBS';
                    break;
                case 'user':
                    user.role = 'Utilisateur';
                    break;
                case 'viewer':
                    user.role = 'Consultation';
                    break;
                default:
                    break;
            };
            usersAssignedTmp.push(user);
        });

        setUsersAssigned(usersAssignedTmp);
    }, [users, office]);

    return (
        <div style={{ marginBottom: 20 }}>
            <CustomTable
                columns={columns}
                rows={usersAssigned}
                title="Liste des utilisateurs assignés au site"
            />
        </div>
    );
}

export default Users;
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { stringToLocalDate } from '@helpers/formatDate';

const useStyles = makeStyles(() => ({
    content: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        '& h1': {
            margin: 0,
            display: 'inline',
            fontWeight: 500,
            fontSize: 20
        },
        '& h2': {
            margin: 0,
            display: 'inline',
            fontWeight: 400,
            fontSize: 18
        }
    },
    title: {
        border: 'lightgray 2px solid',
        background: 'lightgray',
        borderBottom: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        '& h1': {
            fontSize: 20,
            fontWeight: 900
        }
    },
    names: {
        border: 'lightgray 2px solid',
        borderBottom: 'none',
        padding: '10px 20px',
        display: 'flex'
    },
    contact: {
        border: 'lightgray 2px solid',
        padding: '10px 20px',
        display: 'flex',
    },
    address: {
        border: 'lightgray 2px solid',
        borderBottom: 'none',
        padding: '10px 20px',
    },
    country: {
        border: 'lightgray 2px solid',
        borderBottom: 'none',
        padding: '10px 20px',
        display: 'flex'
    },
    contract: {
        border: 'lightgray 2px solid',
        borderBottom: 'none',
        padding: '10px 20px',
        display: 'flex',
    },
    logos: {
        display: 'flex',
        textAlign: 'center',
    }
}));

export default function Office({ office, company, logo, picture }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.content}>
            <Grid className={classes.title}>
                <h1>Informations sur le site</h1>
            </Grid>

            <Grid>
                <Grid item className={classes.names}>
                    <Grid item xs={3}>
                        <h1>Nom: </h1>
                        <h2>{office.label.toUpperCase()}</h2>
                    </Grid>
                    <Grid item xs={3}>
                        <h1>Siège social: </h1>
                        <h2>{office.main ? 'Oui' : 'Non'}</h2>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid item xs={12} className={classes.address}>
                        <h1>Adresse: </h1>
                        <h2>{office.address.street_number + ' ' + office.address.street_name}</h2>
                    </Grid>
                    <Grid className={classes.country}>
                        <Grid item xs={3}>
                            <h1>Code postal: </h1>
                            <h2>{office.address.zip_code}</h2>
                        </Grid>
                        <Grid item xs={3} className={classes.city}>
                            <h1>Ville: </h1>
                            <h2>{office.address.city.toUpperCase()}</h2>
                        </Grid>
                        <Grid item xs={3}>
                            <h1>Pays: </h1>
                            <h2>{office.address.country.toUpperCase()}</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.contract}>
                    <Grid item xs={3}>
                        <h1>Début du contrat d'assurance: </h1>
                        <h2>{stringToLocalDate(office.contractDate.start)}</h2>
                    </Grid>
                    <Grid item xs={3}>
                        <h1>Échéance: </h1>
                        <h2>{stringToLocalDate(office.contractDate.end)}</h2>
                    </Grid>
                </Grid>


                <Grid className={classes.subcontent} py={10}>

                    {
                        company?.contactPerson && company.contactPerson?.email && company.contactPerson.email !== '' ?
                            <>
                                <Grid className={classes.title} style={{ background: 'rgba(220, 220, 220, 0.6)' }}>
                                    <h1>Informations sur le contact de la société: </h1>
                                </Grid>
                                <Grid item className={classes.contact}>
                                    <Grid item xs={3}>
                                        <h1>Nom: </h1>
                                        <h2>{company?.contactPerson?.lastname.toUpperCase()}</h2>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h1>Prénom: </h1>
                                        <h2>{company?.contactPerson?.firstname}</h2>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h1>Fonction: </h1>
                                        <h2>{company?.contactPerson?.job}</h2>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h1>E-mail: </h1>
                                        <h2>{company?.contactPerson?.email.toLowerCase()}</h2>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h1>Téléphone: </h1>
                                        <h2>{company?.contactPerson?.phone}</h2>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>
                    }

                    {
                        office?.contactPerson && office.contactPerson?.email && office.contactPerson.email !== '' ?
                            <>
                                <Grid className={classes.title} style={{ background: 'rgba(220, 220, 220, 0.6)' }}>
                                    <h1>Informations sur le contact du Site: </h1>
                                </Grid>
                                <Grid item className={classes.contact}>
                                    <Grid item xs={6}>
                                        <h1>Nom: </h1>
                                        <h2>{office.contactPerson.lastname.toUpperCase()}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Prénom: </h1>
                                        <h2>{office.contactPerson.firstname}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Fonction: </h1>
                                        <h2>{office.contactPerson.job}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>E-mail: </h1>
                                        <h2>{office.contactPerson.email.toLowerCase()}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Téléphone: </h1>
                                        <h2>{office.contactPerson.phone}</h2>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>
                    }

                </Grid>

                <Grid className={classes.subcontent} py={10}>
                    {
                        company?.accountingPerson && company?.accountingPerson.email && company?.accountingPerson !== '' ?
                            <>
                                <Grid className={classes.title} style={{ background: 'rgba(220, 220, 220, 0.6)' }}>
                                    <h1>Informations sur la comptabilité de la société: </h1>
                                </Grid>
                                <Grid item className={classes.contact}>
                                    <Grid item xs={6}>
                                        <h1>Nom: </h1>
                                        <h2>{company?.accountingPerson?.lastname.toUpperCase()}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Prénom: </h1>
                                        <h2>{company?.accountingPerson?.firstname}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Fonction: </h1>
                                        <h2>{company?.accountingPerson?.job}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>E-mail: </h1>
                                        <h2>{company?.accountingPerson?.email.toLowerCase()}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Téléphone: </h1>
                                        <h2>{company?.accountingPerson?.phone}</h2>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>
                    }

                    {
                        office?.accountingPerson && office?.accountingPerson.email && office?.accountingPerson !== '' ?
                            <>
                                <Grid className={classes.title} style={{ background: 'rgba(220, 220, 220, 0.6)' }}>
                                    <h1>Informations sur la comptabilité du site: </h1>
                                </Grid>
                                <Grid item className={classes.contact}>
                                    <Grid item xs={6}>
                                        <h1>Nom: </h1>
                                        <h2>{office?.accountingPerson?.lastname.toUpperCase()}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Prénom: </h1>
                                        <h2>{office?.accountingPerson?.firstname}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Fonction: </h1>
                                        <h2>{office?.accountingPerson?.job}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>E-mail: </h1>
                                        <h2>{office?.accountingPerson?.email.toLowerCase()}</h2>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <h1>Téléphone: </h1>
                                        <h2>{office?.accountingPerson?.phone}</h2>
                                    </Grid>
                                </Grid>
                            </>
                            : <></>
                    }

                </Grid>

                {
                    <Grid item xs={12} className={classes.logos}>
                        {
                            logo instanceof Blob
                                ? <Grid item xs={6} style={{ margin: '50px 0' }}>
                                    <img src={URL.createObjectURL(logo)} alt='Site logo' height={300} />
                                </Grid>
                                : <></>
                        }
                        {
                            picture instanceof Blob
                                ? <Grid item xs={6} style={{ margin: '50px 0' }}>
                                    <img src={URL.createObjectURL(picture)} alt='Visuel du site' height={300} />
                                </Grid>
                                : <></>
                        }
                    </Grid>
                }
            </Grid>

        </Grid>
    );
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Divider, Grid } from '@material-ui/core';
import { eventActions } from '@actions/event.actions';
import { reserveActions } from '@actions/reserve.actions';
import { billActions } from '@actions/bill.actions';
import { officeActions } from '@actions/office.actions';
import NoFeedBack from './Feedback/NoFeedBack';
import Feedback from './Feedback/Feedback';
import Reserves from './Reserves/Reserves';
import Resume from './Resume/Resume';
import Bills from './Bills/Bills';
import OfficeSubAppBar from '../Office';
import isEmpty from '@helpers/isEmpty';

function Reminder() {
    const { companyId, officeId, eventId, cycleId } = useParams();
    const history = useHistory();

    const dispatch = useDispatch();
    const office = useSelector(state => state.officeReducer.office);
    const file = useSelector(state => state.eventReducer.file);
    const eventReducer = useSelector(state => state.eventReducer);
    const event = useSelector(state => state.eventReducer.event);

    const [newEvent, setNewEvent] = useState({});

    const handleFinishedEvent = () => {
        dispatch(eventActions.update(eventId, cycleId, { status: 1 }));
        history.goBack();
    }

    useEffect(() => {
        dispatch(eventActions.getEvent(eventId));
        dispatch(billActions.getAll(eventId, cycleId));
        dispatch(reserveActions.getAll(eventId, cycleId));
        if (!office.id)
            dispatch(officeActions.get(companyId, officeId));
    }, [dispatch, companyId, cycleId, eventId, office, officeId]);

    useEffect(() => {
        if (eventReducer.action === 'event/getSuccess') {
            let newEvent = event.cycles.find(cycle => cycle.id === parseInt(cycleId));

            if (!isEmpty(event.certification))
                newEvent.label = event.certification.label + ' ' + event.certification.description + ' - ' + event.label;
            else
                newEvent.label = event.type + ' - ' + event.label;
            newEvent.type = event.type;
            newEvent.frequency = event.frequency;
            newEvent.priority = event.priority;
            newEvent.author = event.author;
            setNewEvent(newEvent);
            dispatch(eventActions.clearSuccess());
        } else if (eventReducer.action === 'event/uploadFileSuccess' ||
        eventReducer.action === 'event/updateSuccess' || eventReducer.action === 'event/deleteProofSuccess') {
            dispatch(eventActions.getEvent(eventId));
            dispatch(eventActions.clearSuccess());
        }
    }, [dispatch, eventReducer, cycleId, event, eventId]);

    return (
        <Grid container>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Grid container justifyContent='center'>
                <Grid item xs={12} style={{ margin: 20 }}>
                    <Button color='secondary' variant='outlined' onClick={() => history.goBack()}>
                        Retour
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20, textAlign: 'center' }}>
                    <h1>{newEvent.label}</h1>
                    <Divider variant='middle' />
                </Grid>
                {
                    !isEmpty(newEvent)
                    ? <Resume event={newEvent} eventId={eventId} />
                    : <></>
                }
                {
                    !isEmpty(newEvent.proofs)
                    ? <Feedback
                        event={newEvent}
                        eventId={eventId}
                        cycleId={cycleId}
                        file={file}
                    />
                    : <NoFeedBack
                        event={newEvent}
                        eventId={eventId}
                        cycleId={cycleId}
                    />
                }
                <Bills eventId={eventId} cycleId={cycleId} event={newEvent} />
                {
                    event.compulsory_proof
                    ? newEvent.hasReserves
                        ? <Reserves />
                        : <></>
                    : <>
                        <Reserves />
                        {
                            newEvent.status !== 0
                            ? <></>
                            : <div style={{ margin: '20px auto 50px' }}>
                                <Button variant='outlined' component='div' color='secondary' onClick={handleFinishedEvent} style={{ width: 250, height: 70 }}>
                                    Clore l'événement
                                </Button>
                            </div>
                        }
                    </>
                }
            </Grid>
        </Grid>
    );
}

export default Reminder;
import { Api } from '@config/api';

export const officeService = {
    create,
    update,
    get,
    uploadDocument,
    getDocument,
    deleteDocument,
    getCapital,
    createCapital,
    getDeposits,
    createDeposit,
    updateDeposit,
    deleteDeposit,
    downloadWaiver,
    uploadWaiver,
    downloadTemplateWaiver,
    updateLease
};

function create(companyId, properties) {
    return Api.post(`/companies/${companyId}/offices`, properties).then(handleResponse);
}

function update(companyId, officeId, properties) {
    return Api.patch(`/companies/${companyId}/offices/${officeId}`, properties).then(handleResponse);
}

function get(companyId, officeId) {
    return Api.get(`/companies/${companyId}/offices/${officeId}`).then(handleResponse);
}

function uploadDocument(companyId, officeId, type, properties) {
    return Api.post(`/companies/${companyId}/offices/${officeId}/documents?type=${type}`, properties, { headers: { 'Content-Type': 'multipart/form-data' } }).then(handleResponse);
}

function getDocument(companyId, officeId, fileId, type) {
    return Api.get(`/companies/${companyId}/offices/${officeId}/documents/${fileId}?type=${type}`, { responseType: 'blob' }).then(handleResponse);
}

function deleteDocument(companyId, officeId, fileId, type) {
    return Api.delete(`/companies/${companyId}/offices/${officeId}/documents/${fileId}?type=${type}`).then(handleResponse);
}

function getCapital(companyId, officeId) {
    return Api.get(`/companies/${companyId}/offices/${officeId}/capitals`).then(handleResponse);
}

function createCapital(companyId, officeId, properties) {
    return Api.post(`/companies/${companyId}/offices/${officeId}/capitals`, properties).then(handleResponse);
}

function getDeposits(companyId, officeId) {
    return Api.get(`/companies/${companyId}/offices/${officeId}/deposits`).then(handleResponse);
}

function createDeposit(companyId, officeId, properties) {
    return Api.post(`/companies/${companyId}/offices/${officeId}/deposits`, properties).then(handleResponse);
}

function deleteDeposit(companyId, officeId, id) {
    return Api.delete(`/companies/${companyId}/offices/${officeId}/deposits/${id}`).then(handleResponse);
}

function updateDeposit(companyId, officeId, id, properties) {
    return Api.patch(`/companies/${companyId}/offices/${officeId}/deposits/${id}`, properties).then(handleResponse);
}

function downloadWaiver(companyId, officeId, id) {
    return Api.get(`/companies/${companyId}/offices/${officeId}/deposits/${id}/waiver`).then(handleResponse);
}

function uploadWaiver(companyId, officeId, id, file) {
    return Api.post(`/companies/${companyId}/offices/${officeId}/deposits/${id}/waiver`, file, { headers: { 'Content-Type': 'multipart/form-data' } }).then(handleResponse);
}

function updateLease(companyId, officeId, id, properties) {
    return Api.patch(`/companies/${companyId}/offices/${officeId}/leases/${id}`, properties, { headers: { 'Content-Type': 'multipart/form-data' } }).then(handleResponse);
}

function downloadTemplateWaiver(companyId, officeId, id) {
    return Api.get(`/companies/${companyId}/offices/${officeId}/deposits/${id}/waiver/template`).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;
        return Promise.reject(error);
    }
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, makeStyles, FormControl, InputLabel, InputAdornment, Input } from '@material-ui/core';
import { Search } from '@mui/icons-material';
import { useHistory } from 'react-router';
import fileDownload from 'js-file-download';
import { companyActions } from '@actions/company.actions';
import { officeActions } from '@actions/office.actions';
import CustomAccordion from '@components/Table/CustomAccordion';
import FirePermits from '@assets/Template/fire_permits.pdf';
import checkList from '@assets/Template/Check_list_inspection_mensuelle_IBS.doc';
import AdminPanelSubAppBar from "../AdminPanel";

const useStyles = makeStyles(() => ({
    card: {
        margin: 20,
    },
    searchField: {
        width: '20%',
        margin: '30px auto',
        textAlign: 'center'
    }
}));

export default function Landing() {
    const classes = useStyles();
    const history = useHistory();

    const [userCompanies, setUserCompanies] = useState([]);
    const [userCompaniesFiltered, setUserCompaniesFiltered] = useState([]);
    const [fileName, setFileName] = useState('file');

    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.userInfos);
    const companies = useSelector(state => state.companyReducer.companyDisabled);
    const office = useSelector(state => state.officeReducer);

    const onVisualize = (office) => {
        let company;

        if (user.role === 'user' || user.role === 'viewer')
            company = userCompanies.find(company => company.offices.includes(office));
        else
            company = companies.find(company => company.offices.some(officeTmp => officeTmp.id === office.id));

        if (company)
            history.push({ pathname: `/companies/${company.id}/offices/${office.id}/infos`, state: { company: company, office: office } });
    }

    const onCreateCompany = () => {
        history.push('/create-company');
    }

    const onExport = () => {
        history.push('/export');
    }

    const onCreateOffice = (company) => {
        history.push({ pathname: `/companies/${company.id}/create-office`, state: { companyId: company.id } });
    }

    const onUpdateCompany = (company) => {
        history.push({ pathname: `/companies/${company.id}/update-company`, state: { company: company } });
    }

    // const onDeleteCompany = (e, company) => {
    //     dispatch(companyActions.remove(company.id));
    // }

    const onDeleteCompany = (company) => {
        dispatch(companyActions.remove(company.id, {soft_deleted: false}));
        window.location.reload();
    }
    const onUpdateOffice = (office) => {
        const company = companies.find(company => company.offices.includes(office));

        if (company)
            history.push({ pathname: `/companies/${company.id}/offices/${office.id}/update-office`, state: { company: company, office: office } });
    }

    const handleFiles = (company, office, fileType, fileId) => {
        if (fileType === 'visit_plans') {
            let link = document.createElement('a');

            link.setAttribute('href', checkList);
            link.setAttribute('download', 'Check_list_inspection_mensuelle_IBS.doc');
            document.body.appendChild(link);
            link.click();
        } else if (fileType === 'fire_permits') {
            let link = document.createElement('a');

            link.setAttribute('href', FirePermits);
            link.setAttribute('download', 'template_fire_permits_IBS.pdf');
            document.body.appendChild(link);
            link.click();
        } else {
            let latestDate = new Date(Math.max(...office.visit_reports.map(file => new Date(file.comment))));

            office.visit_reports.every(file => {
                if (new Date(file.comment).getTime() === latestDate.getTime()) {
                    setFileName(file.originalname);
                    dispatch(officeActions.getDocument(company.id, office.id, fileId, 'visit_reports'));
                    return true;
                }
                return false;
            })
        }
    }

    const filterArray = (e) => {
        let input = e.target.value;
        let newUserCompanies = [];

        userCompanies.forEach(company => {
            return Object.entries(company).some(([key, value]) => {
                if (key === 'name' && value.toLowerCase().includes(input.toLowerCase())) {
                    newUserCompanies.push(company);
                    return true;
                } else if (key === 'contactPerson') {
                    return Object.entries(value).some(([key2, value2]) => {
                        if (['email', 'firstname', 'job', 'lastname', 'phone'].includes(key2) &&
                            value2.toLowerCase().includes(input.toLowerCase())) {
                            newUserCompanies.push(company);
                            return true;
                        }
                        return false;
                    });
                } else if (key === 'offices') {
                    value.some(office => {
                        return Object.entries(office).some(([key, value]) => {
                            if (['label', 'location'].includes(key) &&
                                value.toLowerCase().includes(input.toLowerCase())) {
                                newUserCompanies.push(company);
                                return true;
                            }
                            return false;
                        });
                    });
                }
                return false;
            });
        });
        setUserCompaniesFiltered(newUserCompanies);
    }

    useEffect(() => {
        if (user.role === 'admin' && companies.length === 0) {
            dispatch(companyActions.getAllDeleted());
        }
    }, [companies, user, dispatch]);

    useEffect(() => {
        let newUserCompanies = [];

        if (user.role === 'admin') {
            companies.forEach(company => {
                company.offices.forEach(office => {
                    let streetNumber = office.address.street_number;
                    let streetName = office.address.street_name;
                    let zipCode = office.address.zip_code;
                    let city = office.address.city;
                    let country = office.address.country;

                    office.location = streetNumber + ' ' + streetName + ' ' + zipCode + ' ' + city.toUpperCase() + ', ' + country.toUpperCase();
                });
                newUserCompanies.push(company);
            });
            setUserCompaniesFiltered(newUserCompanies);
            setUserCompanies(newUserCompanies);
        } else {
            user.__access_control_list__.forEach(access => {
                access.company.offices.forEach(office => {
                    let streetNumber = office.address.street_number;
                    let streetName = office.address.street_name;
                    let zipCode = office.address.zip_code;
                    let city = office.address.city;
                    let country = office.address.country;

                    office.location = streetNumber + ' ' + streetName + ' ' + zipCode + ' ' + city.toUpperCase() + ', ' + country.toUpperCase();
                });
                access.company.offices.forEach((officeCompany, index) => {
                    let isAssigned = access.offices.some(office => office.id === officeCompany.id);

                    if (!isAssigned)
                        access.company.offices.splice(index, 1);
                })
                newUserCompanies.push(access.company);
            });
            setUserCompaniesFiltered(newUserCompanies);
            setUserCompanies(newUserCompanies);
        }
    }, [companies, user]);

    useEffect(() => {
        if (office.action === 'office/getDocumentSuccess') {
            fileDownload(office.file, fileName);
            dispatch(officeActions.clearSuccess());
        }
    }, [office, dispatch, fileName]);

    return (
        <>
            <AdminPanelSubAppBar />
            <div className={classes.searchField}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">Rechercher...</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        onKeyUp={filterArray}
                        endAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <Card className={classes.card}>
                <CustomAccordion
                    companies={userCompaniesFiltered}
                    handleFiles={handleFiles}
                    onCreate={user.role === 'admin' ? onCreateCompany : null}
                    onReminderExport={onExport}
                    onDepositExport={(e) => { history.push('/export/deposit'); }}
                    onUpdateCompany={user.role === 'admin' ? onUpdateCompany : null}
                    onDeleteCompany={user.role === 'admin' ? onDeleteCompany : null}
                    onCreateOffice={user.role === 'admin' ? onCreateOffice : null}
                    onUpdateOffice={user.role === 'admin' ? onUpdateOffice : null}
                    onVisualize={onVisualize}
                />
            </Card>
        </>
    );
}
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    TextField, FormControl, InputLabel, Select, Grid, Button, ListItemText, Checkbox, Box, Chip, MenuItem,
    FormLabel, FormGroup, FormControlLabel, RadioGroup, Radio
} from '@material-ui/core';
import { eventActions } from '@actions/event.actions';
import formatDate, { stringToLocalDate } from '@helpers/formatDate';

export default function Export() {
    const history = useHistory();

    const dispatch = useDispatch();
    const eventReducer = useSelector(state => state.eventReducer);
    const events = useSelector(state => state.eventReducer.events);
    const user = useSelector(state => state.userReducer.userInfos);

    const [selectAllDates, setSelectAllDates] = useState(true);
    const [officesId, setOfficesId] = useState([]);
    const [status, setStatus] = useState({
        pending: 0,
        ended: 1,
        stopped: 2
    });
    const [priority, setPriority] = useState({
        high: 1,
        mid: 2,
        low: 3
    });
    const [eventType, setEventType] = useState({
        certification: 'certification',
        reserve: 'reserve',
        maintenance: 'maintenance',
        recommandation: 'recommandation',
        autre: 'other'
    });

    const handleSelectAllDates = (e) => {
        setSelectAllDates(e.target.value === 'true');
    }

    const handleChange = (e) => {
        setOfficesId(e.target.value);
    }

    const handleChangeStatus = (e, type) => {
        const statusTmp = {
            ...status,
            [`${type}`]: e.target.checked ? parseInt(e.target.value) : undefined
        }

        setStatus(statusTmp);
    }

    const handleChangePriority = (e, type) => {
        const priorityTmp = {
            ...priority,
            [`${type}`]: e.target.checked ? parseInt(e.target.value) : undefined
        }

        setPriority(priorityTmp);
    }

    const handleChangeEventType = (e, type) => {
        const eventTypeTmp = {
            ...eventType,
            [`${type}`]: e.target.checked ? e.target.value : undefined
        }

        setEventType(eventTypeTmp);
    }

    const handleExport = () => {
        const startDate = new Date(document.getElementById('startDate').value);
        const endDate = new Date(document.getElementById('endDate').value);
        const companiesId = officesId.flatMap(id => {
            return user.__access_control_list__.find(access => {
                return access.company.offices.some(office => (office.id === id));
            }).company.id;
        }).filter((item, pos, self) => self.indexOf(item) === pos);

        const properties = {
            companyIds: companiesId,
            officeIds: officesId,
            period: {
                start: isNaN(startDate.getTime()) || selectAllDates ? undefined : startDate,
                end: isNaN(endDate.getTime()) || selectAllDates ? undefined : endDate
            },
            filter: {
                status: Object.values(status).filter(state => state !== undefined),
                priorities: Object.values(priority).filter(state => state !== undefined),
                types: Object.values(eventType).filter(state => state !== undefined)
            }
        }
        dispatch(eventActions.getExportedEvents(properties));
    }

    useEffect(() => {
        let officesIdTmp = [];

        user.__access_control_list__.forEach(access => {
            access.offices.forEach(office => {
                officesIdTmp.push(office.id);
            });
        });
        setOfficesId(officesIdTmp);
    }, [user]);

    useEffect(() => {
        if (eventReducer.action === 'event/getExportedEventsSuccess') {
            const types = ['En cours', 'Terminé', 'Sans Suite'];
            const priorities = ['Haute', 'Moyenne', 'Basse'];
            const headers = ["Site", "Itération de cycle", "Évènement", "Statut", "Type", "Nombre de réserve", "Date", "Fréquence", "Priorité", "Nombre de pièces justificatives"];
            const rows = [
                ...events.map(item => [
                    item.office,
                    item.cycle,
                    item.label,
                    types[item.status],
                    item.type,
                    item.reserves.length,
                    stringToLocalDate(item.start_date),
                    item.frequency ?? "none",
                    priorities[item.priority - 1],
                    item.proofs.length
                ])
            ];
            const csvContent = 'data:text/csv;sep=;;charset=UTF-8,'
                + headers.join(';') + '\n'
                + rows.map(event => event.join(';')).join('\n');
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');

            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `Export_evenement_${formatDate(new Date())}.csv`);
            document.body.appendChild(link);
            link.click();
        }
    }, [eventReducer, events]);

    return (
        <div>
            <Grid item xs={12} style={{ margin: 20 }}>
                <Button color='secondary' variant='outlined' onClick={() => history.goBack()}>
                    Retour
                </Button>
            </Grid>
            <div style={{ textAlign: 'center' }}>
                <h1 style={{ textAlign: 'center', margin: 50 }}>Sélectionner les paramètres de l'export</h1>
                <div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            value={selectAllDates}
                            onChange={handleSelectAllDates}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Récupérer tous les événements" />
                            <FormControlLabel value={false} control={<Radio />} label="Sélectionner des dates" />
                        </RadioGroup>
                    </FormControl>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            disabled={selectAllDates}
                            variant="outlined"
                            id='startDate'
                            type='date'
                            label="Date de début de l'export"
                            InputLabelProps={{ shrink: true }}
                            style={{ margin: '10px 20px 0' }}
                            fullWidth={true}
                        />
                        <TextField
                            disabled={selectAllDates}
                            variant="outlined"
                            id='endDate'
                            type='date'
                            label="Date de fin de l'export"
                            InputLabelProps={{ shrink: true }}
                            style={{ margin: '10px 20px 0' }}
                            fullWidth={true}
                        />
                    </div>
                </div>
                <FormControl style={{ margin: '10px 20px', width: '70%' }}>
                    <InputLabel>Sélectionnez vos sites</InputLabel>
                    <Select
                        multiple
                        onChange={handleChange}
                        value={officesId}
                        renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {
                                    selected.map(value => {
                                        let label = user.__access_control_list__.flatMap(access => access.offices.filter(office => office.id === value).map(office => office.label));

                                        return <Chip key={Math.random() * 100} label={label} />
                                    })
                                }
                            </Box>
                        )}
                    >
                        {
                            user.__access_control_list__.map(access => (
                                access.offices.map(office => (
                                    <MenuItem key={office.id} value={office.id}>
                                        <Checkbox checked={officesId.indexOf(office.id) > -1} />
                                        <ListItemText primary={`${access.company.name} - ${office.label}`} />
                                    </MenuItem>
                                ))
                            ))
                        }
                    </Select>
                </FormControl>
                <Grid container style={{ marginTop: 50 }}>
                    <Grid item xs={4}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={{ marginBottom: 20 }}>Statuts des événement</FormLabel>
                            <FormGroup aria-label="position" column='true'>
                                <FormControlLabel
                                    value={0}
                                    control={<Checkbox checked={status['pending'] !== undefined} onChange={(e) => handleChangeStatus(e, 'pending')} />}
                                    label="En cours"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={<Checkbox checked={status['ended'] !== undefined} onChange={(e) => handleChangeStatus(e, 'ended')} />}
                                    label="Terminé"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Checkbox checked={status['stopped'] !== undefined} onChange={(e) => handleChangeStatus(e, 'stopped')} />}
                                    label="Sans suite"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={{ marginBottom: 20 }}>Priorité des événements</FormLabel>
                            <FormGroup aria-label="position" column='true'>
                                <FormControlLabel
                                    value={1}
                                    control={<Checkbox checked={priority['high'] !== undefined} onChange={(e) => handleChangePriority(e, 'high')} />}
                                    label="Haute"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Checkbox checked={priority['mid'] !== undefined} onChange={(e) => handleChangePriority(e, 'mid')} />}
                                    label="Moyenne"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={3}
                                    control={<Checkbox checked={priority['low'] !== undefined} onChange={(e) => handleChangePriority(e, 'low')} />}
                                    label="Basse"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend" style={{ marginBottom: 20 }}>Type d'événements</FormLabel>
                            <FormGroup aria-label="position" column='true'>
                                <FormControlLabel
                                    value="certification"
                                    control={<Checkbox checked={eventType['certification'] !== undefined} onChange={(e) => handleChangeEventType(e, 'certification')} />}
                                    label="Certification"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="reserve"
                                    control={<Checkbox checked={eventType['reserve'] !== undefined} onChange={(e) => handleChangeEventType(e, 'reserve')} />}
                                    label="Réserve de rapport de visite"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="recommandation"
                                    control={<Checkbox checked={eventType['recommandation'] !== undefined} onChange={(e) => handleChangeEventType(e, 'recommandation')} />}
                                    label="Recommandation"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="maintenance"
                                    control={<Checkbox checked={eventType['maintenance'] !== undefined} onChange={(e) => handleChangeEventType(e, 'maintenance')} />}
                                    label="Maintenance"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="other"
                                    control={<Checkbox checked={eventType['autre'] !== undefined} onChange={(e) => handleChangeEventType(e, 'autre')} />}
                                    label="Bonne pratique"
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
            <div style={{ margin: '50px auto', textAlign: 'center' }}>
                <Button variant="outlined" color='secondary' onClick={handleExport}>Exporter mes événements</Button>
            </div>
        </div>
    );
}
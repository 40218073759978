import Login from '@views/Login/Login';
import LegalNotice from '@views/LegalNotice/LegalNotice';
import Landing from '@views/Landing/Landing';
import Export from '@views/Landing/Export/Export';
import CreateCompany from '@views/Landing/Company/CreateCompany';
import CreateOffice from './views/Landing/Office/CreateOffice';
import UpdateCompany from '@views/Landing/Company/UpdateCompany';
import UpdateOffice from '@views/Landing/Office/UpdateOffice';
import Help from '@views/Help/Help';
import Informations from '@views/Informations/Informations';
import Users from '@views/AdminPanel/Users/Users';
import CreateUser from '@views/AdminPanel/Users/CreateUser';
import UpdateUser from '@views/AdminPanel/Users/UpdateUser';
import UsersDisabled from '@views/AdminPanel/Users/UsersDisabled';
import CompanyDisabled from '@views/AdminPanel/company/CompanyDisabled';
import Certifications from '@views/AdminPanel/Certifications/Certifications';
import NewCertification from '@views/AdminPanel/Certifications/NewCertification';
import UpdateCertification from '@views/AdminPanel/Certifications/UpdateCertification';
import Infos from '@views/Offices/Infos/Infos';
import Scheduler from '@views/Offices/Scheduler/Scheduler';
import Events from '@views/Offices/Events/Events';
import CreateEvent from '@views/Offices/Events/CreateEvent';
import UpdateEvent from './views/Offices/Events/UpdateEvent';
import Reminder from '@views/Offices/Reminder/Reminder';
import Visites from '@views/Offices/Visites/Visites';
import NewVisite from '@views/Offices/Visites/NewVisites';
import Suivie from '@views/Offices/Suivie/Suivie';
import NewSuivie from '@views/Offices/Suivie/NewSuivie';
import Fire from '@views/Offices/Fire/Fire';
import NewFire from '@views/Offices/Fire/NewFire';
import NotFound from '@views/NotFound/NotFound';
import Deposit from './views/Offices/Deposit/Deposit';
import NewDeposit from './views/Offices/Deposit/NewDeposit';
import Leases from './views/Offices/Leases/Leases';
import NewLease from './views/Offices/Leases/NewLeases';
import UpdateLease from './views/Offices/Leases/UpdateLeases';
import ExportDeposit from './views/Landing/Export/ExportDeposit';


export const Routes = [
    { path: '/auth/login', component: Login, restricted: true, exact: true, public: true },
    { path: '/legal-notice', component: LegalNotice, restricted: false, exact: true, public: true },
    { path: '/', component: Landing, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/export', component: Export, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/export/deposit', component: ExportDeposit, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/create-company', component: CreateCompany, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/companies/:id/create-office', component: CreateOffice, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/companies/:id/update-company', component: UpdateCompany, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/companies/:companyId/offices/:officeId/update-office', component: UpdateOffice, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/users', component: Users, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/users/create', component: CreateUser, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/users/update', component: UpdateUser, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/users-disabled', component: UsersDisabled, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/company-disabled', component: CompanyDisabled, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/certifications', component: Certifications, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/certifications/create', component: NewCertification, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/admin/certifications/update', component: UpdateCertification, restricted: false, exact: true, public: false, role: ['admin'] },
    { path: '/companies/:companyId/offices/:officeId/infos', component: Infos, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/scheduler', component: Scheduler, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/events', component: Events, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/events/create', component: CreateEvent, restricted: false, exact: true, public: false, role: ['user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/events/:eventId/cycles/:cycleId/update', component: UpdateEvent, restricted: false, exact: true, public: false, role: ['user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/reminders/:eventId/cycles/:cycleId', component: Reminder, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/visit-reports', component: Visites, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/visit-reports/create', component: NewVisite, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/visit-plans', component: Suivie, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/visit-plans/create', component: NewSuivie, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/fire-permits', component: Fire, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/fire-permits/create', component: NewFire, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },

    { path: '/companies/:companyId/offices/:officeId/leases', component: Leases, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/leases/create', component: NewLease, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/leases/:leaseId/update', component: UpdateLease, restricted: false, exact: true, public: false, role: ['admin'] },

    { path: '/companies/:companyId/offices/:officeId/deposits', component: Deposit, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/companies/:companyId/offices/:officeId/deposits/create', component: NewDeposit, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/help', component: Help, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/informations', component: Informations, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
    { path: '/not-found', component: NotFound, restricted: false, exact: true, public: false, role: ['viewer', 'user', 'admin'] },
];

export const AppBarItems = (role) =>
    role === 'admin'
        ? [
            { path: '/', label: 'Accueil' },
            { path: '/help', label: 'FAQ' },
            { path: '/informations', label: 'Infos' },
            { path: '/admin/users', label: 'Administration' }
        ] : [
            { label: 'Accueil', path: '/' },
            { label: 'FAQ', path: '/help' },
            { label: 'Informations', path: '/informations' }
        ];

import { userService } from '@services/user.services';
import { alertActions } from './alert.actions';

export const userActions = {
    login,
    logout,
    create,
    update,
    remove,
    getAll,
    getAllDeleted,
    changeOffices,
    contact,
    clearSuccess,
    getAllAdmins
};

function login(username, password) {
    const request = () => ({ type: 'user/loginRequest' });
    const success = (user) => ({ type: 'user/loginSuccess', user });
    const failure = (error) => ({ type: 'user/loginFailure', error });

    return dispatch => {
        dispatch(request());
        userService.login(username, password)
            .then(user => {
                if (user.soft_deleted) {
                    dispatch(failure());
                    dispatch(alertActions.error('Votre compte est désactivé, vous ne pouvez pas vous conntecter.'));
                } else {
                    dispatch(success(user));
                    dispatch(alertActions.success('Vous êtes connecté(e) !'));
                }
            })
            .catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error('Les identifiants sont incorrects.'));
            }
            );
    };
}

function logout() {
    const request = () => ({ type: 'user/logoutRequest' });
    const success = () => ({ type: 'user/logoutSuccess' });
    const failure = (error) => ({ type: 'user/logoutFailure', error });

    return dispatch => {
        dispatch(request());
        userService.logout()
            .then(() => {
                dispatch(success());
                localStorage.removeItem('user');
                dispatch(alertActions.success('Vous êtes déconnecté(e).'));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error('Une erreur empêche de vous déconnecter.'));
            });
    }
}

function create(properties) {
    const request = () => ({ type: 'user/createRequest' });
    const success = () => ({ type: 'user/createSuccess' });
    const failure = (error) => ({ type: 'user/createFailure', error });

    return dispatch => {
        dispatch(request());
        userService.create(properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("L'utilisateur à bien été créé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Un compte avec cette addresse email a déjà été crée"));
            });
    };
}

function update(id, properties) {
    const request = () => ({ type: 'user/updateRequest' });
    const success = () => ({ type: 'user/updateSuccess' });
    const failure = (error) => ({ type: 'user/updateFailure', error });

    return dispatch => {
        dispatch(request());
        userService.update(id, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("L'utilisateur a bien été modifié."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la modification de cet utilisateur."));
            });
    };
}

function remove(id) {
    const request = () => ({ type: 'user/removeRequest' });
    const success = (id) => ({ type: 'user/removeSuccess', id });
    const failure = (error) => ({ type: 'user/removeFailure', error });

    return dispatch => {
        dispatch(request());
        userService.remove(id)
            .then(() => {
                dispatch(success(id));
                dispatch(alertActions.success("L'utilisateur a bien été supprimé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la suppression de cet utilisateur."));
            });
    };
}

function getAll() {
    const request = () => ({ type: 'user/getAllRequest' });
    const success = (users) => ({ type: 'user/getAllSuccess', users });
    const failure = (error) => ({ type: 'user/getAllFailure', error });

    return dispatch => {
        dispatch(request());
        userService.getAll()
            .then(users => dispatch(success(users)))
            .catch(error => dispatch(failure(error)));
    };
}

function getAllAdmins() {
    const request = () => ({ type: 'user/getAllAdminsRequest' });
    const success = (admins) => ({ type: 'user/getAllAdminsSuccess', admins });
    const failure = (error) => ({ type: 'user/getAllAdminsFailure', error });

    return dispatch => {
        dispatch(request());
        userService.getAll('admin')
            .then(admins => dispatch(success(admins)))
            .catch(error => dispatch(failure(error)));
    };
}

function getAllDeleted() {
    const request = () => ({ type: 'user/getAllDeletedRequest' });
    const success = (users) => ({ type: 'user/getAllDeletedSuccess', users });
    const failure = (error) => ({ type: 'user/getAllDeletedFailure', error });

    return dispatch => {
        dispatch(request());
        userService.getAllDeleted()
            .then(users => dispatch(success(users)))
            .catch(error => dispatch(failure(error)));
    };
}

function changeOffices(id, properties) {
    const request = () => ({ type: 'user/changeOfficesRequest' });
    const success = () => ({ type: 'user/changeOfficesSuccess' });
    const failure = (error) => ({ type: 'user/changeOfficesFailure', error });

    return dispatch => {
        dispatch(request());
        userService.changeOffices(id, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("Les sites de l'utilisateur ont été modifiés."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Seul les admins peuvent avoir accès à plusieurs sociétés."));
            });
    };
}

function contact(properties) {
    const request = () => ({ type: 'user/contactRequest' });
    const success = () => ({ type: 'user/contactSuccess' });
    const failure = (error) => ({ type: 'user/contactFailure', error });

    return dispatch => {
        dispatch(request());
        userService.contact(properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("Le message a bien été envoyé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche l'envoie du message."));
            });
    };
}

function clearSuccess() {
    return { type: 'user/clearSuccess' };
}
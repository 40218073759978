import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card, FormControl, InputLabel, Input, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormatListBulleted, Search } from '@material-ui/icons';
import CustomTable from '@components/Table/CustomTable';
import { userActions } from '@actions/user.actions';
import AdminPanelSubAppBar from '../AdminPanel';

const useStyles = makeStyles(() => ({
    searchField: {
        width: '20%',
        margin: '30px auto',
        textAlign: 'center'
    }
}));

export default function UsersDisabled() {
    const classes = useStyles();
    const history = useHistory();

    const [newUsers, setNewUsers] = useState([]);
    const [newUsersFiltered, setNewUsersFiltered] = useState(undefined);

    const dispatch = useDispatch();
    const userReducer = useSelector(state => state.userReducer);
    const users = useSelector(state => state.userReducer.usersDisabled);

    const columns = [
        { field: 'lastname', headerName: 'Nom', style: { 'textTransform': 'uppercase' }},
        { field: 'firstname', headerName: 'Prénom', style: { 'textTransform': 'Capitalize' }},
        { field: 'email', headerName: 'E-mail', style: { 'textTransform': 'lowercase' }},
        { field: 'phone', headerName: 'Téléphone' },
        { field: 'role', headerName: 'Rôle', type: 'role'},
        { field: 'companies', headerName: 'Sociétés', type: 'list', style: { 'textTransform': 'uppercase' }},
        { field: 'offices', headerName: 'Sites', type: 'list', style: { 'textTransform': 'uppercase' }},
        { field: 'soft_deleted', headerName: 'Actif', type: 'switch' },
    ];

    const onUserList = () => {
        history.push(`/admin/users`);
    }

    const handleChangeRole = (e, user) => {
        dispatch(userActions.update(user.id, { role: e.target.value }));
    }

    const handleChangeWorking = (e, user) => {
        dispatch(userActions.update(user.id, { soft_deleted: e.target.value }));
    }

    const filterArray = (e) => {
        let input = e.target.value;
        let newUsersTmp = [];

        newUsers.forEach(user => {
            for (const [key, value] of Object.entries(user)) {
                if (['lastname', 'firstname', 'email', 'phone', 'role'].includes(key)
                && value.toLowerCase().includes(input.toLowerCase())) {
                    newUsersTmp.push(user);
                    break;
                } else if (['companies', 'offices'].includes(key) &&
                value.some(string => string.toLowerCase().includes(input.toLowerCase()))) {
                    newUsersTmp.push(user);
                    break;
                }
            }
        });
        setNewUsersFiltered(newUsersTmp);
    }

    useEffect(() => {
        dispatch(userActions.getAllDeleted());
    }, [dispatch]);

    useEffect(() => {
        if (userReducer.action === 'user/updateSuccess') {
            dispatch(userActions.getAllDeleted());
        }
    }, [dispatch, userReducer]);

    useEffect(() => {
        let newUsersTmp = [];

        users.forEach((user) => {
            user.companies = [];
            user.offices = [];
            user.__access_control_list__.forEach(list => {
                user.companies.push(list.company.name);
                user.offices.push(list.offices.map(office => office.label).join(', '));
            });
            newUsersTmp.push(user);
        });
        setNewUsers(newUsersTmp);
    }, [users]);

    return (
        <>
            <AdminPanelSubAppBar />
            <div style={{ textAlign: 'center' }}>
                <Button
                    variant='outlined'
                    startIcon={<FormatListBulleted />}
                    component='div'
                    style={{ height: 60, width: 300, marginTop: 20 }}
                    color='secondary'
                    onClick={onUserList}
                >
                    Voir la liste d'utilisateurs
                </Button>
            </div>
            <div className={classes.searchField}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">Rechercher...</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        onKeyUp={filterArray}
                        endAdornment={
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </div>
            <Card style={{ margin: 20 }}>
                <CustomTable
                    columns={columns}
                    rows={newUsersFiltered ?? newUsers}
                    title="Liste des utilisateurs inactifs"
                    handleChangeRole={handleChangeRole}
                    handleChangeWorking={handleChangeWorking}
                />
            </Card>
        </>
    );
}
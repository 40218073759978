import React from 'react';
import { Grid, makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    textField: {
        marginBottom: 100,
        fontSize: 50
    }
}));

function Entry(props) {
    const classes = useStyles();

    return (
        <Grid item xs={10}>
            <TextField
                InputProps={{style: {fontSize: 20}}}
                InputLabelProps={{style: {fontSize: 20}}}
                className={classes.textField}
                id={props.id}
                label={props.label}
                fullWidth={true}
                required
                type={props.type}
                color='secondary'
                {...props.register}
            />
        </Grid>
    );
}

export default Entry;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Button
} from '@material-ui/core';
import { userActions } from '@actions/user.actions';
import { companyActions } from '@actions/company.actions';
import isEmpty from '@helpers/isEmpty';

export default function LinkUser({ open, setOpen, user }) {
    const [newCompanies, setNewCompanies] = useState([]);
    const [newOffices, setNewOffices] = useState([]);

    const dispatch = useDispatch();
    const companies = useSelector(state => state.companyReducer.companies);
    const userReducer = useSelector(state => state.userReducer);

    const handleClose = () => {
        setNewCompanies([]);
        setNewOffices([]);
        setOpen(false);
    }

    const handleChangeCompanies = (e) => {
        const companiesToAdd = [];
        e.target.value.map((companyId) => {
            if (!companiesToAdd.includes(companyId))
                companiesToAdd.push(companyId)
        })

        if (companiesToAdd.length === 0) {
            setNewOffices([])
        }
        setNewCompanies(companiesToAdd);
    };

    const handleChangeOffices = (e) => {
        setNewOffices(e.target.value);
    }

    const getCompany = (id) => companies.find((company) => company.id === id)
    const renderCompanies = (elements) => elements.map((companyId) => getCompany(companyId)?.name).join(', ')

    const onSubmit = (e, row) => {
        const access_control_list = [];
        for (const newCompanyId of newCompanies) {
            const company = getCompany(newCompanyId)

            if (company) {
                const officesIds = company.offices.map((office) => office.id)
                const selectedOfficeIds = officesIds.filter((officeId) => newOffices.includes(officeId))
                if (selectedOfficeIds.length > 0)
                    access_control_list.push({
                        "companyId": newCompanyId,
                        "officeIds": selectedOfficeIds
                    })
            }
        }
        dispatch(userActions.changeOffices(user.id, { access_control_list }));
    }

    useEffect(() => {
        let newOfficesTmp = [];
        dispatch(companyActions.getAll());
        const companiesToAdd = [];
        user.__access_control_list__.forEach((access_control) => {
            const company = access_control.company;
            if (!isEmpty(company.offices)) {
                company.offices.forEach(office => {
                    newOfficesTmp.push(office.id);
                });
                setNewOffices(newOfficesTmp);
            }

            if (!companiesToAdd.includes(company.id))
                companiesToAdd.push(company.id)
        });
        setNewCompanies(companiesToAdd)

    }, [dispatch, user]);

    useEffect(() => {
        if (userReducer.action === 'user/changeOfficesSuccess') {
            setOpen(false);
            dispatch(userActions.clearSuccess());
            dispatch(userActions.getAll());
        }
    }, [userReducer, dispatch, setOpen]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='xs' fullWidth>
            <DialogTitle>Lier l'utilisateur à un site</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} style={{ margin: '50px 0' }}>
                    <InputLabel>Sociétés</InputLabel>
                    <Select
                        multiple
                        value={newCompanies}
                        onChange={handleChangeCompanies}
                        style={{ width: '100%' }}
                        renderValue={renderCompanies}
                    >
                        {
                            companies.map((company) => (
                                <MenuItem key={company.id} value={company.id}>
                                    <Checkbox checked={newCompanies.indexOf(company.id) > -1} />
                                    <ListItemText primary={company?.name} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>

                <FormControl fullWidth={true} style={{ margin: '50px 0' }} disabled={newCompanies === []}>
                    <InputLabel>Sites</InputLabel>
                    <Select
                        multiple
                        value={newOffices}
                        onChange={handleChangeOffices}
                        renderValue={ids => (
                            companies ?
                                ids.map(id => (
                                    companies[companies.findIndex(company => (
                                        company.offices.find(office => office.id === id))
                                    )].offices.find(office => office.id === id).label)
                                ).join(', ')
                                : ''
                        )}
                    >
                        {
                            newCompanies && newCompanies.length > 0 ?

                                newCompanies.map((newCompanyId) => {
                                    return getCompany(newCompanyId)?.offices.map((office) => {
                                        return (
                                            <MenuItem key={office.id} value={office.id}>
                                                <Checkbox checked={newOffices.indexOf(office.id) > -1} />
                                                <ListItemText primary={office.label} />
                                            </MenuItem>
                                        )
                                    })
                                })
                                : <MenuItem />

                        }
                    </Select>
                </FormControl>


            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">Annuler</Button>
                <Button onClick={onSubmit} color="secondary">Sauvegarder</Button>
            </DialogActions>
        </Dialog >
    );
}
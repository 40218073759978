import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Dialog, DialogContent } from '@material-ui/core';
import UpdateReserve from '@views/Offices/Reminder/Reserves/UpdateReserve';
import { reserveActions } from '@actions/reserve.actions';
import { eventActions } from '@actions/event.actions';
import CustomTable from '../Table/CustomTable';
import AttachFileModal from './AttachFileModal';
import { stringToLocalDate } from '@helpers/formatDate';
import isEmpty from '@helpers/isEmpty';

export default function ReserveModal({ open, setOpen, eventSelected }) {
    const history = useHistory();
    const { companyId, officeId } = useParams();
    const { eventId, cycleId } = eventSelected

    const [reserves, setReserves] = useState([]);
    const [openFile, setOpenFile] = useState(false);
    const [update, setUpdate] = useState(false);
    const [reserveSelected, setReserveSelected] = useState({});

    const dispatch = useDispatch();
    const reserveReducer = useSelector(state => state.reserveReducer);
    const eventReducer = useSelector(state => state.eventReducer);
    const office = useSelector(state => state.officeReducer.office);
    const user = useSelector(state => state.userReducer.userInfos);

    const handleClose = () => setOpen(false);

    const onClickFile = (event) => {
        setReserveSelected({ event: event, cycle: event.cycles[0] });
        setOpenFile(true);
    }

    const columns = [
        { field: 'label', headerName: 'Réserve' },
        { field: 'description', headerName: 'Description' },
        { field: 'stringDate', headerName: 'Date de réalisation prévue' },
        { field: 'office', headerName: 'Site', style: { 'textTransform': 'uppercase' } },
        { field: 'priority', headerName: 'Priorité', type: 'priority' },
        { field: 'status', headerName: 'Statuts', type: 'status', disabled: user },
        { field: 'proofs', headerName: 'Fichier', type: 'proofs' }
    ];

    const handleChange = (e, row) => {
        row.status = e.target.value;
        dispatch(eventActions.update(row.id, row.cycles[0].id, row));
    };

    const onVisualize = (row) => {
        history.push({
            pathname: `/companies/${companyId}/offices/${officeId}/reminders/${row.id}/cycles/${row.cycles[0].id}`,
            state: { event: row }
        });
    }

    const onUpdate = (row) => {
        setUpdate(true);
        setReserveSelected(row);
    }

    useEffect(() => {
        if (open || (eventReducer.action === 'event/updateSuccess')) {
            dispatch(reserveActions.clearSuccess());
            dispatch(reserveActions.getAll(eventId, cycleId));
        }
    }, [eventReducer, open, dispatch, cycleId, eventId]);

    useEffect(() => {
        let reservesTmp = [];

        if (open && !isEmpty(reserveReducer.reserves)) {
            reserveReducer.reserves.forEach(reserve => {
                reserve.stringDate = stringToLocalDate(reserve.start_date)
                reserve.status = reserve.cycles[0].status;
                reserve.office = office.label;
                reserve.proofs = reserve.cycles[0].proofs;
                reserve.author = eventSelected.event.author;
                reservesTmp.push(reserve);
            });
            setReserves(reservesTmp);
        }
    }, [reserveReducer, open, office, eventSelected]);

    return (
        <div>
            <Dialog open={open} onClose={handleClose} maxWidth='xl' fullWidth>
                <DialogContent>
                    {
                        update
                            ? <UpdateReserve setUpdate={setUpdate} reserve={reserveSelected} eventIdSelected={eventId} cycleIdSelected={cycleId} />
                            : <CustomTable
                                columns={columns}
                                rows={reserves}
                                title="Liste des réserves / points de non-conformité"
                                onVisualize={onVisualize}
                                onUpdate={onUpdate}
                                handleChangeStatus={handleChange}
                                setOpen={onClickFile}
                            />
                    }
                </DialogContent>
            </Dialog>
            <AttachFileModal
                open={openFile}
                setOpen={setOpenFile}
                event={reserveSelected.event}
                cycle={reserveSelected.cycle}
            />
        </div>
    );
}
import { companyService } from '@services/company.services';
import { alertActions } from './alert.actions';

export const companyActions = {
    create,
    update,
    get,
    getAll,
    getAllDeleted,
    remove,
    uploadLogo,
    deleteLogo,
    getLogo,
    getCapitals,
    clearSuccess,
};

function create(properties) {
    const request = () => ({ type: 'company/createRequest' });
    const success = (company) => ({ type: 'company/createSuccess', company });
    const failure = (error) => ({ type: 'company/createFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.create(properties)
            .then(company => {
                dispatch(success(company));
                dispatch(alertActions.success(`La société '${company.name}' a bien été créé.`));
            }).catch(error => {
                let messageError = 'Une erreur empêche la création de la société.';
                let messageResponse = error.response.data.message;

                dispatch(failure(error));
                if (Array.isArray(messageResponse)) {
                    messageResponse.some(message => {
                        if (message.includes('email')) {
                            messageError = 'L\'e-mail de la personne de contact n\'est pas valide.';
                            return true;
                        } else
                            return false;
                    });
                } else if (messageResponse.includes('email'))
                    messageError = 'L\'e-mail de la personne de contact n\'est pas valide.';
                dispatch(alertActions.error(messageError));
            });
    };
}

function update(id, properties) {
    const request = () => ({ type: 'company/updateRequest' });
    const success = (company) => ({ type: 'company/updateSuccess', company });
    const failure = (error) => ({ type: 'company/updateFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.update(id, properties)
            .then(company => {
                dispatch(success(company));
                dispatch(alertActions.success(`La société '${company.name}' a bien été modifié.`));
            }).catch(error => {
                let messageError = 'Une erreur empêche la modification de cette société.';
                let messageResponse = error.response.data.message;

                dispatch(failure(error));
                if (Array.isArray(messageResponse)) {
                    messageResponse.some(message => {
                        if (message.includes('email')) {
                            messageError = 'L\'e-mail de la personne de contact n\'est pas valide.';
                            return true;
                        } else
                            return false;
                    });
                } else if (messageResponse.includes('email'))
                    messageError = 'L\'e-mail de la personne de contact n\'est pas valide.';
                dispatch(alertActions.error(messageError));
            });
    };
}

function get(id) {
    const request = () => ({ type: 'company/getRequest' });
    const success = (company) => ({ type: 'company/getSuccess', company });
    const failure = (error) => ({ type: 'company/getFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.get(id)
            .then(company => dispatch(success(company)))
            .catch(error => dispatch(failure(error)));
    };
}

function getAll() {
    const request = () => ({ type: 'company/getAllRequest' });
    const success = (companies) => ({ type: 'company/getAllSuccess', companies });
    const failure = (error) => ({ type: 'company/getAllFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.getAll()
            .then(
                companies => dispatch(success(companies)),
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error("Une erreur empêche la récupération des sociétés."));
                }
            );
    };
}

function getAllDeleted() {
    const request = () => ({ type: 'company/getAllDeletedRequest' });
    const success = (company) => ({ type: 'company/getAllDeletedSuccess', company });
    const failure = (error) => ({ type: 'company/getAllDeletedFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.getAllDeleted()
            .then(company => dispatch(success(company)))
            .catch(error => dispatch(failure(error)));
    };
}

function remove(id) {
    const request = () => ({ type: 'company/removeRequest' });
    const success = (id) => ({ type: 'company/removeSuccess', id });
    const failure = (error) => ({ type: 'company/removeFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.remove(id)
            .then(() => {
                dispatch(success(id));
                dispatch(alertActions.success("La société a bien été édité."));
            }).catch(error => {
            dispatch(failure(error));
            dispatch(alertActions.error("Une erreur empêche d'éditer cette société."));
        });
    };
}

function uploadLogo(id, properties) {
    const request = () => ({ type: 'company/uploadLogoRequest' });
    const success = () => ({ type: 'company/uploadLogoSuccess' });
    const failure = (error) => ({ type: 'company/uploadLogoFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.uploadLogo(id, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success('Le logo a bien été mis en ligne.'));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la mise en ligne du logo."));
            });
    };
}

function deleteLogo(id) {
    const request = () => ({ type: 'company/removeLogoRequest' });
    const success = () => ({ type: 'company/removeLogoSuccess' });
    const failure = (error) => ({ type: 'company/removeLogoFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.deleteLogo(id)
            .then(() => {
                dispatch(success())
                dispatch(alertActions.success("Le logo a bien été supprimé."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la supression du logo."));
            });
    };
}

function getLogo(id) {
    const request = () => ({ type: 'company/getLogoRequest' });
    const success = (logo) => ({ type: 'company/getLogoSuccess', logo });
    const failure = (error) => ({ type: 'company/getLogoFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.getLogo(id)
            .then(logo => dispatch(success(logo)))
            .catch(error => dispatch(failure(error)));
    };
}

function getCapitals(id) {
    const request = () => ({ type: 'company/getCapitalsRequest' });
    const success = (capitals) => ({ type: 'company/getCapitalsSuccess', capitals });
    const failure = (error) => ({ type: 'company/getCapitalsFailure', error });

    return dispatch => {
        dispatch(request());
        companyService.getCapitals(id)
            .then(capitals => dispatch(success(capitals)))
            .catch(error => dispatch(failure(error)));
    };
}

function clearSuccess() {
    return { type: 'company/clearSuccess' };
}
import { createTheme } from '@material-ui/core/styles';

export const AppTheme = createTheme({
  palette: {
      type: 'light',
      primary: {
         main: '#37373a',
      },
      secondary: {
         main: '#0288d1',
      },
  },
  typography: {
      h3: {
         fontSize: '1.2rem',
         '@media (min-width:600px)': {
            fontSize: '1.5rem',
         },
      }
   }
});
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@material-ui/core';

export const DeleteButton = ({ row, onDelete }) => {
    if (!onDelete)
      return <></>;

    return (
        <IconButton component="span" onClick={() => {onDelete(row)}}>
            <DeleteForeverIcon />
        </IconButton>
    );
};
import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import CustomTable from '@components/Table/CustomTable';
import { officeActions } from '@actions/office.actions';
import { stringToLocalDate } from '@helpers/formatDate';
import Header from '../Header/Header';
import OfficeSubAppBar from '../Office';

const columns = [
    { field: 'originalname', headerName: 'Nom du fichier'},
    { field: 'file', headerName: 'Fichier', type: 'officeFile'},
    { field: 'created_at', headerName: 'Date de mise en ligne'},
    { field: 'fileDate', headerName: 'Date du rapport'}
];

export default function Visites() {
    const history = useHistory();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();
    const fileInfos = useSelector(state => state.officeReducer.file);
    const officeReducer = useSelector(state => state.officeReducer);
    const visit_reports = useSelector(state => state.officeReducer.office.visit_reports);
    const user = useSelector(state => state.userReducer.userInfos);

    const [fileName, setFileName] = useState(undefined);
    const [files, setFiles] = useState([]);

    const onCreate = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/visit-reports/create`);
    }

    const onDelete = (file) => {
        let newFileArray = [...files];

        setFiles(newFileArray.filter(everyFile => everyFile.id !== file.id));
        dispatch(officeActions.deleteDocument(companyId, officeId, file.id, 'visit_reports', ));
    }

    const handleDownload = (file) => {
        dispatch(officeActions.getDocument(companyId, officeId, file.id, 'visit_reports'));
        setFileName(file.originalname);
    };

    useEffect(() => {
        dispatch(officeActions.get(companyId, officeId));
    }, [dispatch, companyId, officeId]);

    useEffect(() => {
        let filesTmp = [];

        visit_reports.forEach(file => {
            let limiteDate = new Date(file.created_at);

            file.created_at = stringToLocalDate(file.created_at);
            file.fileDate = stringToLocalDate(file.comment);
            limiteDate.setDate(limiteDate.getDate() + 1);
            if (user.role === 'admin')
                file.canDelete = true;
            else
                file.canDelete = limiteDate > new Date();
            filesTmp.push(file);
        });
        setFiles(filesTmp);
    }, [visit_reports, user]);

    useEffect(() => {
        if (officeReducer.action === 'office/getDocumentSuccess'
        && fileName !== undefined) {
            fileDownload(fileInfos, fileName);
            setFileName(undefined)
            dispatch(officeActions.clearSuccess());
        }
    }, [officeReducer, dispatch, fileInfos, fileName]);

    return (
        <div height="800">
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <CustomTable
                columns={columns}
                rows={files}
                title="Liste des rapports de visite"
                onCreate={user.role !== 'viewer' ? onCreate : null}
                createLabel="Ajouter un rapport de visite"
                onDelete={user.role !== 'viewer' ? onDelete : null}
                handleDownload={handleDownload}
            />
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { Card, CardContent, CardHeader, Divider, makeStyles, IconButton } from '@material-ui/core';
import { Delete, CloudDownload } from '@material-ui/icons';
import { eventActions } from '@actions/event.actions';
import { stringToLocalDate } from '@helpers/formatDate';

const useStyles = makeStyles(() => ({
    fileCard: {
        width: '100vw',
        margin: 20,
        position: 'relative'
    },
    cardContent: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column'
    },
    date: {
        fontWeight: 400,
        color: 'gray',
        fontSize: 20
    },
    download: {
        color: 'lightblue',
        fontWeight: 700,
        fontSize: 40,
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 20
    }
}));

export default function FeedBack({ event, eventId, cycleId }) {
    const classes = useStyles();

    const [canDelete, setCanDelete] = useState(false);

    const dispatch = useDispatch();
    const file = useSelector(state => state.eventReducer.file);
    const user = useSelector(state => state.userReducer.userInfos);

    const downloadFile = (index) => {
        fileDownload(file, event.proofs[index].originalname);
    };

    const onDelete = (index) => {
        dispatch(eventActions.deleteProof(eventId, cycleId, event.proofs[index].id));
    }

    useEffect(() => {
        let canDeleteTmp = [];

        event.proofs.forEach(proof => {
            let limiteDate = new Date(proof.created_at);

            limiteDate.setDate(limiteDate.getDate() + 1);
            if (user.role === 'admin')
                canDeleteTmp.push(true);
            else
                canDeleteTmp.push(limiteDate > new Date());
        });
        setCanDelete(canDeleteTmp);
    }, [event, user]);

    return (
        <Card className={classes.fileCard}>
            <CardHeader title='Pièce(s) justificative(s)' />
            <CardContent className={classes.cardContent}>
                <Divider variant='fullWidth' />
                {
                    event.proofs.map((proof, index) => {
                        return <div key={index}>
                            <h1>{proof.originalname}</h1>
                            <h2 className={classes.date}>{stringToLocalDate(proof.created_at)}</h2>
                            <div>
                                <IconButton size='medium' className={classes.download} onClick={() => downloadFile(index)}>
                                    <CloudDownload fontSize='inherit' />
                                </IconButton>
                                {
                                    canDelete[index] && user.role !== 'viewer'
                                    ? <IconButton aria-label='delete' size='medium' onClick={() => onDelete(index)}>
                                        <Delete fontSize='inherit' />
                                    </IconButton>
                                    : <></>
                                }
                            </div>
                        </div>
                    })
                }
            </CardContent>
        </Card>
    );
}
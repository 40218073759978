import { useSelector } from 'react-redux';
import { Button, FormControl, IconButton, InputLabel, MenuItem, Select, Switch, Typography } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { AttachFile, Brightness1, CloudDownload, CloudUpload } from '@material-ui/icons';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ExploreIcon from '@mui/icons-material/Explore';
import ApartmentIcon from '@mui/icons-material/Apartment';
import isEmpty from '@helpers/isEmpty';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

export default function CustomTableCell({ column, row, onClickIcon, onReserve, noPermit, noPlans, noReports,
    handleUpload, handleDownload, handleChangeRole, handleChangeStatus, handleChangeWorking, setOpen }) {
    const eventColors = ['#cc0000', '#FF7900', '#4BB543'];
    let content = undefined;
    const ownUser = useSelector(state => state.userReducer.userInfos);
    const types = (type) => {
        switch (type) {
            case 'switch':
                return (
                    <Switch
                        checked={!row.soft_deleted}
                        onChange={(e) => handleChangeWorking(e, row)}
                        disabled={ownUser.id === row.id}
                    />
                );
            case 'status':
                return (
                    (row.reserveCount == 0) ?
                        <FormControl variant="outlined">
                            <InputLabel>Statut</InputLabel>
                            <Select
                                value={row.status}
                                onChange={(e) => handleChangeStatus(e, row)}
                                label='Statut'
                                disabled={(column.disabled.role === 'user' && row.author && row.author.id !== column.disabled.id) || column.disabled.role === 'viewer'}
                            >
                                <MenuItem value={0}>En cours</MenuItem>
                                <MenuItem value={1}>Terminé</MenuItem>
                                <MenuItem value={2}>Sans suite</MenuItem>
                            </Select>
                        </FormControl>
                        :
                        <FormControl variant="outlined">
                            <InputLabel>Statut</InputLabel>
                            <Select
                                value={row.status}
                                onChange={(e) => handleChangeStatus(e, row)}
                                label='Statut'
                                disabled={(column.disabled.role === 'user' && row.author && row.author.id !== column.disabled.id) || column.disabled.role === 'viewer'}
                            >
                                <MenuItem value={0}>En cours</MenuItem>
                                {(row.reserveCount === 0 || row.status === 1) && <MenuItem value={1}>Terminé</MenuItem>}
                                <MenuItem value={2}>Sans suite</MenuItem>
                            </Select>
                        </FormControl>

                );
            case 'list':
                return <ol>{row[`${column.field}`].map((field, index) => <li key={index}>{field}</li>)}</ol>
            case 'role':
                return (
                    <FormControl variant="outlined">
                        <InputLabel>Role</InputLabel>
                        <Select
                            value={row.role}
                            onChange={(e) => handleChangeRole(e, row)}
                            label='Role'
                            disabled={ownUser.id === row.id}
                        >
                            <MenuItem value='admin'>Admin IBS</MenuItem>
                            <MenuItem value='user'>Utilisateur</MenuItem>
                            <MenuItem value='viewer'>Consultation</MenuItem>
                        </Select>
                    </FormControl>
                );
            case 'bill':
                return (
                    row.file
                        ? <div>
                            <Typography>{row.file.originalname}</Typography>
                            <IconButton size='medium' onClick={(e) => handleDownload(row.id)}>
                                <CloudDownload fontSize='inherit'/>
                            </IconButton>
                        </div>
                        : <label htmlFor='file-bill'>
                            <input
                                style={{display: 'none'}}
                                id='file-bill'
                                multiple
                                type='file'
                                onChange={(e) => handleUpload(row.id)}
                            />
                            <IconButton
                                variant='outlined'
                                color='secondary'
                                component='span'
                                type='file'
                            >
                                <CloudUpload/>
                            </IconButton>
                        </label>
                );
            case 'priority':
                return <Brightness1 style={{color: eventColors[row.priority - 1]}}/>
            case 'proofs':
                return (
                    <div>
                        {
                            !isEmpty(row[`${column.field}`])
                                ? <IconButton onClick={() => setOpen(row)}>
                                    <AttachFile/>
                                </IconButton>
                                : <></>
                        }
                    </div>
                );
            case 'officeFile':
                return (
                    <div>
                        <Button
                            variant='outlined'
                            component='span'
                            color='secondary'
                            onClick={(e) => handleDownload(row)}
                        >
                            Télécharger
                        </Button>
                    </div>
                );
            case 'siteIcons':
                return (
                    <div>
                        <IconButton
                            onClick={(e) => onClickIcon(e, row, 'visit-report')}
                            disabled={noReports}
                        >
                            <ApartmentIcon style={noReports ? {} : {color: '#86c5da'}}/>
                        </IconButton>
                        <IconButton
                            onClick={(e) => onClickIcon(e, row, 'visit-plans')}
                            disabled={noPlans}
                        >
                            <ExploreIcon style={noPlans ? {} : {color: '#f2a900'}}/>
                        </IconButton>
                        <IconButton
                            onClick={(e) => onClickIcon(e, row, 'fire-permit')}
                            disabled={noPermit}
                        >
                            <LocalFireDepartmentIcon style={noPermit ? {} : {color: '#e25822'}}/>
                        </IconButton>
                    </div>
                );
            case 'reserve':
                return (
                    // row[`${column.field}`] !== 0 ?
                    <IconButton style={{color: '#1D71B8'}} component="span" onClick={(e) => onReserve(row)}>
                        <Typography variant='button' style={{fontSize: 20}}>{row[`${column.field}`]}</Typography>
                    </IconButton>
                    // : <></>
                );
            case 'date': {
                return (
                    <Typography align='center'>
                        {
                            row[`${column.field}`]
                                ? new Date(row[`${column.field}`]).toLocaleDateString('fr-Fr', {
                                    weekday: 'short',
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric'
                                })
                                : null
                        }
                    </Typography>
                );
            }

            case 'fréquence_before':
                if (row[`${column.field}`] !== null)
                    if (row.frequency_before_event !== 'Aucune') {
                        return (
                            <Typography align='center'>
                                {row.frequency_before_event} jours avant
                            </Typography>
                        );
                    } else {
                        return (
                            <Typography align='center'>
                                Aucune
                            </Typography>
                        );
                    }
                else {
                    return <></>
                }


            case 'fréquence_after':
                if (row[`${column.field}`] !== null)
                    if (row.frequency_after_event === 60) {
                        return (
                            <Typography align='center'>
                                Semestriel
                            </Typography>
                        );
                    } else if (row.frequency_after_event === 30) {
                        return (
                            <Typography align='center'>
                                Mensuel
                            </Typography>
                        );
                    } else if (row.frequency_after_event === 7) {
                        return (
                            <Typography align='center'>
                                Hebdomadaire
                            </Typography>
                        );
                    } else if (row.frequency_after_event === 1) {
                        return (
                            <Typography align='center'>
                                Quotidienne
                            </Typography>
                        );
                    } else {
                        return (
                            <Typography align='center'>
                                Aucune
                            </Typography>
                        );
                    }
                break;

            case 'euro':
                if (row[`${column.field}`] !== null)
                    if (row.type ==='Renonciation à recours') {
                        return (
                            <Typography align='center'>
                                {row.billing} €
                            </Typography>
                        );
                    } else {
                        return (
                            <Typography align='center'>
                                {row.billing} €
                            </Typography>
                        );
                    }
                else {
                    return <></>
                }

            case 'euros':
                if (row[`${column.field}`] !== null){
                    if (row.type === 'Renonciation à recours' && row.calculation > 0) {
                        return (
                            <Typography align='center'>
                                {row.calculation} €
                            </Typography>
                        );
                    }
        }else{
            return <></>
                }
                break;


            case 'waiverFile':
                if (row.type !== 'Renonciation à recours')
                    return (
                        <></>
                    )

                return (
                    <div>
                        {
                            row.waiver ?
                                <Button
                                    variant='outlined'
                                    component='span'
                                    color='secondary'
                                    startIcon={<DownloadIcon />}
                                    onClick={(e) => handleDownload(row)}
                                >
                                    Télécharger
                                </Button>
                                :

                                <label htmlFor={`file-bill-${row.id}`}>
                                    <input
                                        style={{ display: 'none' }}
                                        id={`file-bill-${row.id}`}
                                        multiple
                                        type='file'
                                        onChange={(e) => handleUpload(row, e)}
                                    />
                                    <Button
                                        type='file'
                                        variant='outlined'
                                        component='span'
                                        color='secondary'
                                        startIcon={<UploadIcon />}
                                    >
                                        Mettre en ligne
                                    </Button>
                                </label>
                        }
                    </div >
                );
            default:
                break;
        }
    };

    if (types(column.type) !== undefined) {
        content = types(column.type);
    } else {
        let properties = column.field.split('.');

        content = row;
        properties.forEach(property => {
            content = content[property];
        });
    }

    return (
        <TableCell component="th" scope="row" align='center' style={column.style}>
            {content}
        </TableCell>
    );
}
import React, { useState } from 'react';
import { InputAdornment, NativeSelect } from '@mui/material';
import { Grid, Divider, TextField, Typography, FormControl, InputLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=" "
            isNumericString
            prefix=""
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function DepositForm({ register, deposit, rate }) {

    const officeReducer = useSelector(state => state.officeReducer);
    const [filingCompany, setFillingCompany] = useState(deposit.filingCompany)
    const [merchandiseType, setMerchandiseType] = useState(deposit.merchandiseType)
    const [startingDate, setStartingDate] = useState(deposit?.startingDate?.toISOString()?.split('T')[0])
    const [endingDate, setEndingDate] = useState(deposit?.endingDate?.toISOString()?.split('T')[0])
    const [billing, setBilling] = useState(`${deposit.billing}`)


    const [previewBilling, setPreviewBilling] = useState(deposit.previewBilling ?? 0)
    const [previewMensualBilling, setPreviewMensualBilling] = useState((deposit.previewMensualBilling / 12) ?? 0)
    const [type, setType] = useState(deposit.type === "" ? "unknown" : deposit.type)

    const calcDaysBeetweenDates = (date1, date2) => {
        // To calculate the time difference of two dates
        const timeDiff = date2.getTime() - date1.getTime();
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return daysDiff;
    }

    const calcPreview = (billingValue, startingDate, endingDate) => {
        const days = calcDaysBeetweenDates(startingDate, endingDate);
        // MONTANT * (5/365 000) * période en jours
        const rate = officeReducer.office.rate ?? 5;

        let copy = billingValue.replace(/\s/g, '');
        const preview = (parseInt(copy) * (rate / 364000) * days).toFixed(2);
        return preview;
    }

    const calcMensualPreview = (billingValue, startingDate, endingDate) => {
        let days = calcDaysBeetweenDates(startingDate, endingDate);
        days = days > 364 ? 364 : days < 364 ? days : days;
            // MONTANT * (5/365 000) * période en jours
            const rate = officeReducer.office.rate ?? 5;

            let copy = billingValue.replace(/\s/g, '');
            const preview = (parseInt(copy) * (rate / 364000) * days).toFixed(2);
            return preview;
    }

    const onBillingChange = (e) => {
        let billingNewValue = e.target.value;
        setBilling(billingNewValue)
        if (startingDate && endingDate) {
            const date1 = new Date(startingDate)
            const date2 = new Date(endingDate)
            const preview = calcPreview(billingNewValue, date1, date2)
            setPreviewBilling(preview && preview > 0 ? preview : 0);
            const previewMensual = calcMensualPreview(billingNewValue, date1, date2)
            setPreviewMensualBilling(previewMensual && previewMensual > 0 ? previewMensual / 12 : 0);
        }
    }

    const onStartingDateChange = (e) => {
        let newStartingDate = e.target.value;
        setStartingDate(newStartingDate)

        if (billing && startingDate && endingDate) {
            const date1 = new Date(newStartingDate)
            const date2 = new Date(endingDate)
            const preview = calcPreview(billing, date1, date2)
            setPreviewBilling(preview && preview > 0 ? preview : 0);
            const previewMensual = calcMensualPreview(billing, date1, date2)
            setPreviewMensualBilling(previewMensual && previewMensual > 0 ? previewMensual / 12 : 0);
        }
    }

    const onEndingDateChange = (e) => {
        let newEndingDate = e.target.value;
        setEndingDate(newEndingDate)
        if (billing && startingDate && endingDate) {
            const date1 = new Date(startingDate)
            const date2 = new Date(newEndingDate)
            const preview = calcPreview(billing, date1, date2)
            setPreviewBilling(preview && preview > 0 ? preview : 0);
            const previewMensual = calcMensualPreview(billing, date1, date2)
            setPreviewMensualBilling(previewMensual && previewMensual > 0 ? previewMensual / 12 : 0);
        }
    }

    return <>
        <Grid container spacing={4}>

            <Grid item xs={12}>
                <Grid container justifyContent="start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" id="tableTitle" component="div">
                            {"Déposant"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={2}>
                <Grid item xs={12}>
                    <TextField color="secondary" {...register("filingCompany")} variant="outlined" label={"Entreprise déposante"} type="string" fullWidth={true} InputLabelProps={{ shrink: true }} onChange={(e) => { setFillingCompany(e.target.value) }} defaultValue={filingCompany} required />
                </Grid>

                <Grid item xs={6}>
                    <TextField color="secondary" {...register("applicant.firstname")} variant="outlined" label={"Prénom"} type="string" fullWidth={true} defaultValue={deposit?.applicant?.firstname} />
                </Grid>
                <Grid item xs={6}>
                    <TextField color="secondary" {...register("applicant.lastname")} variant="outlined" label={"Nom"} type="string" fullWidth={true} defaultValue={deposit?.applicant?.lastname} />
                </Grid>
                <Grid item xs={6}>
                    <TextField color="secondary" {...register("applicant.email")} variant="outlined" label={"Email"} type="string" fullWidth={true} defaultValue={deposit?.applicant?.email} />
                </Grid>
                <Grid item xs={6}>
                    <TextField color="secondary" {...register("applicant.phone")} variant="outlined" label={"Téléphone"} type="string" fullWidth={true} defaultValue={deposit?.applicant?.phone} />
                </Grid>
                <Grid item xs={12}>
                    <TextField color="secondary" {...register("applicant.job")} variant="outlined" label={"Role"} type="string" fullWidth={true} defaultValue={deposit?.applicant?.job} />
                </Grid>
            </Grid>


            {
                deposit.type !== null ?
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native" defaultValue={type}>
                            </InputLabel>
                            <NativeSelect
                                // defaultValue={30}
                                inputProps={{
                                    name: 'type',
                                    id: 'uncontrolled-native',
                                }}
                                {...register("type")}
                                value={type}
                                onChange={(e) => { setType(e.target.value) }}
                            >
                                <option value={"waiver"}>Renonciation à recours</option>
                                <option value={"accounting"}>Comptabilité</option>
                                <option value={"unknown"}>Ne sais pas</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid > : <></>
            }



            <Grid item xs={12}>
                <Grid container justifyContent="start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" id="tableTitle" component="div">
                            {"Période"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction={"row"} spacing={2}>
                <Grid item xs={6}>
                    <TextField color="secondary" {...register("startingDate")} variant="outlined" id="date" label={"Date de début"} type="date" fullWidth={true} InputLabelProps={{ shrink: true }} onChange={onStartingDateChange} defaultValue={startingDate} required />
                </Grid>
                <Grid item xs={6}>
                    <TextField color="secondary" {...register("endingDate")} variant="outlined" id="date" label={"Date de fin"} type="date" fullWidth={true} InputLabelProps={{ shrink: true }} onChange={onEndingDateChange} defaultValue={endingDate} required />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent="start" spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" id="tableTitle" component="div">
                            {"Marchandises stockées"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={6}>
                <TextField color="secondary" {...register("merchandiseType")} variant="outlined" label={"Type de marchandise"} type="string" fullWidth={true} InputLabelProps={{ shrink: true }} onChange={(e) => { setMerchandiseType(e.target.value) }} defaultValue={merchandiseType} required />
            </Grid>

            <Grid item xs={6}>
                <TextField color="secondary" {...register("quantity")} variant="outlined" label={"Quantité"} type="string" fullWidth={true} defaultValue={deposit.quantity} />
            </Grid>

            <Grid container direction={"row"} spacing={2}>
                <Grid item xs={4}>
                    <TextField color="secondary" {...register("billing")} variant="outlined" label={"Montant des capitaux marchandises"}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            inputComponent: NumberFormatCustom,
                        }}
                        type="string" fullWidth={true} InputLabelProps={{ shrink: true, pattern: "[0-9]*" }} onChange={onBillingChange} defaultValue={billing}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField color="secondary" {...register("previewBilling")} variant="outlined" label={"Prévisualisation du cout assurantiel"} type="string" fullWidth={true} disabled InputLabelProps={{ shrink: true }} value={previewBilling}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField color="secondary" {...register("previewMensualBilling")} variant="outlined" label={"Prévisualisation du cout assurantiel mensuel"} type="string" fullWidth={true} disabled InputLabelProps={{ shrink: true }} value={(previewMensualBilling).toFixed(2)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            inputComponent: NumberFormatCustom,
                        }}
                    />
                </Grid>
            </Grid>

        </Grid >
    </>
}
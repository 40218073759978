import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { userActions } from '@actions/user.actions';
import LogoIbs from '@assets/Img/logo-ibs.png';
import FAQ from '@assets/FAQ.pdf';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    deconnexion: {
        position: 'absolute',
        top: '50%',
        color: '#F18844',
        transform: 'translateY(-50%)',
    },
    tab: {
        color: 'red',
        background: 'none'
    }
}));

export default function CustomAppBar({ navigations }) {
    const classes = useStyles();

    const [role, setRole] = useState('');
    const [value, setValue] = useState(0);

    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.userInfos);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const onLogout = () => {
        dispatch(userActions.logout())
    }

    useEffect(() => {
        let url = window.location.pathname;
        let index;

        url = url.split('/');
        if (url.includes('admin'))
            index = 3;
        else
            index = navigations(user.role).findIndex(item => item.path === window.location.pathname);
        setRole(user.role);
        setValue(index === -1 ? 0 : index);
    }, [navigations, user]);


    const downloadFaq = () => {
        let link = document.createElement('a');
        link.setAttribute('href', FAQ);
        link.setAttribute('download', 'Présentation Extranet V2.pdf');
        document.body.appendChild(link);
        link.click();
    }

    const getNavItem = (item, index) => {

        if (item.label === "FAQ") {
            return (
                <Tab
                    key={index}
                    selected={false}
                    onClick={downloadFaq}
                    value={index}
                    style={{ color: '#FFFFFF', textDecoration: 'none' }}
                    label={item.label}
                />
            )
        }

        return (
            <Tab
                key={index}
                selected={false}
                component={Link}
                value={index}
                to={{ pathname: item.path }}
                style={{ color: '#FFFFFF', textDecoration: 'none' }}
                label={item.label}
            />
        );
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed">
                <Toolbar style={{ height: 90, background: '#18284D' }}>
                    <Grid container direction="row" spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item xs={2}>
                            <Grid container alignContent="flex-start" justifyContent="center">
                                <img src={LogoIbs} alt='logo ibs' style={{ margin: 10, height: 70, marginLeft: 50 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container justifyContent="center">
                                <Tabs indicatorColor='secondary' value={value} onChange={handleChange} centered>
                                    {
                                        navigations(role).map((navigation, index) => {
                                            return (getNavItem(navigation, index));
                                        })
                                    }
                                </Tabs>
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container alignContent="flex-end" justifyContent="center">
                                <Button variant="outlined" className={classes.deconnexion} onClick={onLogout}>
                                    Déconnexion
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Toolbar style={{ height: 90 }} />
        </div>
    );
}
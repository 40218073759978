import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    TextField, FormControl, InputLabel, Select, Grid, Button, ListItemText, Checkbox, Box, Chip, MenuItem, FormControlLabel, RadioGroup, Radio
} from '@material-ui/core';

import { exportActions } from '@actions/export.actions';

import formatDate, { stringToLocalDate } from '@helpers/formatDate';

export default function ExportDeposit() {
    const history = useHistory();
    const dispatch = useDispatch();
    const exportReducer = useSelector(state => state.exportReducer);
    const deposits = useSelector(state => state.exportReducer.deposits);
    const user = useSelector(state => state.userReducer.userInfos);

    const [selectAllDates, setSelectAllDates] = useState(true);
    const [officesId, setOfficesId] = useState([]);

    const handleSelectAllDates = (e) => {
        setSelectAllDates(e.target.value === 'true');
    }

    const handleChange = (e) => {
        setOfficesId(e.target.value);
    }

    const handleExport = () => {
        const startDate = new Date(document.getElementById('startDate').value);
        const endDate = new Date(document.getElementById('endDate').value);
        const companiesId = officesId.flatMap(id => {
            return user.__access_control_list__.find(access => {
                return access.company.offices.some(office => (office.id === id));
            }).company.id;
        }).filter((item, pos, self) => self.indexOf(item) === pos);

        const properties = {
            companyIds: companiesId,
            officeIds: officesId,
            period: {
                start: isNaN(startDate.getTime()) || selectAllDates ? undefined : startDate,
                end: isNaN(endDate.getTime()) || selectAllDates ? undefined : endDate
            },
        }
        dispatch(exportActions.getExportedDeposits(properties));
    }

    useEffect(() => {
        let officesIdTmp = [];

        user.__access_control_list__.forEach(access => {
            access.offices.forEach(office => {
                officesIdTmp.push(office.id);
            });
        });
        setOfficesId(officesIdTmp);
    }, [user]);

    useEffect(() => {
        if (exportReducer.action === 'export/getDepositsSuccess') {
            const types = { 'waiver': "Renonciation", 'unknown': "Ne sais pas", "accounting": "Comptabilité" }
            const headers = ['Entreprise déposante', 'Type de marchandise', 'Action effectuée', 'Montant', 'Date de début', 'Date de fin', 'Preuve']
            const rows = [
                ...deposits.map(deposit => [
                    deposit.filingCompany,
                    deposit.merchandiseType,
                    types[deposit.type] ?? "Non définis",
                    deposit.billing,
                    stringToLocalDate(deposit.startingDate),
                    stringToLocalDate(deposit.endingDate),
                    deposit.waiver ? "oui" : "non"
                ])
            ]

            const csvContent = 'data:text/csv;sep=;;charset=UTF-8,'
                + headers.join(';') + '\n'
                + rows.map(event => event.join(';')).join('\n');
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', `Export_marchandises_${formatDate(new Date())}.csv`);
            document.body.appendChild(link);
            link.click();
        }
    }, [exportReducer, deposits]);

    return (
        <div>
            <Grid item xs={12} style={{ margin: 20 }}>
                <Button color='secondary' variant='outlined' onClick={() => history.goBack()}>
                    Retour
                </Button>
            </Grid>
            <div style={{ textAlign: 'center' }}>
                <h1 style={{ textAlign: 'center', margin: 50 }}>Sélectionner les paramètres de l'export</h1>
                <div>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            value={selectAllDates}
                            onChange={handleSelectAllDates}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Récupérer toutes les marchandises" />
                            <FormControlLabel value={false} control={<Radio />} label="Sélectionner des dates" />
                        </RadioGroup>
                    </FormControl>
                    <div style={{ display: 'flex' }}>
                        <TextField
                            disabled={selectAllDates}
                            variant="outlined"
                            id='startDate'
                            type='date'
                            label="Date de début de l'export"
                            InputLabelProps={{ shrink: true }}
                            style={{ margin: '10px 20px 0' }}
                            fullWidth={true}
                        />
                        <TextField
                            disabled={selectAllDates}
                            variant="outlined"
                            id='endDate'
                            type='date'
                            label="Date de fin de l'export"
                            InputLabelProps={{ shrink: true }}
                            style={{ margin: '10px 20px 0' }}
                            fullWidth={true}
                        />
                    </div>
                </div>
                <FormControl style={{ margin: '10px 20px', width: '70%' }}>
                    <InputLabel>Sélectionnez vos sites</InputLabel>
                    <Select
                        multiple
                        onChange={handleChange}
                        value={officesId}
                        renderValue={selected => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {
                                    selected.map(value => {
                                        let label = user.__access_control_list__.flatMap(access => access.offices.filter(office => office.id === value).map(office => office.label));

                                        return <Chip key={Math.random() * 100} label={label} />
                                    })
                                }
                            </Box>
                        )}
                    >
                        {
                            user.__access_control_list__.map(access => (
                                access.offices.map(office => (
                                    <MenuItem key={office.id} value={office.id}>
                                        <Checkbox checked={officesId.indexOf(office.id) > -1} />
                                        <ListItemText primary={`${access.company.name} - ${office.label}`} />
                                    </MenuItem>
                                ))
                            ))
                        }
                    </Select>
                </FormControl>

            </div>
            <div style={{ margin: '50px auto', textAlign: 'center' }}>
                <Button variant="outlined" color='secondary' onClick={handleExport}>Exporter mes marchandises</Button>
            </div>
        </div>
    );
}
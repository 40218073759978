import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import PasswordModal from '@components/Modal/PasswordModal';
import { Api } from '@config/api';
import { alertActions } from '../../../actions/alert.actions';
const generator = require('password-generator')

export default function CreateUser() {

    const { register, handleSubmit, reset } = useForm();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const form = {
        title: "Création d'un utilisateur",
        lines: [
            [
                { label: 'Nom', field: 'lastname', required: true },
                { label: 'Prénom', field: 'firstname', required: true },
            ],
            [
                { label: 'E-mail', field: 'email', required: true },
            ],
            [
                { label: 'Téléphone', field: 'phone', required: true },
            ],
        ]
    }

    const [userCreated, setUserCreated] = useState({ email: '', password: '' });
    const onClick = () => history.goBack();

    const onSubmit = (properties) => {
        properties.lastname = properties.lastname;
        properties.firstname = properties.firstname;
        properties.email = properties.email;
        properties.phone = properties.phone;
        properties.password = generator(12, false);

        Api.post('/users', properties).then((response) => {
            setUserCreated({ email: properties.email, password: properties.password })
            setOpen(true);
            reset({});
        }).catch(() => {
            dispatch(alertActions.error("Un utilisateur avec cette addresse email est déjà existant."));
        });
    }

    return (
        <div style={{ marginTop: 50 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {form.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={4}>
                            <Grid item xs={10}>
                                <CustomForm title={''} lines={form.lines} register={register} />
                            </Grid>
                            <Grid item xs={10}>
                                <Grid container justifyContent="center">
                                    <SaveButton />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <PasswordModal open={open} setOpen={setOpen} user={userCreated} />
        </div>
    );
}
const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    action: '',
    loading: false,
    error: {},
    userInfos: user ?
        {
            ...user,
            loggedIn: true
        } : {
            loggedIn: false
        },
    users: [],
    admins: [],
    usersDisabled: []
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'user/loginRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/loginSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                userInfos: { ...action.user, loggedIn: true }
            };
        case 'user/loginFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
                user: {}
            };
        case 'user/logoutRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/logoutSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                userInfos: { loggedIn: false }
            };
        case 'user/logoutFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error,
            };
        case 'user/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'user/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'user/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'user/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'user/removeRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/removeSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                users: [...state.users.filter(user => user.id !== action.id)]
            };
        case 'user/removeFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'user/getAllRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/getAllSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                userInfos: { ...state.userInfos, ...action.users.find(user => user.id === state.userInfos.id) },
                users: action.users,
            };
        case 'user/getAllFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };

        case 'user/getAllAdminsRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/getAllAdminsSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                admins: action.admins,
            };
        case 'user/getAllAdminsFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };

        case 'user/getAllDeletedRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/getAllDeletedSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                usersDisabled: action.users
            };
        case 'user/getAllDeletedFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'user/changeOfficesRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/changeOfficesSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'user/changeOfficesFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'user/contactRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'user/contactSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'user/contactFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'user/clearSuccess':
            return {
                ...state,
                action: action.type
            };
        default:
            return state;
    }
}
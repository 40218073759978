import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import fileDownload from 'js-file-download';

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

export const generateWaiverTemplate = (address, officeName,startingDate,officeReferentName, companyName) => {

    let baseUrl = "http://localhost:3000"

    if (process.env.NODE_ENV === 'production') {
        baseUrl = "https:/riskmanagement.i-b-s.fr"
    }

    loadFile(`${baseUrl}/renonciation.docx`,

        function (error, content) {
            if (error) {
                throw error;
            }

            const zip = new PizZip(content);

            const doc = new Docxtemplater(zip, {
                paragraphLoop: true,
                linebreaks: true,
            });

            // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
            doc.render({
                address: address,
                office_name: officeName,
                starting_date: startingDate,
                office_referent_name: officeReferentName,
                company_name: companyName,
            });
            const out = doc.getZip().generate({
                type: "blob",
                mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }); //Output the document using Data-URI

            fileDownload(out, "renonciation.docx");
        }
    );
}
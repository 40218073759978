import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { FormatListBulleted } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { eventActions } from '@actions/event.actions';
import { certificationActions } from '@actions/certification.actions';
import { officeActions } from '@actions/office.actions';
import formatDate from '@helpers/formatDate';
import isEmpty from '@helpers/isEmpty';
import 'dhtmlx-scheduler'
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import 'dhtmlx-scheduler/codebase/locale/locale_fr';
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_recurring'
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_multiselect'
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_editors'
import 'dhtmlx-scheduler/codebase/ext/dhtmlxscheduler_minical'
import Header from '../Header/Header';
import OfficeSubAppBar from '../Office';
import './Scheduler.css'
import eventReducer from "../../../reducers/event.reducers";

const frequency_before_event_options = {
    Autre: [
        { key: 0, label: "Aucune" },
        { key: 7, label: "7 jours avant" },
        { key: 14, label: "14 jours avant" },
        { key: 30, label: "30 jours avant" },
    ],
    Hebdomadaire: [
        { key: 0, label: "Aucune" },
        { key: 7, label: "7 jours avant" },
        { key: 14, label: "14 jours avant" },
        { key: 30, label: "30 jours avant" },
    ],
    Mensuelle: [
        { key: 7, label: "7 jours avant" },
        { key: 0, label: "Aucune" },
        { key: 14, label: "14 jours avant" },
        { key: 30, label: "30 jours avant" },
    ],
    Trimestrielle: [
        { key: 14, label: "14 jours avant" },
        { key: 0, label: "Aucune" },
        { key: 7, label: "7 jours avant" },
        { key: 30, label: "30 jours avant" },
    ],
    Semestrielle: [
        { key: 30, label: "30 jours avant" },
        { key: 0, label: "Aucune" },
        { key: 7, label: "7 jours avant" },
        { key: 14, label: "14 jours avant" },
    ],
    Annuelle: [
        { key: 30, label: "30 jours avant" },
        { key: 0, label: "Aucune" },
        { key: 7, label: "7 jours avant" },
        { key: 14, label: "14 jours avant" },
    ],
};

export default function Scheduler() {
    const history = useHistory();
    const { companyId, officeId } = useParams();
    var schedulerContainer = useRef();

    const [certifications, setCertifications] = useState([]);
    const [newEvents, setNewEvents] = useState([]);

    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.userInfos);
    const company = useSelector(state => state.companyReducer.company);
    const office = useSelector(state => state.officeReducer.office);
    const events = useSelector(state => state.eventReducer.events);
    const certificationReducer = useSelector(state => state.certificationReducer);

    const scheduler = window.scheduler;

    const onEventList = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/events`);
    }

    const update_select_options = (select, options) => {
        select.options.length = 0;

        for (let i = 0; i < options.length; i++) {
            let option = options[i];

            select[i] = new Option(option.label, option.key);
        }
    };

    if (certifications && certifications.length > 0)
        scheduler.updateCollection('certifications', certifications);

    useEffect(() => {
        dispatch(officeActions.get(companyId, officeId));
        dispatch(eventActions.getAll(companyId, officeId));
        dispatch(certificationActions.getAll(companyId, officeId));
    }, [dispatch, companyId, officeId]);

    useEffect(() => {
        const handleChangeFrequency = e => {
            const newFrequenciesBeforeEvent = frequency_before_event_options[e.target.value];

            if (newFrequenciesBeforeEvent)
                update_select_options(scheduler.formSection('Périodicité du rappel pré-événement').control, newFrequenciesBeforeEvent);
        };

        scheduler.skin = 'material';
        scheduler.config.header = [
            'day',
            'week',
            'month',
            'date',
            'prev',
            'today',
            'next'
        ];
        if (user.role === 'viewer')
            scheduler.config.readonly = true;
        scheduler.xy.scale_width = 70;
        scheduler.config.responsive_lightbox = true;
        scheduler.config.buttons_right = [];
        scheduler.config.hour_date = '%H:%i';
        scheduler.config.default_date = '%l %j %F %Y';
        scheduler.config.day_scale_date = null;
        scheduler.config.first_hour = 8;
        scheduler.config.last_hour = 18;
        scheduler.config.mark_now = true;
        scheduler.config.full_day = true;
        scheduler.config.drag_create = false;
        scheduler.form_blocks["myDate"] = {
            render: function (config) {
                const height = (config.height || 50) + "px";

                return "<div class='dhx_cal_ltext' style='height: " + height + ";'>" +
                    "<input type='date' /></div>";
            },
            set_value: function (node, value, ev, config) {
                node.querySelector("input").value = value || "";
            },
            get_value: function (node, ev, config) {
                return node.querySelector("input").value;
            },
            focus: function (node) {
                node.querySelector("input").focus();
            }
        };
        scheduler.config.lightbox.sections = [
            {
                name: 'Événement',
                height: 50,
                type: 'select',
                map_to: 'label',
                options: [
                    { key: 'Bonne pratique', label: 'Bonne pratique' },
                    { key: 'Maintenance', label: 'Maintenance' },
                    { key: 'Reserve rapport de visite', label: 'Réserve de rapport de visite' },
                    { key: 'Certification APSAD', label: 'Certification APSAD' }
                ]
            },

            {
                name: 'Type',
                onchange: handleChangeFrequency,
                height: 50,
                type: 'select',
                map_to: 'type',
                default_value: 1,
                options: scheduler.serverList('certifications', certifications ?? [])
            },
            {
                name: 'Société',
                height: 50,
                type: 'textarea',
                map_to: 'company',
                default_value: company.name,
            },
            {
                name: 'Site',
                height: 50,
                type: 'textarea',
                map_to: 'office',
                default_value: office.label,
            },
            {
                name: 'Priorité',
                height: 50,
                type: 'select',
                map_to: 'priority',
                options: [
                    { key: 1, label: 'Haute' },
                    { key: 2, label: 'Moyenne' },
                    { key: 3, label: 'Basse' }
                ]
            },
            {
                name: "Date",
                height: 50,
                type: 'myDate',
                map_to: 'date',
                default_value: formatDate(events.start_date)
            },
            {
                name: "Périodicité de l'événement",
                onchange: handleChangeFrequency,
                height: 50,
                type: 'select',
                map_to: 'frequency_event',
                options: [
                    { key: "none", label: "Aucune" },
                    { key: "Hebdomadaire", label: "Hebdomadaire" },
                    { key: "Mensuelle", label: "Mensuelle" },
                    { key: "Trimestrielle", label: "Trimestrielle" },
                    { key: "Semestrielle", label: "Semestrielle" },
                    { key: "Annuelle", label: "Annuelle" },
                    { key: "2 ans", label: "Tous les 2 ans" },
                    { key: "3 ans", label: "Tous les 3 ans" },
                    { key: "4 ans", label: "Tous les 4 ans" },
                    { key: "5 ans", label: "Tous les 5 ans" }
                ],
            },
            {
                name: 'Périodicité du rappel pré-événement',
                height: 50,
                type: 'select',
                map_to: 'frequency_before_event',
                options: frequency_before_event_options
            },
            {
                name: 'Périodicité du rappel post-événement',
                height: 50,
                type: 'select',
                map_to: 'frequency_after_event',
                options: [
                    { key: 0, label: "Aucune" },
                    { key: 1, label: "Quotidienne" },
                    { key: 7, label: "Hebdomadaire" },
                    { key: 30, label: "Mensuelle" },
                    { key: 60, label: "Semestrielle" },
                ]
            },
            {
                name: 'Fichier',
                height: 50,
                type: 'checkbox',
                map_to: 'proofs',
                checked_value: true,
                unchecked_value: false,
                default_value: true
            }
        ];
        scheduler.clearAll();
        scheduler.resetLightbox();
        scheduler.init(schedulerContainer, new Date(), 'month');
        scheduler.parse(newEvents);
    }, [scheduler, certifications, company, office, user, newEvents]);

    useEffect(() => {
        const eventColors = ['#cc0000', '#FF7900', '#4BB543'];
        let eventsTmp = [];

        if (!scheduler._$initialized) {
            scheduler.attachEvent("onClick", (id, e) => {
                /*const event = events.find(eventTmp => eventTmp.cycles.some(cycleTmp => id === cycleTmp.id));

                history.push(`/companies/${companyId}/offices/${officeId}/reminders/${event.id}/cycles/${id}`);*/
                return false;
            });
            scheduler.attachEvent('onEmptyClick', (date_event, event) => {
                scheduler._on_dbl_click(event);
                return true;
            });
            scheduler.attachEvent("onBeforeLightbox", function (id) {
                let date = new Date(scheduler.getEvent(id).start_date);
                let dateFormat = moment(date).format('YYYY-MM-DD');
                const ev = scheduler.getEvent(id);

                if (!ev.child_id) {
                    const parent_id = 'Hebdomadaire';
                    const new_child_options = frequency_before_event_options[parent_id];

                    update_select_options(scheduler.formSection('Périodicité du rappel pré-événement').control, new_child_options);
                }
                scheduler.formSection('Société').control.disabled = true;
                scheduler.formSection('Site').control.disabled = true;
                scheduler.formSection('Date').section.default_value = dateFormat;
                scheduler.locale.labels.section_checkme = "Ajouter un compte-rendu à l'événement ?";
                return true;
            });
            scheduler.attachEvent('onEventAdded', (id, event) => {
                let end_date = new Date(event.start_date);

                end_date.setFullYear(end_date.getFullYear() + 10);
                const newEvent = {
                    label: event.label,
                    type: Number.isInteger(parseInt(event.type)) ? 'certification' : event.type,
                    certification: Number.isInteger(parseInt(event.type)) ? parseInt(event.type) : null,
                    office: parseInt(officeId),
                    priority: parseInt(event.priority),
                    start_date: event.date,
                    frequency: event.frequency_event,
                    frequency_after_event: parseInt(event.frequency_after_event),
                    frequency_before_event: parseInt(event.frequency_before_event),
                    compulsory_proof: event.proofs,
                }
                dispatch(eventActions.create(newEvent));
                return true;
            });
            scheduler.attachEvent('onViewChange', (new_node, new_date) => {
                if (new_node === 'day' || new_node === 'week')
                    scheduler.config.readonly = true;
                else
                    scheduler.config.readonly = false;
            });
            scheduler._$initialized = true;
        }
        events.forEach(event => {
            if (isEmpty(event.cycles)) return;
            event.cycles.forEach(cycle => {
                let start_date = new Date(new Date(cycle.start_date).setHours(0, 0, 0));
                let end_date = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate() + 1);
                let label;

                if (event.certification)
                    label = event.certification.label + ' ' + event.certification.description
                else
                    label = event.type;
                label = label + ' - ' + event.label;
                eventsTmp.push({
                    id: cycle.id,
                    start_date: start_date,
                    end_date: end_date,
                    text: label,
                    color: eventColors[event.priority - 1],
                    event_length: '86400'
                });
            });
        });
        setNewEvents(eventsTmp);
        scheduler.parse(eventsTmp);
    }, [events, scheduler, dispatch, officeId]);

    useEffect(() => {
        let certificationsTmp = [];

        certificationReducer.certifications.forEach(certification => {
            certificationsTmp.push({ key: certification.id, label: certification.label });
        });
        certificationsTmp.unshift(
            { key: 'other', label: 'Autre' },
            { key: 'recommandation', label: 'Recommandation' },
            { key: 'maintenance', label: 'Maintenance' },
            { key: 'Reserve de rapport de visite', label: 'Réserve de rapport de visite' }
        );
        setCertifications(certificationsTmp);
    }, [certificationReducer]);

    return (
        <div>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            {
                !isEmpty(office)
                    ? <Header office={office} company={company} />
                    : <></>
            }
            <div style={{ textAlign: 'center' }}>
                <Button
                    variant='outlined'
                    startIcon={<FormatListBulleted />}
                    component='div'
                    style={{ height: 60, width: 300 }}
                    color='secondary'
                    onClick={onEventList}
                >
                    Voir la liste d'événements
                </Button>
            </div>
            <div
                ref={input => { schedulerContainer = input }}
                style={{ width: '100%', height: '100vh' }}
            ></div>
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import CustomTable from '@components/Table/CustomTable';
import { officeActions } from '@actions/office.actions';
import Header from '../Header/Header';
import OfficeSubAppBar from '../Office';
import { alertActions } from '../../../actions/alert.actions'
import UpdateDeposit from './UpdateDeposit';
import fileDownload from 'js-file-download';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import {IconButton} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { generateWaiverTemplate } from './TemplateService';
import {FormControl, Input, InputAdornment, InputLabel, makeStyles} from "@material-ui/core";
import {Search} from "@material-ui/icons";

const useStyles = makeStyles(() => ({
    card: {
        margin: 20,
    },
    searchField: {
        width: '20%',
        margin: '30px auto',
        textAlign: 'center'
    }
}));
export default function Deposit() {
    const classes = useStyles();
    const history = useHistory();
    const { companyId, officeId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.userInfos);
    const officeReducer = useSelector(state => state.officeReducer);
    const companyReducer = useSelector(state => state.companyReducer);
    const deposits = useSelector(state => state.officeReducer.deposits);
    const [selectedDeposit, setSelectedDeposit] = useState(undefined);
    const [update, setUpdate] = useState(false);
    const [rows, setRows] = useState([])
    const [waiverSelected, setWaiverSelected] = useState(undefined)
    const [newUserDepositFiltered, setNewUserDepositFiltered] = useState(undefined)


    let columns = [
        { field: 'filingCompany', headerName: 'Entreprise déposante', align: 'center' },
        { field: 'merchandiseType', headerName: 'Type de marchandise', align: 'left' },
        { field: 'quantity', headerName: 'Quantité', align: 'left' },
        { field: 'billing', headerName: 'Montant (€)', align: 'left', type: 'euro' },
        { field: 'startingDate', headerName: 'Date de début', align: 'left', type: 'date' },
        { field: 'endingDate', headerName: 'Date de fin', align: 'left', type: 'date' },
        { field: 'iconType', headerName: 'Type', align: 'left' },
        { field: 'waiver', headerName: 'Fichier associé', type: 'waiverFile', align: 'left' },
    ];

    if (user.role === 'admin') {
        columns = [
            { field: 'filingCompany', headerName: 'Entreprise déposante', align: 'center' },
            { field: 'merchandiseType', headerName: 'Type de marchandise', align: 'left' },
            { field: 'quantity', headerName: 'Quantité', align: 'left' },
            { field: 'calculation', headerName: 'Cotisation', align: 'left', type: 'euros' },
            { field: 'billing', headerName: 'Montant (€)', align: 'left', type:'euro' },
            { field: 'startingDate', headerName: 'Date de début', align: 'left', type: 'date' },
            { field: 'endingDate', headerName: 'Date de fin', align: 'left', type: 'date' },
            { field: 'iconType', headerName: 'Type', align: 'left' },
            { field: 'waiver', headerName: 'Fichier associé', type: 'waiverFile', align: 'left' },
        ];
    }

    const onCreate = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/deposits/create`);
    }

    useEffect(() => {
        dispatch(officeActions.getDeposits(companyId, officeId))
    }, [dispatch, companyId, officeId]);

    useEffect(() => {

        if (officeReducer.action === 'office/getDepositsSuccess') {
            dispatch(officeActions.clearSuccess());
            let tmp = [];
            const iconTranslation = {
                'Renonciation à recours': <Tooltip title="Renonciation à recours"><IconButton><EventBusyIcon /></IconButton></Tooltip>,
                'Comptabilité': <Tooltip title="Comptabilité"><IconButton><AssuredWorkloadIcon /></IconButton></Tooltip>,
                'Ne sais pas': <Tooltip title="Ne sais pas"><IconButton><ErrorOutlineIcon /></IconButton></Tooltip>,
            }
            for (const deposit of deposits) {
                    const copy = { ...deposit };
                    copy.iconType = iconTranslation[copy.type] ?? iconTranslation['unknown']
                    tmp.push(copy)
            }
            setRows(tmp);
        }

        if (officeReducer.action === 'office/updateDepositsSuccess') {
            dispatch(officeActions.clearSuccess());
            dispatch(alertActions.success("La marchandise a été modifié avec succès."))
            dispatch(officeActions.getDeposits(companyId, officeId))
        }

        if (officeReducer.action === 'office/deleteDepositsSuccess') {
            dispatch(officeActions.clearSuccess());
            dispatch(alertActions.success("La marchandise a été supprimer avec succès."))
            dispatch(officeActions.getDeposits(companyId, officeId))
        }

        if (officeReducer.action === 'office/createDepositsSuccess') {
            dispatch(officeActions.clearSuccess());

            if (officeReducer.deposit && officeReducer.deposit.type === 'Renonciation à recours') {
                const address = officeReducer.office.address;
                const formattedAddress = `${address.street_number} ${address.street_name}, ${address.city}, ${address.zip_code}, ${address.country}`
                const referentName= `${officeReducer.deposit.applicant.firstname} ${officeReducer.deposit.applicant.lastname}`
                generateWaiverTemplate(formattedAddress, officeReducer.office.label, officeReducer.deposit.startingDate, referentName,companyReducer.company.name)
            }

                let total = 0;
                for (const deposit of deposits) {
                    total += deposit.billing;
                }
                if (total >= ((companyReducer.company.limitAmountOfCapitals / 100) * 80)){
                    dispatch(alertActions.error("Le montant total des dossiers déposés est supérieur à 80% du montant total de la facture."))
                }else {
                    dispatch(alertActions.success("La marchandise a été crée avec succès."))
                }
        }

    }, [companyId, officeId, deposits, officeReducer, history, dispatch]);

    const onDelete = (depositSelected) => {
        if (depositSelected && depositSelected.id) {
            dispatch(officeActions.deleteDeposit(companyId, officeId, depositSelected.id))
        }
    }

    const onUpdate = (selected) => {
        setSelectedDeposit(selected)
        setUpdate(true)
    }

    const handleDownload = (deposit) => {
        setWaiverSelected(deposit.waiver);
        dispatch(officeActions.downloadWaiver(companyId, officeId, deposit.id))
    }

    useEffect(() => {
        if (officeReducer.action === 'office/uploadWaiverSuccess') {
            dispatch(officeActions.clearSuccess());
            dispatch(officeActions.getDeposits(companyId, officeId))
        }

        if (officeReducer.action === 'office/downloadWaiverSuccess') {
            dispatch(officeActions.clearSuccess());
            fileDownload(officeReducer.file, waiverSelected.originalname);
            setSelectedDeposit(undefined)
        }
    }, [companyId, officeId, waiverSelected, officeReducer, dispatch]);

    const handleUpload = (deposit, event) => {
        dispatch(officeActions.getDeposits(companyId, officeId))
        const data = new FormData();
        const file = event.target.files[0]
        data.append('file', file);
        dispatch(officeActions.uploadWaiver(companyId, officeId, deposit.id, data))
    }

    const filterArray = (e) => {
        let input = e.target.value;
        let newUserDepositTmp= [];

        rows.forEach(deposit => {
            return Object.entries(deposit).some(([key, value]) => {
                if (key === 'filingCompany' && value.toLowerCase().includes(input.toLowerCase())) {
                    newUserDepositTmp.push(deposit);
                    return true;
                }else if (key === 'merchandiseType' && value.toLowerCase().includes(input.toLowerCase())) {
                    newUserDepositTmp.push(deposit);
                    return true;
                }else if (key === 'type' && value.toLowerCase().includes(input.toLowerCase())) {
                    newUserDepositTmp.push(deposit);
                    return true;
                }
            });
        });
        setNewUserDepositFiltered(newUserDepositTmp);

    }
    return (

        update ?
            <UpdateDeposit deposit={selectedDeposit} companyId={companyId} officeId={officeId} setUpdate={setUpdate} />
            :
            <>
                <div height="800">
                    <OfficeSubAppBar companyId={companyId} officeId={officeId} />
                    <Header />

                    <div className={classes.searchField}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel htmlFor="input-with-icon-adornment">Rechercher...</InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                onKeyUp={filterArray}
                                endAdornment={
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>

                    <CustomTable
                        columns={columns}
                        rows={newUserDepositFiltered ?? rows}
                        title="Liste des marchandises"
                        onCreate={user.role !== 'viewer' ? onCreate : null}
                        createLabel="Ajouter une marchandise."
                        onUpdate={user.role === 'admin' ? onUpdate : null}
                        onDelete={user.role === 'admin' ? onDelete : null}
                        handleDownload={handleDownload}
                        handleUpload={handleUpload}
                    />
                </div>
            </>
    );

}
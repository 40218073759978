import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL ?? "http://localhost:8000"

const instance = axios.create({ withCredentials: true });

function logout(reload) {
    localStorage.setItem('loggedIn', false);
    localStorage.removeItem('user');
    if (reload)
        document.location.reload();
}

export const Api = {

    get(path, options) {
        return new Promise ((resolve, reject) => {
            instance.get(`${baseUrl}${path}`, options).then((response) => {
                resolve(response);
            }).catch((err) => {
                if (!err.response)
                    console.log(err);
                else if (err.response.status === 401)
                    logout(true);
                reject(err);
            })
        })
    },
    post(path, body, header) {
        return new Promise ((resolve, reject) => {
            instance.post(`${baseUrl}${path}`, body, header)
            .then((response) => {
                resolve(response);
            }).catch((err) => {
                if (!err.response)
                    console.log(err);
                else if (err.response.status === 401)
                    logout();
                reject(err);
            })
        })
    },
    patch(path, body) {
        return new Promise ((resolve, reject) => {
            instance.patch(`${baseUrl}${path}`, body).then((response) => {
                resolve(response);
            }).catch((err) => {
                if (!err.response)
                    console.log(err);
                else if (err.response.status === 401)
                    logout();
                reject(err);
            })
        })
    },
    delete(path, header) {
        return new Promise ((resolve, reject) => {
            instance.delete(`${baseUrl}${path}`, header).then((response) => {
                resolve(response);
            }).catch((err) => {
                if (!err.response)
                    console.log(err);
                else if (err.response.status === 401)
                    logout();
                reject(err);
            })
        })
    },
}
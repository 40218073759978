import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Grid, Typography, Button, Card, CardContent, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import PasswordModal from '@components/Modal/PasswordModal';
import { userActions } from '@actions/user.actions';

const generator = require('password-generator');

const useStyles = makeStyles(() => ({
    card: {
        margin: '50px auto',
        padding: 20,
        width: 700,
        textAlign: 'center',
    }
}));

const PermissionRow = ({ title, checkboxes, setCheboxes }) => {
    const [high, setHigh] = useState(checkboxes.high)
    const [medium, setMedium] = useState(checkboxes.medium)
    const [low, setLow] = useState(checkboxes.low)
    const [all, setAll] = useState(checkboxes.medium && checkboxes.high && checkboxes.low)

    return (
        <Grid container spacing={6}>
            <Grid item xs={4}>
                <Typography style={{ fontWeight: 500 }}>
                    {title}
                </Typography>
            </Grid>
            <Grid item>
                <Checkbox checked={low} onChange={(e) => {
                    setLow(e.target.checked)
                    setCheboxes({ ...checkboxes, low: e.target.checked })
                }} />
            </Grid>
            <Grid item>
                <Checkbox checked={medium} onChange={(e) => {
                    setMedium(e.target.checked)
                    setCheboxes({ ...checkboxes, medium: e.target.checked })
                }} />
            </Grid>
            <Grid item>
                <Checkbox checked={high} onChange={(e) => {
                    setHigh(e.target.checked)
                    setCheboxes({ ...checkboxes, high: e.target.checked })
                }} />
            </Grid>
            <Grid item>
                <Checkbox checked={all} onChange={(e) => {
                    setAll(e.target.checked)
                    setLow(e.target.checked)
                    setMedium(e.target.checked)
                    setHigh(e.target.checked)
                    setCheboxes({ low: e.target.checked, medium: e.target.checked, high: e.target.checked })
                }} />
            </Grid>
        </Grid>
    )
}

const typesAvailable = [
    { key: 'Bonne pratique', label: 'Bonne pratique' },
    { key: 'Certification APSAD', label: 'Certification APSAD' },
    { key: 'Reserve rapport de visite', label: 'Reserve rapport de visite' },
    { key: 'Maintenance', label: 'Maintenance' },
]
export default function UpdateUser(props) {
    const { register, handleSubmit } = useForm();
    const history = useHistory();
    const classes = useStyles();
    const user = props.location.state.user;
    const dispatch = useDispatch();
    const userReducer = useSelector(state => state.userReducer);
    const [open, setOpen] = useState(false);
    const [selectedType, setSelectedType] = useState(typesAvailable[0].key);
    const [userInfos, setUserInfos] = useState({
        action: undefined,
        email: user.email,
        password: undefined
    });


    const form = {
        title: `Gestion de ${user.lastname.toUpperCase()} ${user.firstname}`,
        lines: [
            [
                { label: 'Nom', field: 'lastname', data: user.lastname },
                { label: 'Prénom', field: 'firstname', data: user.firstname },
            ],
            [
                { label: 'E-mail', field: 'email', data: user.email },
            ],
            [
                { label: 'Téléphone', field: 'phone', data: user.phone },
            ],
            ]
    }

    const onClick = () => history.goBack();

    const regenPassword = () => {
        const password = generator(12, false);

        setUserInfos({
            action: 'regenPassword',
            email: user.email,
            password: password,
        });
        dispatch(userActions.update(user.id, { password: password }));
    }

    const onSubmit = (properties) => {
        dispatch(userActions.update(user.id, properties));
    };

    useEffect(() => {
        if (userReducer.action === 'user/updateSuccess') {
            dispatch(userActions.clearSuccess());
            if (userInfos.action === 'regenPassword') setOpen(true);
            else if (userInfos.action === 'mailing');
            else {
                history.push('/admin/users');
                window.location.reload();
            }
        }
    }, [dispatch, history, userInfos, userReducer]);

    const bonnePratique = typesAvailable.find(type => type.key === 'Bonne pratique');
    const maintenance = typesAvailable.find(type => type.key === 'Maintenance');
    const certification = typesAvailable.find(type => type.key === 'Certification APSAD');
    const reserve = typesAvailable.find(type => type.key === 'Reserve rapport de visite');

    const forms = {
        Pratique: [
            [
                { label: 'Type', field: 'type', data: bonnePratique.label, type: 'text', disabled : true },
            ]
        ],
        Maintenance: [
            [
                { label: 'Type', field: 'type', data: maintenance.label, type: 'text', disabled : true },
            ]
        ],
        Certification: [
            [
                { label: 'Type', field: 'type', data: certification.label, type: 'text', disabled : true },
            ]
        ],
        Reserve: [
            [
                { label: 'Type', field: 'type', data: reserve.label, type: 'text', disabled : true },
            ]
        ],
    };
    const [mailBeforeEvent, setMailBeforeEvent] = useState({
        high: user.mailing_before_event_high ?? false,
        medium: user.mailing_before_event_medium ?? false,
        low: user.mailing_before_event_low ?? false
    });
    const [mailAfterEvent, setMailAfterEvent] = useState({
        high: user.mailing_after_event_high ?? false,
        medium: user.mailing_after_event_medium ?? false,
        low: user.mailing_after_event_low ?? false
    });
    const [mailDdayEvent, setMailDdayEvent] = useState({
        high: user.mailing_d_day_high ?? false,
        medium: user.mailing_d_day_medium ?? false,
        low: user.mailing_d_day_low ?? false
    });

    const [mailBeforeMaintenance, setMailBeforeMaintenance] = useState({
        high: user.mailing_before_maintenance_high ?? false,
        medium: user.mailing_before_maintenance_medium ?? false,
        low: user.mailing_before_maintenance_low ?? false
    } );
    const [mailAfterMaintenance, setMailAfterMaintenance] = useState({
        high: user.mailing_after_maintenance_high ?? false,
        medium: user.mailing_after_maintenance_medium ?? false,
        low: user.mailing_after_maintenance_low ?? false
    } );
    const [mailDdayMaintenance, setMailDdayMaintenance] = useState({
        high: user.mailing_d_day_maintenance_high ?? false,
        medium: user.mailing_d_day_maintenance_medium ?? false,
        low: user.mailing_d_day_maintenance_low ?? false
    } );

    const [mailBeforeAPSAD, setMailBeforeAPSAD] = useState({
        high: user.mailing_before_apsad_high ?? false,
        medium: user.mailing_before_apsad_medium ?? false,
        low: user.mailing_before_apsad_low ?? false
    });
    const [mailAfterAPSAD, setMailAfterAPSAD] = useState({
        high: user.mailing_after_apsad_high ?? false,
        medium: user.mailing_after_apsad_medium ?? false,
        low: user.mailing_after_apsad_low ?? false
    });
    const [mailDdayAPSAD, setMailDdayAPSAD] = useState({
        high: user.mailing_d_day_apsad_high ?? false,
        medium: user.mailing_d_day_apsad_medium ?? false,
        low: user.mailing_d_day_apsad_low ?? false
    });

    const [mailBeforeReserve, setMailBeforeReserve] = useState({
        high: user.mailing_before_reserve_high ?? false,
        medium: user.mailing_before_reserve_medium ?? false,
        low: user.mailing_before_reserve_low ?? false
    });
    const [mailAfterReserve, setMailAfterReserve] = useState({
        high: user.mailing_after_reserve_high ?? false,
        medium: user.mailing_after_reserve_medium ?? false,
        low: user.mailing_after_reserve_low ?? false
    });
    const [mailDdayReserve, setMailDdayReserve] = useState({
        high: user.mailing_d_day_reserve_high ?? false,
        medium: user.mailing_d_day_reserve_medium ?? false,
        low: user.mailing_d_day_reserve_low ?? false
    });

    const onPermissionSubmit = () => {
        if (selectedType === 'Bonne pratique') {
            const payload = {
                mailing_d_day_high: mailDdayEvent.high,
                mailing_d_day_low: mailDdayEvent.low,
                mailing_d_day_medium: mailDdayEvent.medium,
                mailing_after_event_high: mailAfterEvent.high,
                mailing_after_event_medium: mailAfterEvent.medium,
                mailing_after_event_low: mailAfterEvent.low,
                mailing_before_event_high: mailBeforeEvent.high,
                mailing_before_event_low: mailBeforeEvent.low,
                mailing_before_event_medium: mailBeforeEvent.medium,
                mailing_d_day_maintenance_high: mailDdayMaintenance.high,
                mailing_d_day_maintenance_low: mailDdayMaintenance.low,
                mailing_d_day_maintenance_medium: mailDdayMaintenance.medium,
                mailing_after_maintenance_high: mailAfterMaintenance.high,
                mailing_after_maintenance_medium: mailAfterMaintenance.medium,
                mailing_after_maintenance_low: mailAfterMaintenance.low,
                mailing_before_maintenance_high: mailBeforeMaintenance.high,
                mailing_before_maintenance_low: mailBeforeMaintenance.low,
                mailing_before_maintenance_medium: mailBeforeMaintenance.medium,
                mailing_d_day_apsad_high: mailDdayAPSAD.high,
                mailing_d_day_apsad_low: mailDdayAPSAD.low,
                mailing_d_day_apsad_medium: mailDdayAPSAD.medium,
                mailing_after_apsad_high: mailAfterAPSAD.high,
                mailing_after_apsad_medium: mailAfterAPSAD.medium,
                mailing_after_apsad_low: mailAfterAPSAD.low,
                mailing_before_apsad_high: mailBeforeAPSAD.high,
                mailing_before_apsad_low: mailBeforeAPSAD.low,
                mailing_before_apsad_medium: mailBeforeAPSAD.medium,
                mailing_d_day_reserve_high: mailDdayReserve.high,
                mailing_d_day_reserve_low: mailDdayReserve.low,
                mailing_d_day_reserve_medium: mailDdayReserve.medium,
                mailing_after_reserve_high: mailAfterReserve.high,
                mailing_after_reserve_medium: mailAfterReserve.medium,
                mailing_after_reserve_low: mailAfterReserve.low,
                mailing_before_reserve_high: mailBeforeReserve.high,
                mailing_before_reserve_low: mailBeforeReserve.low,
                mailing_before_reserve_medium: mailBeforeReserve.medium,
            }
            dispatch(userActions.update(user.id, payload));
        }
    }
    return (
        <div style={{ marginTop: 50 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h5" style={{ fontWeight: 500 }}>
                                    {form.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={4}>
                            <Grid item xs={10}>
                                <CustomForm title={''} lines={form.lines} register={register} />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex' }}>
                                <Grid container justifyContent="flex-end" style={{ margin: 20 }}>
                                    <SaveButton />
                                </Grid>
                                <Grid container justifyContent='flex-start' style={{ margin: 20 }}>
                                    <Button variant="outlined" color='secondary' onClick={regenPassword}>Regénérer un mot de passe</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Card className={classes.card}>
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={forms.Pratique} register={register} />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Faible
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Moyenne
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Élevé
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Tous
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <CardContent>
                    <form onSubmit={handleSubmit(onPermissionSubmit)}>
                        <PermissionRow title={'Rappel pré-évènement'} checkboxes={mailBeforeEvent} setCheboxes={setMailBeforeEvent} />
                        <PermissionRow title={'Rappel du jour'} checkboxes={mailDdayEvent} setCheboxes={setMailDdayEvent} />
                        <PermissionRow title={'Rappel post-évènement'} checkboxes={mailAfterEvent} setCheboxes={setMailAfterEvent} />
                    </form>
                </CardContent>

                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={forms.Maintenance} register={register} />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Faible
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Moyenne
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Élevé
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Tous
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>

                <CardContent>
                    <form onSubmit={handleSubmit(onPermissionSubmit)}>
                        <PermissionRow title={'Rappel pré-évènement'} checkboxes={mailBeforeMaintenance} setCheboxes={setMailBeforeMaintenance} />
                        <PermissionRow title={'Rappel du jour'} checkboxes={mailDdayMaintenance} setCheboxes={setMailDdayMaintenance} />
                        <PermissionRow title={'Rappel post-évènement'} checkboxes={mailAfterMaintenance} setCheboxes={setMailAfterMaintenance} />
                    </form>
                </CardContent>

                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={forms.Certification} register={register} />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Faible
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Moyenne
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Élevé
                            </Typography>
                        </Grid>


                        <Grid item>
                            <Typography style={{ fontWeight: 500 }}>
                                Tous
                            </Typography>
                        </Grid>
                    </Grid>

                    <form onSubmit={handleSubmit(onPermissionSubmit)}>
                        <PermissionRow title={'Rappel pré-évènement'} checkboxes={mailBeforeAPSAD} setCheboxes={setMailBeforeAPSAD} />
                        <PermissionRow title={'Rappel du jour'} checkboxes={mailDdayAPSAD} setCheboxes={setMailDdayAPSAD} />
                        <PermissionRow title={'Rappel post-évènement'} checkboxes={mailAfterAPSAD} setCheboxes={setMailAfterAPSAD} />
                        <Grid container justifyContent="center" mt={10} style={{ margin: 20 }}>
                        </Grid>
                    </form>

                </CardContent>

            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={10}>
                        <CustomForm title={''} lines={forms.Reserve} register={register} />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item>
                        <Typography style={{ fontWeight: 500 }}>
                            Faible
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 500 }}>
                            Moyenne
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{ fontWeight: 500 }}>
                            Élevé
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography style={{ fontWeight: 500 }}>
                            Tous
                        </Typography>
                    </Grid>
                </Grid>

                <form onSubmit={handleSubmit(onPermissionSubmit)}>
                    <PermissionRow title={'Rappel pré-évènement'} checkboxes={mailBeforeReserve} setCheboxes={setMailBeforeReserve} />
                    <PermissionRow title={'Rappel du jour'} checkboxes={mailDdayReserve} setCheboxes={setMailDdayReserve} />
                    <PermissionRow title={'Rappel post-évènement'} checkboxes={mailAfterReserve} setCheboxes={setMailAfterReserve} />
                    <Grid container justifyContent="center" mt={10} style={{ margin: 20 }}>
                        <SaveButton />
                    </Grid>
                </form>

            </CardContent>
            </Card>
            <PasswordModal
                open={open}
                setOpen={setOpen}
                user={userInfos}
            />
        </div >
    );
}
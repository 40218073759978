import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { eventActions } from '@actions/event.actions';
import formatDate from '@helpers/formatDate';
import { stringToLocalDate } from '@helpers/formatDate';

export default function UpdateEvent(props) {
    const { register, handleSubmit, reset } = useForm();
    const { eventId, cycleId } = useParams();

    const history = useHistory();

    const event = props.location.state.event;

    const dispatch = useDispatch();
    const eventReducer = useSelector(state => state.eventReducer);

    const [priority, setPriority] = useState(event.priority ?? 1);
    const [status, setStatus] = useState(event.status ?? 0);
    const [frequency, setFrequency] = useState(event.frequency ?? "Aucune");
    const [frequencyBeforeEvent, setFrequencyBeforeEvent] = useState(event.frequency_before_event ?? 0);
    const [frequencyAfterEvent, setFrequencyAfterEvent] = useState(event.frequency_after_event ?? 0);

    console.log(event);
    const priorityOptions = [
        { label: 'Haute', key: 1 },
        { label: 'Moyenne', key: 2 },
        { label: 'Basse', key: 3 }
    ];
    const statusOptions = [
        { label: 'En cours', key: 0 },
        { label: 'Terminé', key: 1 },
        { label: 'Sans suite', key: 2 }
    ];

    const frequencyOptions =  [
        { key: "none", label: "Aucune" },
        { key: "Hebdomadaire", label: "Hebdomadaire" },
        { key: "Mensuelle", label: "Mensuelle" },
        { key: "Trimestrielle", label: "Trimestrielle" },
        { key: "Semestrielle", label: "Semestrielle" },
        { key: "Annuelle", label: "Annuelle" },
        { key: "2 ans", label: "Tous les 2 ans" },
        { key: "3 ans", label: "Tous les 3 ans" },
        { key: "4 ans", label: "Tous les 4 ans" },
        { key: "5 ans", label: "Tous les 5 ans" }
    ];

    const frequencyBeforeEventOptions = [
        { label: "Aucune", key: 0 },
        { label: "7 jours avant", key: 7 },
        { label: "14 jours avant", key: 14 },
        { label: "30 jours avant", key: 30 },
    ];

    const  frequencyAfterEventOptions = [
        { key: 0, label: "Aucune" },
        { key: 1, label: "Quotidienne" },
        { key: 7, label: "Hebdomadaire" },
        { key: 30, label: "Mensuelle" },
        { key: 60, label: "Semestrielle" }
    ];


    const onSubmit = (properties) => {
        properties.status = status;
        properties.priority = priority;
        dispatch(eventActions.update(eventId, cycleId, properties));
        properties.frequency = frequency;
        properties.frequency_before_event = frequencyBeforeEvent;
        properties.frequency_after_event = frequencyAfterEvent;
        dispatch(eventActions.updateEvent(eventId, properties));
        reset({});
    }


    const onClick = () => history.goBack();

    const form = {
        title: `Modification de l'événement '${event.label}' du ${stringToLocalDate(event.start_date)}`,
        lines: [
            [
                { label: 'Date', field: 'start_date', data: formatDate(event.start_date), type: 'date' }
            ],
            [
                { label: 'Priorité', field: 'priority', data: priority, setData: setPriority, type: 'select', options: priorityOptions  }
            ],
            [
                { label: 'Statut', field: 'status', data: status, setData: setStatus, type: 'select', options: statusOptions }
            ],
            [
                { label: 'Périodicité de l\'évènement', field: 'frequency',data:frequency, setData: setFrequency, type: 'select', options: frequencyOptions}
            ],
            [
                { label: 'Périodicité du rappel pré-événement', field: 'frequency_before_event', data: frequencyBeforeEvent, setData: setFrequencyBeforeEvent, type: 'select', options: frequencyBeforeEventOptions },
                { label: 'Périodicité du rappel post-événement', field: 'frequency_after_event', data: frequencyAfterEvent, setData: setFrequencyAfterEvent, type: 'select', options: frequencyAfterEventOptions }
            ],
        ]
    }

    useEffect(() => {
        if (eventReducer.action === 'event/updateSuccess')
            history.goBack();
    }, [eventReducer, history]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 50 }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={form.lines} register={register} />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <SaveButton />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
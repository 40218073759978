import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    content: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        '& h1': {
            margin: 0,
            display: 'inline',
            fontWeight: 500,
            fontSize: 20
        },
        '& h2': {
            margin: 0,
            display: 'inline',
            fontWeight: 400,
            fontSize: 18
        }
    },
    title: {
        border: 'lightgray 2px solid',
        background: 'lightgray',
        borderBottom: 'none',
        padding: '10px 20px',
        textAlign: 'center',
        '& h1': {
            fontSize: 20,
            fontWeight: 900
        }
    },
    companyName: {
        border: 'lightgray 2px solid',
        borderBottom: 'none',
        padding: '10px 20px',
    },
    contact: {
        border: 'lightgray 2px solid',
        padding: '10px 20px',
        display: 'flex',
    },
    logo: {
        textAlign: 'center',
        margin: '50px 0'
    }
}));

function Company({ company, logo }) {
    const classes = useStyles();

    return (
        <Grid container className={classes.content}>
            <Grid className={classes.title}>
                <h1>Informations sur la société</h1>
            </Grid>
            <Grid item className={classes.names}>
                <Grid className={classes.companyName} container xs={12}>
                    <Grid xs={12} item>
                        <h1>Nom: </h1>
                        <h2>{company.name.toUpperCase()}</h2>
                    </Grid>
                </Grid>
            </Grid>

            {
                logo instanceof Blob
                    ? <Grid item xs={12} className={classes.logo}>
                        <img src={URL.createObjectURL(logo)} alt='Logo de la société' height={300} />
                    </Grid>
                    : <></>
            }
        </Grid>
    );
}

export default Company;
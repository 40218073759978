import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import monitorReducerEnhancer from '@enhancers/monitorReducer';
import userReducer from '@reducers/user.reducers';
import alertReducer from '@reducers/alert.reducers';
import companyReducer from '@reducers/company.reducers';
import officeReducer from '@reducers/office.reducers';
import certificationReducer from '@reducers/certification.reducers';
import eventReducer from '@reducers/event.reducers';
import billReducer from '@reducers/bill.reducers';
import reserveReducer from '@reducers/reserve.reducers';
import exportReducer from '@reducers/export.reducers';

const loggerMiddleware = createLogger();

const staticReducers = {
    userReducer: userReducer,
    companyReducer: companyReducer,
    officeReducer: officeReducer,
    certificationReducer: certificationReducer,
    eventReducer: eventReducer,
    billReducer: billReducer,
    reserveReducer: reserveReducer,
    alertReducer: alertReducer,
    exportReducer: exportReducer,
}

export function createReducerManager(initialReducers) {
    const reducers = { ...initialReducers }
    let combinedReducer = combineReducers(reducers)
    let keysToRemove = []

    return {
        getReducerMap: () => reducers,
        reduce: (state, action) => {
            if (keysToRemove.length > 0) {
                state = { ...state }
                for (let key of keysToRemove) {
                    delete state[key]
                }
                keysToRemove = []
            }

            return combinedReducer(state, action)
        },
        add: (key, reducer) => {
            if (!key || reducers[key]) return;
            reducers[key] = reducer
            combinedReducer = combineReducers(reducers)
        },
        remove: key => {
            if (!key || !reducers[key]) return;
            delete reducers[key]
            keysToRemove.push(key)
            combinedReducer = combineReducers(reducers)
        }
    }
}

export default function configureStore(preloadedState) {
    const middlewares = [thunkMiddleware];

    if (process.env.NODE_ENV === 'development') {
        middlewares.push(loggerMiddleware);
    }
    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer, monitorReducerEnhancer];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const reducerManager = createReducerManager(staticReducers);

    const store = createStore(reducerManager.reduce, preloadedState, composedEnhancers);

    store.reducerManager = reducerManager;

    return store;
}
import { Api } from '@config/api';
const login = (email, password) =>
    Api.post('/auth/login', { email, password })
        .then(handleResponse)
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user));
            return user;
        });

const logout = () => Api.get('/auth/logout').then(handleResponse);

const create = properties => Api.post('/users', properties).then(handleResponse);

const update = (id, properties) => Api.patch(`/users/${id}`, properties).then(handleResponse);

const remove = id => Api.delete(`/users/${id}`).then(handleResponse);

const getAll = (query = undefined) =>
    query
        ? Api.get(`/users?role=${query}`).then(handleResponse)
        : Api.get('/users').then(handleResponse);

const getAllDeleted = () => Api.get('/users/deleted').then(handleResponse);

const changeOffices = (id, properties) => Api.patch(`/users/${id}/access_control_list`, properties).then(handleResponse);

const contact = properties => Api.post(`/task/contact`, properties).then(handleResponse);

const handleResponse = response => {
    if (response.status === 200 || response.status === 201) return response.data;
    if (response.status === 401) return;
    const error = (response && response.message) || response.statusText;
    return Promise.reject(error);
};
const userService = {
    login,
    logout,
    create,
    update,
    remove,
    getAll,
    getAllDeleted,
    changeOffices,
    contact
};
export { userService };

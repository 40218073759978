import { IconButton } from '@material-ui/core';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const VisualizeButton = ({ row, onVisualize }) => {
    if (!onVisualize)
        return <></>;

    return (
        <IconButton color="primary" component="span" onClick={(e) => {onVisualize(row)}}>
            <VisibilityIcon />
        </IconButton>
    )
};
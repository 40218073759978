import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import { FormatListBulleted } from '@material-ui/icons';
import { Circle } from '@mui/icons-material';
import CustomTable from '@components/Table/CustomTable';
import AttachFileModal from '@components/Modal/AttachFileModal';
import ReserveModal from '@components/Modal/ReserveModal';
import DeleteEvent from '@components/Modal/DeleteEvent';
import { eventActions } from '@actions/event.actions';
import { companyActions } from '@actions/company.actions';
import { officeActions } from '@actions/office.actions';
import { compare, compareReversed, compareDate, compareDateReversed } from '@helpers/sort';
import isEmpty from '@helpers/isEmpty';
import formatDate from '@helpers/formatDate';
import { stringToLocalDate } from '@helpers/formatDate';
import Header from '../Header/Header';
import OfficeSubAppBar from '../Office';

export default function Events() {
    const history = useHistory();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.userInfos);
    const company = useSelector(state => state.companyReducer.company);
    const officeReducer = useSelector(state => state.officeReducer);
    const office = useSelector(state => state.officeReducer.office);
    const eventReducer = useSelector(state => state.eventReducer);
    const events = useSelector(state => state.eventReducer.events);

    const [newEvents, setNewEvents] = useState([]);
    const [eventsFiltered, setEventsFiltered] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [isArraySorted, setIsArraySorted] = useState(Array(9).fill(0));
    const [openReserve, setOpenReserve] = useState(false);
    const [eventSelected, setEventSelected] = useState({ eventId: null, cycleId: null, event: null, cycle: null });
    const [searchedInput, setSearchedInput] = useState('');
    const [dateFilter, setDateFilter] = useState({ startDate: formatDate(new Date()), endDate: formatDate(new Date()) });
    const [prioritySelected, setPrioritySelected] = useState({
        high: true,
        medium: true,
        low: true
    });

    const onScheduler = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/scheduler`);
    }

    const onCreate = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/events/create`);
    }

    const onVisualize = (cycleTmp) => {
        const eventTmp = events.find(event => event.cycles.some(cycle => cycle.id === cycleTmp.id));

        history.push({
            pathname: `/companies/${companyId}/offices/${officeId}/reminders/${eventTmp.id}/cycles/${cycleTmp.id}`,
            state: { event: cycleTmp }
        });
    }

    const onUpdate = (cycle) => {
        const event = events.find(event => event.cycles.some(cycleTmp => cycleTmp.id === cycle.id));

        if (event) {
            history.push({
                pathname: `/companies/${companyId}/offices/${officeId}/events/${event.id}/cycles/${cycle.id}/update`,
                state: { event: cycle }
            });
        }
    }

    const onExport = () => {
        if (eventsFiltered.length === 0)
            return false;

        const startDate = formatDate(new Date(Math.min(...eventsFiltered.map(event => new Date(event.start_date)))));
        const endDate = formatDate(new Date(Math.max(...eventsFiltered.map(event => new Date(event.start_date)))));
        const types = ['En cours', 'Terminé', 'Sans Suite'];
        const priorities = ['Haute', 'Moyenne', 'Basse'];
        const headers = ["Site", "Évènement", "Statut", "Type", "Nombre de réserve", "Date", "Fréquence","Périodicité du rappel pré-événement", "Priorité", "Nombre de pièces justificatives"];
        const rows = [
            ...eventsFiltered.map(item => [
                item.office,
                item.label,
                types[item.status],
                item.type,
                item.reserves ? item.reserves.length : 0,
                stringToLocalDate(item.start_date),
                item.frequency,
                item.frequency_before_event,
                item.frequency_after_event,
                priorities[item.priority - 1],
                item.proofs.length
            ])
        ];
        const csvContent = 'data:text/csv;sep=;;charset=utf-8,' + headers.join(';') + '\n' + rows.map(event => event.join(';')).join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `Export_du_${startDate}_au_${endDate}.csv`);
        document.body.appendChild(link);
        link.click();

        return true
    }

    const onDelete = (cycle) => {
        const event = events.find(eventTmp => eventTmp.cycles.some(cycleTmp => cycle.id === cycleTmp.id));

        setEventSelected({ eventId: event.id, cycleId: cycle.id, event: event, cycle: cycle });
        setOpenDelete(true);
    }

    const changePrioritySelected = (priority) => {
        if (priority === 'low')
            setPrioritySelected({ ...prioritySelected, low: !prioritySelected.low });
        else if (priority === 'medium')
            setPrioritySelected({ ...prioritySelected, medium: !prioritySelected.medium });
        else
            setPrioritySelected({ ...prioritySelected, high: !prioritySelected.high });
    }

    const sortColumn = (index, rows) => {
        let array = [...rows];
        let arrow = document.getElementById('arrow-' + index)
        let arraySorted = isArraySorted;

        arrow.style.transition = 'all 0.4s ease';
        if (index === 2) {
            if (arraySorted[index] === 0) {
                arraySorted.fill(0);
                arraySorted[index] = 1;
                arrow.style.transform = 'rotate(0deg)';
                array = compareDate(array, index);
            } else if (arraySorted[index] === 1) {
                arraySorted[index] = 2;
                arrow.style.transform = 'rotate(180deg)';
                array = compareDateReversed(array, index);
            } else {
                arraySorted[index] = 1;
                arrow.style.transform = 'rotate(0deg)';
                array = compareDate(array, index);
            }
        } else {
            if (arraySorted[index] === 0) {
                arraySorted.fill(0);
                arraySorted[index] = 1;
                arrow.style.transform = 'rotate(0deg)';
                array = compare(array, index);
            } else if (arraySorted[index] === 1) {
                arraySorted[index] = 2;
                arrow.style.transform = 'rotate(180deg)';
                array = compareReversed(array, index);
            } else {
                arraySorted[index] = 1;
                arrow.style.transform = 'rotate(0deg)';
                array = compare(array, index);
            }
        }
        array.forEach((event, index) => {
            if (event.status !== 0)
                array.push(array.splice(index, 1)[0]);
        });
        setIsArraySorted(arraySorted);
        setEventsFiltered(array);
    }

    const handleChangeStart = (e) => {
        const date = e.target.value;
        let [year, month, day] = formatDate(new Date(date)).split('-');

        year = year.padStart(4, "0");
        const formatedDate = [year, month, day].join('-');
        setDateFilter({ ...dateFilter, startDate: formatedDate });
    }

    const handleChangeEnd = (e) => {
        const date = e.target.value;
        let [year, month, day] = formatDate(new Date(date)).split('-');

        year = year.padStart(4, "0");
        const formatedDate = [year, month, day].join('-');
        setDateFilter({ ...dateFilter, endDate: formatedDate });
    }

    const handleChangeStatus = (e, cycle) => {
        const event = events.find(eventTmp => eventTmp.cycles.some(cycleTmp => cycleTmp.id === cycle.id));

        cycle.status = e.target.value;
        setNewEvents([...newEvents, cycle]);
        dispatch(eventActions.update(event.id, cycle.id, { status: e.target.value }));
    }

    const onClickFile = (cycle) => {
        const event = events.find(eventTmp => eventTmp.cycles.some(cycleTmp => cycle.id === cycleTmp.id));

        setEventSelected({ ...eventSelected, event: event, cycle: cycle });
        setOpen(true);
    }

    const onReserve = (cycleTmp) => {
        const eventTmp = events.find(event => event.cycles.some(cycle => cycle.id === cycleTmp.id));

        setEventSelected({ eventId: eventTmp.id, cycleId: cycleTmp.id, event: cycleTmp });
        setOpenReserve(true);
    }

    const columns = [
        { field: 'type', headerName: 'Type', style: { 'textTransform': 'capitalize' }, sortColumn: sortColumn },
        { field: 'label', headerName: 'Description', sortColumn: sortColumn },
        { field: 'start_date', headerName: 'Date', type: 'date', sortColumn: sortColumn },
        { field: 'frequency', headerName: 'Fréquence', sortColumn: sortColumn },
        { field: 'frequency_before_event', headerName: 'Fréquence du rappel pré-évènement', type:'fréquence_before', sortColumn: sortColumn },
        { field: 'frequency_after_event', headerName: 'Fréquence du rappel post-évènement', type: 'fréquence_after', sortColumn: sortColumn },
        { field: 'office', headerName: 'Site', style: { 'textTransform': 'uppercase' }, sortColumn: sortColumn },
        { field: 'priority', headerName: 'Priorité', type: 'priority', sortColumn: sortColumn },
        { field: 'proofs', headerName: 'Pièce jointe', type: 'proofs', sortColumn: sortColumn },
        { field: 'status', headerName: 'Statut', type: 'status', disabled: user, sortColumn: sortColumn },
        { field: 'reserveCount', headerName: 'Réserves', type: 'reserve', sortColumn: sortColumn }
    ];

    useEffect(() => {
        dispatch(eventActions.getAll(companyId, officeId));
    }, [dispatch, companyId, officeId]);

    useEffect(() => {
        if (eventReducer.action === 'event/updateSuccess')
            dispatch(eventActions.getAll(companyId, officeId));
        if (isEmpty(company))
            dispatch(companyActions.get(companyId));
        if (isEmpty(office))
            dispatch(officeActions.get(companyId, officeId));
    }, [dispatch, eventReducer, events, company, companyId, office, officeId]);

    useEffect(() => {
        let newEventsTmp = [];
        let eventsFilteredTmp = [];

        if ((eventReducer.action === 'event/getAllSuccess' ||
            !isEmpty(events)) && office.id) {
            events.forEach(event => {
                if (event.type !== 'reserve' && !isEmpty(event.cycles)) {
                    event.cycles.forEach(cycle => {
                        if (event.certification)
                            cycle.label =  event.certification.description + ' - ' + event.label;
                        else
                            cycle.label = event.label;
                        cycle.type = event.type;
                        cycle.frequency = event.frequency === 'none' ? 'Aucune' : event.frequency;
                        cycle.frequency_before_event = event.frequency_before_event === 0 ? 'Aucune' : event.frequency_before_event;
                        cycle.frequency_after_event = event.frequency_after_event === 0 ? 'Aucune' : event.frequency_after_event ;
                        cycle.office = office.label;
                        cycle.priority = event.priority;
                        cycle.author = event.author;
                        newEventsTmp.push(cycle);
                        if (new Date() >= new Date(cycle.start_date) &&
                            (new Date(office.contractDate.start) <= new Date(cycle.start_date) || cycle.status === 0))
                            eventsFilteredTmp.push(cycle);
                    });
                }
            });
            setDateFilter({
                startDate: formatDate(new Date(office.contractDate.start)),
                endDate: formatDate(new Date())
            });
            setEventsFiltered(eventsFilteredTmp);
            setNewEvents(newEventsTmp);
        }
    }, [eventReducer, events, officeReducer, office]);

    useEffect(() => {
        const priorities = ['high', 'medium', 'low'];
        let eventsFilteredTmp = [];

        for (let i = 0; i < newEvents.length; i++) {
            const event = newEvents[i];

            if (prioritySelected[`${priorities[event.priority - 1]}`]) {
                if (new Date(event.start_date).getTime() <= new Date(dateFilter.endDate).getTime() &&
                    (new Date(event.start_date).getTime() >= new Date(dateFilter.startDate).getTime() || event.status === 0)) {
                    Object.values(event).some((value) => {
                        if (typeof value === 'string' &&
                            value.toLowerCase().includes(searchedInput.toLocaleLowerCase())) {
                            eventsFilteredTmp.push(event);
                            return true;
                        } else
                            return false;
                    });
                    continue;
                } else
                    continue;
            } else
                continue;
        };
        setEventsFiltered(eventsFilteredTmp);
    }, [searchedInput, prioritySelected, dateFilter, newEvents]);

    return (
        <Grid container>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <div style={{ width: '100%' }}>
                {
                    !isEmpty(office)
                        ? <Header office={office} company={company} />
                        : <></>
                }
                <div style={{ textAlign: 'center' }}>
                    <Button
                        variant='outlined'
                        startIcon={<FormatListBulleted />}
                        component='div'
                        style={{ height: 60, width: 300, margin: '20px 0' }}
                        color='secondary'
                        onClick={onScheduler}
                    >
                        Afficher le calendrier
                    </Button>
                </div>
                <Grid style={{ width: '70%', margin: '0 auto 20px', textAlign: 'center' }}>
                    <TextField label='Rechercher...' variant='standard' fullWidth onKeyUp={(e) => setSearchedInput(e.target.value)} />
                    <div style={{ marginTop: 20 }}>
                        <Checkbox
                            icon={<Circle />}
                            defaultChecked={true}
                            checkedIcon={<Circle style={{ color: '#4BB543' }} />}
                            onClick={() => changePrioritySelected('low')}
                        />
                        <Checkbox
                            icon={<Circle />}
                            defaultChecked={true}
                            checkedIcon={<Circle style={{ color: '#FF7900' }} />}
                            onClick={() => changePrioritySelected('medium')}
                        />
                        <Checkbox
                            icon={<Circle />}
                            defaultChecked={true}
                            checkedIcon={<Circle style={{ color: '#cc0000' }} />}
                            onClick={() => changePrioritySelected('high')}
                        />
                    </div>
                    {
                        office.id
                            ? <div style={{ display: 'flex' }}>
                                <TextField
                                    variant="outlined"
                                    type='date'
                                    style={{ margin: '10px 20px' }}
                                    fullWidth={true}
                                    value={dateFilter.startDate}
                                    onChange={handleChangeStart}
                                />
                                <TextField
                                    variant="outlined"
                                    type='date'
                                    style={{ margin: '10px 20px' }}
                                    fullWidth={true}
                                    value={dateFilter.endDate}
                                    onChange={handleChangeEnd}
                                />
                            </div>
                            : <></>
                    }
                </Grid>
                <div>
                <CustomTable
                    columns={columns}
                    rows={eventsFiltered}
                    title="Liste des événements"
                    onCreate={user.role !== 'viewer' ? onCreate : null}
                    createLabel="Créer un événement"
                    onVisualize={onVisualize}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                    onExport={onExport}
                    onReserve={onReserve}
                    handleChangeStatus={handleChangeStatus}
                    setOpen={onClickFile}
                />
                </div>
                <AttachFileModal
                    open={open}
                    setOpen={setOpen}
                    event={eventSelected.event}
                    cycle={eventSelected.cycle}
                />
                <DeleteEvent
                    open={openDelete}
                    setOpen={setOpenDelete}
                    event={eventSelected.event}
                    cycle={eventSelected.cycle}
                />
                <ReserveModal
                    open={openReserve}
                    setOpen={setOpenReserve}
                    eventSelected={eventSelected}
                    office={office}
                    company={company}
                    user={user}
                />
            </div>
        </Grid>
    );
}
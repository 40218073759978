export default function canModif(event, user) {
    if (user.role === 'admin')
        return true;

    if (event.author && user) {
        if (user.role === 'viewer' ||
        (user.role === 'user' && event.author.id !== user.id))
            return false;
        else if (user.role === 'user' && event.author.id === user.id)
            return true;
    } else
        return false;
}
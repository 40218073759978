import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { reserveActions } from '@actions/reserve.actions';
import formatDate from '@helpers/formatDate';

export default function UpdateReserve({ setUpdate, reserve, eventIdSelected, cycleIdSelected }) {
    const { register, handleSubmit } = useForm();
    const { eventId, cycleId } = useParams();

    const dispatch = useDispatch();
    const reserveReducer = useSelector(state => state.reserveReducer);

    const onSubmit = (properties) => {
        properties.label = properties.label ?? reserve.label;
        properties.description = properties.description ?? reserve.description;
        dispatch(reserveActions.update(reserve.id, properties));
    }

    const onClick = () => setUpdate(false);

    const form = {
        title: "Modification de la réserve",
        lines: [
            [
                { label: 'Réserve', field: 'label', data: reserve.label}
            ],
            [
                { label: 'Description', field: 'description', data: reserve.description}
            ],
        ]
    }

    useEffect(() => {
        if (reserveReducer.action === 'reserve/updateSuccess') {
            dispatch(reserveActions.getAll(eventIdSelected ?? eventId, cycleIdSelected ?? cycleId));
            setUpdate(false);
        }
    }, [dispatch, setUpdate, reserveReducer, eventIdSelected, eventId, cycleIdSelected, cycleId]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={form.lines} register={register}/>
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center">
                                <SaveButton/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
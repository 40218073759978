import { IconButton } from '@material-ui/core';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

export const UpdateButton = ({ row, onUpdate }) => {
    if (!onUpdate)
        return <></>;

    return (
        <IconButton component="span" onClick={(e) => {onUpdate(row)}}>
            <ModeEditIcon />
        </IconButton>
    );
};
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import fileDownload from 'js-file-download';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import { makeStyles } from '@material-ui/styles';
import { eventActions } from '@actions/event.actions';

const useStyles = makeStyles(() => ({
    content: {
        fontSize: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    proof: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0'
    },
    button: {
        margin: '0 10px'
    }
}));

export default function AttachFileModal({ open, setOpen, event, cycle }) {
    const classes = useStyles();

    const [fileName, setFileName] = useState(undefined);

    const dispatch = useDispatch();
    const eventReducer = useSelector(state => state.eventReducer);

    const handleClose = () => setOpen(false);

    const handleDownload = (proof) => {
        setFileName(proof.originalname);
        dispatch(eventActions.getFile(event.id, cycle.id, proof.id));
    }

    useEffect(() => {
        if (eventReducer.action === 'event/getFileSuccess' &&
        fileName !== undefined) {
            dispatch(eventActions.clearSuccess());
            setFileName(undefined);
            fileDownload(eventReducer.file, fileName);
        }
    }, [eventReducer, dispatch, fileName]);

    if (!cycle || !event)
        return <></>;

    return (
        <Dialog open={open} onClose={handleClose} className={classes.dialog} maxWidth='md' fullWidth>
            <DialogTitle>
                Pièce(s) justificative(s)
            </DialogTitle>
            <DialogContent className={classes.content}>
                {
                    cycle.proofs.map(proof => {
                        return <div className={classes.proof} key={proof.id}>
                            <h2>{proof.originalname}</h2>
                            <IconButton color="secondary" component="span" onClick={() => handleDownload(proof)}>
                                <DownloadIcon />
                            </IconButton>
                        </div>
                    })
                }
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <div>
                    <Button className={classes.button} color='secondary' onClick={handleClose}>Retour</Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
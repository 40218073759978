import { reserveService } from '@services/reserve.services';
import { alertActions } from './alert.actions';

export const reserveActions = {
    create,
    update,
    remove,
    getAll,
    clearSuccess
};

function create(eventId, cycleId, properties) {
    const request = () => ({ type: 'reserve/createRequest' });
    const success = (reserve) => ({ type: 'reserve/createSuccess', reserve });
    const failure = (error) => ({ type: 'reserve/createFailure', error });

    return dispatch => {
        dispatch(request());
        reserveService.create(eventId, cycleId, properties)
            .then(reserve => {
                dispatch(success(reserve));
                dispatch(alertActions.success("La réserve a bien été créée."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("La description ne peut pas excéder les 255 caractères."));
            });
    };
}

function update(eventId, properties) {
    const request = () => ({ type: 'reserve/updateRequest' });
    const success = (reserve) => ({ type: 'reserve/updateSuccess', reserve });
    const failure = (error) => ({ type: 'reserve/updateFailure', error });

    return dispatch => {
        dispatch(request());
        reserveService.update(eventId, properties)
            .then(reserve => {
                dispatch(success(reserve));
                dispatch(alertActions.success("L'événement a bien été édité."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche l'édition de cet événement."));
            });
    };
}

function remove(eventId) {
    const request = () => ({ type: 'reserve/removeRequest' });
    const success = (id) => ({ type: 'reserve/removeSuccess', id });
    const failure = (error) => ({ type: 'reserve/removeFailure', error });

    return dispatch => {
        dispatch(request());
        reserveService.remove(eventId)
            .then(() => {
                dispatch(success(eventId));
                dispatch(alertActions.success("La réserve a bien été supprimée."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la suppression de cette réserve."));
            });
    };
}

function getAll(eventId, cycleId) {
    const request = () => ({ type: 'reserve/getAllRequest' });
    const success = (reserves) => ({ type: 'reserve/getAllSuccess', reserves });
    const failure = (error) => ({ type: 'reserve/getAllFailure', error });

    return dispatch => {
        dispatch(request());
        reserveService.getAll(eventId, cycleId)
            .then(reserves => dispatch(success(reserves)))
            .catch(error => dispatch(failure(error)));
    };
}

function clearSuccess() {
    return { type: 'reserve/clearSuccess' };
}
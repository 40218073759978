import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {Grid, Typography, Button, Checkbox} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { officeActions } from '@actions/office.actions';
import Capitals from './Capitals';

export default function UpdateSite(props) {
    const { register, handleSubmit, reset } = useForm();
    const history = useHistory();

    const dispatch = useDispatch();
    const office = useSelector(state => state.officeReducer);

    const companyInfos = props.location.state.company;
    const officeInfos = props.location.state.office;

    const [keepData, setKeepData] = useState({
        ...officeInfos,
        contractDate: {
            start: new Date(officeInfos.contractDate.start).toISOString().split('T')[0],
            end: new Date(officeInfos.contractDate.end).toISOString().split('T')[0]
        }
    });
    const [logoData, setLogoData] = useState(null);
    const [pictureData, setPictureData] = useState(null);

    const [main, setMain] = useState(officeInfos.main);
    const [owner, setOwner] = useState(officeInfos.owner);
    const [isCompanyOffice, setIsCompanyOffice] = useState(officeInfos.isCompanyOffice);
    const [showGoods, setShowGoods] = useState(false);

    const handleGoodsCheckboxChange = (event) => {
        setShowGoods(event.target.checked);
    };
    const form = {
        title: `Modification de ${officeInfos.name}`,
        lines: [
            [
                { label: 'Site', field: 'label', data: keepData.label },
                { label: 'Siège social', field: 'main', type: 'boolean', data: main, setData: (e) => setMain(e.target.checked) },
                { label: 'Site principale', field: 'owner', type: 'boolean', data: owner, setData: (e) => setOwner(e.target.checked) },
                { label: 'Site secondaire', field: 'isCompanyOffice', type: 'boolean', data: isCompanyOffice, setData: (e) => setIsCompanyOffice(e.target.checked) },
            ],
            [
                { label: 'Début du contrat d\'assurance', field: 'contractDate.start', type: 'date', data: keepData.contractDate.start },
                { label: 'Fin d\'échéance', field: 'contractDate.end', type: 'date', data: keepData.contractDate.end }
            ],
        ]
    }

    const addressForm = {
        lines: [
            [
                { label: 'Numéro de rue', field: 'address.street_number', data: keepData.address.street_number },
                { label: 'Nom de rue', field: 'address.street_name', data: keepData.address.street_name },
                { label: 'Code postal', field: 'address.zip_code', data: keepData.address.zip_code },
            ],
            [
                { label: 'Ville', field: 'address.city', data: keepData.address.city },
                { label: 'Pays', field: 'address.country', data: keepData.address.country },
            ],
        ]
    }

    const contactForm = {
        lines: [
            [
                { label: 'Nom de la personne de contact', field: 'contactPerson.lastname', data: keepData.contactPerson.lastname },
                { label: 'Prénom de la personne de contact', field: 'contactPerson.firstname', data: keepData.contactPerson.firstname },
            ],
            [
                { label: 'Téléphone de la personne de contact', field: 'contactPerson.phone', data: keepData.contactPerson.phone },
                { label: 'E-mail de la personne de contact', field: 'contactPerson.email', data: keepData.contactPerson.email },
                { label: 'Role de la personne de contact', field: 'contactPerson.job', data: keepData.contactPerson.job }
            ],
        ]
    }

    const accountingForm = {
        lines: [

            [
                { label: 'Nom de la personne de comptabilité', field: 'accountingPerson.lastname', data: keepData?.accountingPerson?.lastname },
                { label: 'Prénom de la personne de comptabilité', field: 'accountingPerson.firstname', data: keepData?.accountingPerson?.firstname }
            ],
            [
                { label: 'Téléphone de la personne de comptabilité', field: 'accountingPerson.phone', data: keepData?.accountingPerson?.phone },
                { label: 'E-mail de la personne de comptabilité', field: 'accountingPerson.email', data: keepData?.accountingPerson?.email },
                { label: 'Role de la personne de comptabilité', field: 'accountingPerson.job', data: keepData?.accountingPerson?.job }
            ],
            [
                { label: 'Email de contact pour la facturation', field: 'billingEmail', type: 'email', data: keepData.billingEmail },
            ]
        ]
    }

    const depositForm = {
        lines: [
            [
                { label: 'Description', field: 'merchandiseWarehouse', type: 'string', data: keepData.merchandiseWarehouse },
            ],
            [
                { label: 'Taux annuel TTC (‰)', field: 'rate', data: keepData.rate },
                { label: 'Limite de capitaux des marchandises assurées', field: 'limitAmountOfCapitals', data: keepData.limitAmountOfCapitals },
            ],
            [
                { label: 'Numéro de contrat assurance dommage', field: 'damageInsuranceNumber', data: keepData.damageInsuranceNumber },
                { label: 'Nom compagnie pour Assurance Dommage ', field: 'damageInsuranceName', data: keepData.damageInsuranceName },
            ],
            [
                { label: 'Numéro de RC', field: 'rcNumber', data: keepData.rcNumber },
                { label: 'Nom compagnie pour RC ', field: 'rcName', data: keepData.rcName },
            ],
        ]
    }

    const formLogo = {
        title: 'Ajouter un logo',
        lines: [
            [
                { label: `Logo`, field: `file`, type: 'file', data: logoData }
            ]
        ]
    }

    const formPicture = {
        title: 'Ajouter une photo',
        lines: [
            [
                { label: `Photo`, field: `file`, type: 'file', data: pictureData }
            ]
        ]
    }

    const onSubmit = (properties) => {
        if (
            properties.contactPerson.lastname !== '' &&
            properties.contactPerson.firstname !== '' &&
            properties.contactPerson.phone !== '' &&
            properties.contactPerson.email !== '' &&
            properties.contactPerson.job !== '' &&
            properties.address.street_number !== '' &&
            properties.address.street_name !== '' &&
            properties.address.zip_code !== '' &&
            properties.address.city !== '' &&
            properties.address.country !== ''
        ) {
            if (!showGoods) {
                properties.rate = properties.rate ?? 0;
                properties.limitAmountOfCapitals = properties.limitAmountOfCapitals ?? 0;
                properties.accountingPerson = {
                    lastname: '',
                    firstname: '',
                    phone: '',
                    email: '',
                    job: ''
                };
            } else {
                properties.rate = parseInt(properties.rate);
                properties.limitAmountOfCapitals = parseInt(properties.limitAmountOfCapitals) || 0;
            }
            properties.label = properties.label ?? keepData.label;
            properties.main = main;
            properties.owner = owner;
            properties.isCompanyOffice = isCompanyOffice;
            // properties.contactPerson.lastname = properties.contactPerson.lastname ?? keepData.contactPerson.lastname;
            // properties.contactPerson.firstname = properties.contactPerson.firstname ?? keepData.contactPerson.firstname;
            // properties.contactPerson.phone = properties.contactPerson.phone ?? keepData.contactPerson.phone;
            // properties.contactPerson.email = properties.contactPerson.email ?? keepData.contactPerson.email;
            // properties.contactPerson.job = properties.contactPerson.job ?? keepData.contactPerson.job;
            properties.contactPerson.lastname = properties.contactPerson.lastname !== '' ? properties.contactPerson.lastname : keepData.contactPerson.lastname;
            properties.contactPerson.firstname = properties.contactPerson.firstname !== '' ? properties.contactPerson.firstname : keepData.contactPerson.firstname;
            properties.contactPerson.phone = properties.contactPerson.phone !== '' ? properties.contactPerson.phone : keepData.contactPerson.phone;
            properties.contactPerson.email = properties.contactPerson.email !== '' ? properties.contactPerson.email : keepData.contactPerson.email;
            properties.contactPerson.job = properties.contactPerson.job !== '' ? properties.contactPerson.job : keepData.contactPerson.job;

            properties.address.street_number = properties.address.street_number ?? keepData.address.street_number;
            properties.address.street_name = properties.address.street_name ?? keepData.address.street_name;
            properties.address.zip_code = properties.address.zip_code ?? keepData.address.zip_code;
            properties.address.city = properties.address.city ?? keepData.address.city;
            properties.address.country = properties.address.country ?? keepData.address.country;
            properties.contractDate.start = properties.contractDate.start ?? keepData.contractDate.start;
            properties.contractDate.end = properties.contractDate.end ?? keepData.contractDate.end;
            properties.rate = parseInt(properties.rate);
            properties.limitAmountOfCapitals = parseInt(properties.limitAmountOfCapitals) ?? 0;
            properties.billingEmail = properties.billingEmail ?? '';
            properties.merchandiseWarehouse = properties.merchandiseWarehouse ?? '';
            properties.rcName = properties.rcName ?? keepData.rcName;
            properties.rcNumber = properties.rcNumber ?? keepData.rcNumber;
            properties.damageInsuranceNumber = properties.damageInsuranceNumber ?? keepData.damageInsuranceNumber;
            properties.damageInsuranceName = properties.damageInsuranceName ?? keepData.damageInsuranceName;


            dispatch(officeActions.update(companyInfos.id, officeInfos.id, properties));
            setKeepData(properties);
            reset({});
        }
    };


    const onSubmitDocument = (type, fileData) => {
        const data = new FormData();

        data.append('file', fileData);
        dispatch(officeActions.uploadDocument(companyInfos.id, officeInfos.id, type, data));
    }

    const onDeleteDocument = (type, fileId) => {
        dispatch(officeActions.deleteDocument(companyInfos.id, officeInfos.id, fileId, type));
    }

    const handleLogo = (e) => {
        setLogoData(e.target.files[0]);
    }

    const handlePicture = (e) => {
        setPictureData(e.target.files[0]);
    }

    const onClick = () => {
        history.goBack();
    }

    useEffect(() => {
        if (officeInfos.logo)
            dispatch(officeActions.getDocument(companyInfos.id, officeInfos.id, officeInfos.logo.id, 'logo'));
        if (officeInfos.picture)
            dispatch(officeActions.getDocument(companyInfos.id, officeInfos.id, officeInfos.picture.id, 'picture'));
    }, [officeInfos, dispatch, companyInfos]);

    useEffect(() => {
        if (office.action === 'office/updateSuccess' ||
            office.action === 'office/uploadDocumentSuccess') {
            dispatch(officeActions.clearSuccess());
            history.goBack();
        } else if (office.action === 'office/getDocumentSuccess')
            dispatch(officeActions.clearSuccess());
    }, [office, dispatch, history]);

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container style={{ margin: '50px 0' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    Modification de {officeInfos.label}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">

                            <Grid item xs={10}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <CustomForm title={''} lines={form.lines} register={register} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CustomForm title={'Adresse'} lines={addressForm.lines} register={register} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <CustomForm title={'Contact'} lines={contactForm.lines} register={register} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Checkbox checked={showGoods} onChange={handleGoodsCheckboxChange} />
                                        <label>Afficher la partie Marchandises pour compte de Tiers et Comptabilité</label>
                                    </Grid>
                                    {showGoods && (
                                        <Grid item xs={12}>
                                            <CustomForm title={'Comptabilité'} lines={accountingForm.lines} register={register} />
                                        </Grid>
                                    )}
                                    {showGoods && (
                                        <Grid item xs={12}>
                                            <CustomForm title={'Marchandises'} lines={depositForm.lines} register={register} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item xs={10} style={{ marginTop: 50 }}>
                                <Grid container justifyContent="center">
                                    <SaveButton />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            {office.logo instanceof Blob
                ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={window.URL.createObjectURL(office.logo)} alt='Logo du site' height={300} />
                    <Button
                        onClick={() => onDeleteDocument('logo', officeInfos.logo.id)}
                        size="large"
                        variant='outlined'
                        style={{ color: '#D11A2A', margin: '50px 0' }}
                        startIcon={<DeleteForeverIcon />}
                        type="submit"
                    >
                        SUPPRIMER
                    </Button>
                </div>
                : <form onSubmit={handleSubmit(() => onSubmitDocument('logo', logoData))}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {formLogo.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ margin: '0px 0' }}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" style={{ textAlign: 'center' }}>
                                <Grid item xs={10}>
                                    <CustomForm title={''} lines={formLogo.lines} register={register} handleFileChanged={handleLogo} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ margin: 50 }}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                    <Button size="large" variant='outlined' color='secondary' startIcon={<Send />} type="submit">
                                        METTRE EN LIGNE
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>}
            {office.picture instanceof Blob
                ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={window.URL.createObjectURL(office.picture)} alt='Visuel du site' height={300} />
                    <Button
                        onClick={() => onDeleteDocument('picture', officeInfos.picture.id)}
                        size="large"
                        variant='outlined'
                        style={{ color: '#D11A2A', margin: '50px 0' }}
                        startIcon={<DeleteForeverIcon />}
                        type="submit"
                    >
                        SUPPRIMER
                    </Button>
                </div>
                : <form onSubmit={handleSubmit(() => onSubmitDocument('picture', pictureData))}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {formPicture.title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ margin: '0px 0' }}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" style={{ textAlign: 'center' }}>
                                <Grid item xs={10}>
                                    <CustomForm title={''} lines={formPicture.lines} register={register} handleFileChanged={handlePicture} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ margin: 50 }}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                    <Button size="large" variant='outlined' color='secondary' startIcon={<Send />} type="submit">
                                        METTRE EN LIGNE
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>}

            <div style={{ flex: true, justifyContent: 'center' }}>
                <Grid container spacing={5}>
                    <Grid xs={12}>
                        <Capitals officeId={officeInfos.id} office={office} />
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import { Button } from '@material-ui/core';
import { CloudDownloadOutlined } from '@material-ui/icons';
import CustomTable from '@components/Table/CustomTable';
import { officeActions } from '@actions/office.actions';
import { stringToLocalDate } from '@helpers/formatDate';
import FirePermits from '@assets/Template/fire_permits.pdf';
import Header from '../Header/Header';
import OfficeSubAppBar from '../Office';

const columns = [
    { field: 'originalname', headerName: 'Nom du fichier', align: 'center'},
    { field: 'file', headerName: 'Fichier', align: 'left', type: 'officeFile'},
    { field: 'created_at', headerName: 'Date de mise en ligne', align: 'left'}
];

export default function Fire() {
    const history = useHistory();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();
    const fileInfos = useSelector(state => state.officeReducer.file);
    const officeReducer = useSelector(state => state.officeReducer);
    const fire_permits = useSelector(state => state.officeReducer.office.fire_permits);
    const user = useSelector(state => state.userReducer.userInfos);

    const [fileName, setFileName] = useState(undefined);
    const [files, setFiles] = useState([]);

    const onCreate = () => {
        history.push(`/companies/${companyId}/offices/${officeId}/fire-permits/create`);
    }

    const onDelete = (file) => {
        let newFileArray = [...files];

        setFiles(newFileArray.filter(everyFile => everyFile.id !== file.id));
        dispatch(officeActions.deleteDocument(companyId, officeId, file.id, 'fire_permits', ));
    }

    const handleDownload = (file) => {
        dispatch(officeActions.getDocument(companyId, officeId, file.id, 'fire_permits'));
        setFileName(file.originalname);
    };

    const handleDownloadTemplate = () => {
        let link = document.createElement('a');

        link.setAttribute('href', FirePermits);
        link.setAttribute('download', 'template_fire_permits_IBS.pdf');
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        dispatch(officeActions.get(companyId, officeId));
    }, [dispatch, companyId, officeId]);

    useEffect(() => {
        let filesTmp = [];

        fire_permits.forEach(file => {
            let limiteDate = new Date(file.created_at);

            file.created_at = stringToLocalDate(file.created_at);
            file.fileDate = stringToLocalDate(file.comment);
            limiteDate.setDate(limiteDate.getDate() + 1);
            if (user.role === 'admin')
                file.canDelete = true;
            else
                file.canDelete = limiteDate > new Date();
            filesTmp.push(file);
        });
        setFiles(filesTmp);
    }, [fire_permits, user]);

    useEffect(() => {
        if (officeReducer.action === 'office/getDocumentSuccess'
        && fileName !== undefined) {
            fileDownload(fileInfos, fileName);
            setFileName(undefined)
            dispatch(officeActions.clearSuccess());
        }
    }, [officeReducer, fileName, fileInfos, dispatch]);

    return (
        <div height="800">
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <div style={{ textAlign: 'center' }}>
                <Button
                    variant='outlined'
                    startIcon={<CloudDownloadOutlined />}
                    component='div'
                    style={{ height: 60, width: 250 }}
                    color='secondary'
                    onClick={handleDownloadTemplate}
                >
                    Modèle
                </Button>
            </div>
            <CustomTable
                columns={columns}
                rows={files}
                title="Liste des permis de feu"
                onCreate={user.role !== 'viewer' ? onCreate : null}
                createLabel="Ajouter un permis de feu"
                onDelete={user.role !== 'viewer' ? onDelete : null}
                handleDownload={handleDownload}
            />
        </div>
    );
}
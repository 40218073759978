import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { officeActions } from '@actions/office.actions';
import OfficeSubAppBar from '../Office';
import Header from '../Header/Header';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SaveIcon from '@mui/icons-material/Save';
import { alertActions } from '../../../actions/alert.actions';
import DepositForm from './DepositForm';

export default function NewDeposit() {
    const { register, handleSubmit } = useForm();
    const { companyId, officeId } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const officeReducer = useSelector(state => state.officeReducer);
    const rate = officeReducer.office.rate;

    const [option, setOption] = useState(undefined);
    const newDeposit = {
        filingCompany: "",
        merchandiseType: "",
        billing: "",
        startingDate: new Date(),
        endingDate: new Date(),
        previewBilling: "",
        previewMensualBilling: "",
        type: null,
        quantity: "",
        applicant: {
            email: "",
            firstName: "",
            lastName: '',
            phone: '',
            job: ''
        }
    }

    useEffect(() => {
        if (officeReducer.action === 'office/createDepositsSuccess') {
            history.goBack();
        }
    }, [history, officeReducer, dispatch])

    const onSubmit = (properties) => {
        const type = ["Renonciation à recours", "Comptabilité", "Ne sais pas"]

        if (option === 1 && !properties.billing) {
            dispatch(alertActions.error('L\'envois à la comptabilité nécessite le montant.'))
        } else {
            newDeposit.quantity = properties.quantity ?? ""
            newDeposit.filingCompany = properties.filingCompany ?? undefined
            newDeposit.merchandiseType = properties.merchandiseType ?? undefined

            if (properties.billing) {
                newDeposit.billing = parseInt(properties.billing.replace(/\s/g, ''));
            }

            newDeposit.startingDate = properties.startingDate
            newDeposit.endingDate = properties.endingDate
            newDeposit.type = type[option] ?? "unknown"
            newDeposit.quantity = properties.quantity ?? ""
            newDeposit.applicant = {
                email: properties.applicant.email,
                lastname: properties.applicant.lastname,
                firstname: properties.applicant.firstname,
                phone: properties.applicant.phone,
                job: properties.applicant.job,
            }

            dispatch(officeActions.createDeposit(companyId, officeId, newDeposit));
        }
    }

    const onClick = () => history.goBack();

    return (
        <div>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {"Ajout d'une marchandise"}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '50px 0' }}>
                        <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={11} style={{ textAlign: 'center' }}>
                                <DepositForm register={register} deposit={newDeposit} rate={rate} />
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 50 }}>
                                <Grid container justifyContent="center" spacing={2} direction="row">
                                    <Grid item>
                                        <Button onClick={() => { setOption(0) }}>
                                            <Button size="large" color='secondary' startIcon={<FilePresentIcon />} type="submit" variant='outlined'>
                                                Renonciation à recours
                                            </Button>
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button onClick={() => { setOption(1) }}>
                                            <Button size="large" color='secondary' startIcon={<AccountBalanceWalletIcon />} type="submit" variant='outlined'>
                                                Envois à la comptabilité
                                            </Button>
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button onClick={() => { setOption(2) }}>
                                            <Button size="large" color='secondary' startIcon={<SaveIcon />} type="submit" variant='outlined'>
                                                Je ne sais pas
                                            </Button>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
import { Api } from '@config/api';

export const billService = {
    create,
    update,
    remove,
    getAll,
    getFile
};

function create(eventId, cycleId, properties) {
    return Api.post(`/reminders/${eventId}/cycles/${cycleId}/bills`, properties).then(handleResponse);
}

function update(eventId, cycleId, billId, properties) {
    return Api.patch(`/reminders/${eventId}/cycles/${cycleId}/bills/${billId}`, properties).then(handleResponse);
}

function remove(eventId, cycleId, billId) {
    return Api.delete(`/reminders/${eventId}/cycles/${cycleId}/bills/${billId}`).then(handleResponse);
}

function getAll(eventId, cycleId) {
    return Api.get(`/reminders/${eventId}/cycles/${cycleId}/bills`).then(handleResponse);
}

function getFile(eventId, cycleId, billId) {
    return Api.get(`/reminders/${eventId}/cycles/${cycleId}/bills/${billId}/file`, { responseType: 'blob' }).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;

        return Promise.reject(error);
    }
}
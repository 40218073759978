import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function FreeSoloCreateOption({ options, label, setData, value }) {

    return (
        <Autocomplete
            value={value}
            autoSelect={true}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setData(newValue);
                } else if (newValue && newValue.inputValue) {
                    setData(newValue.inputValue);
                } else {
                    setData(newValue);
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.label);

                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        inputValue,
                        label: ` "${inputValue}"`,
                    });
                }

                return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={options}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option.label;
            }}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            fullwidth
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={label} />
            )}
        />
    );
}
import { certificationService } from '@services/certification.services';
import { alertActions } from './alert.actions';

export const certificationActions = {
    create,
    update,
    getAll,
    clearSuccess
};

function create(properties) {
    const request = () => ({ type: 'certification/createRequest' });
    const success = (certification) => ({ type: 'certification/createSuccess', certification });
    const failure = (error) => ({ type: 'certification/createFailure', error });

    return dispatch => {
        dispatch(request());
        certificationService.create(properties)
            .then(certification => {
                dispatch(success(certification));
                dispatch(alertActions.success(`La certification ${certification.label} a bien été créée.`));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche la création de la certification."));
            });
    };
}

function update(id, properties) {
    const request = () => ({ type: 'certification/updateRequest' });
    const success = () => ({ type: 'certification/updateSuccess' });
    const failure = (error) => ({ type: 'certification/updateFailure', error });

    return dispatch => {
        dispatch(request());
        certificationService.update(id, properties)
            .then(() => {
                dispatch(success());
                dispatch(alertActions.success("La certification a bien été éditée."));
            }).catch(error => {
                dispatch(failure(error));
                dispatch(alertActions.error("Une erreur empêche l'édition de la certification."));
            });
    };
}

function getAll() {
    const request = () => ({ type: 'certification/getAllRequest' });
    const success = (certifications) => ({ type: 'certification/getAllSuccess', certifications });
    const failure = (error) => ({ type: 'certification/getAllFailure', error });

    return dispatch => {
        dispatch(request());
        certificationService.getAll()
            .then(certifications => dispatch(success(certifications)))
            .catch(error => dispatch(failure(error)));
    };
}

function clearSuccess() {
    return { type: 'certification/clearSuccess' };
}
import React from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { alertActions } from '@actions/alert.actions';

export default function BasicAlert({ open, setOpen, type, message }) {
    const dispatch = useDispatch();

    const handleClose = () => {
        setOpen(false);
        dispatch(alertActions.clear());
    };

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={3000}
            anchorOrigin={ { vertical: 'top', horizontal: 'right' } }>
            <Alert
                severity={type}
                action={
                    <IconButton
                        aria-label='close'
                        color='inherit'
                        size='small'
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        <Close />
                    </IconButton>
                }
            >
                <AlertTitle>{message}</AlertTitle>
            </Alert>
        </Snackbar>
    );
}
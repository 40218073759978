import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import { CustomForm } from '@components/Form/CustomForm';
import { userActions } from '@actions/user.actions';

export default function Informations() {
    const { register, handleSubmit, reset } = useForm();

    const dispatch = useDispatch();

    const form = {
        title: "Nous contacter",
        lines: [
            [
                { label: `Sujet de l'e-mail`, field: `subject` }
            ],
            [
                { label: `Message...`, field: `message`, type: 'message' }
            ]
        ]
    }

    const onSubmit = (properties) => {
        dispatch(userActions.contact(properties));
        reset({});
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container style={{ margin: '50px 0' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={11}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={10}>
                            <CustomForm title={''} lines={form.lines} register={register}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ margin: 50 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Button size="large" variant='outlined' color='secondary' startIcon={<SendIcon />} type="submit">
                                ENVOYER
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
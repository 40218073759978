import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { stringToLocalDate } from '@helpers/formatDate';
import { eventActions } from '@actions/event.actions';

const useStyles = makeStyles(() => ({
    content: {
        fontSize: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialogContent: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    deleteButton: {
        color: '#ff0021'
    },
    button: {
        margin: '0 10px'
    }
}));

export default function DeleteEvent({ open, setOpen, event, cycle }) {
    const classes = useStyles();
    const { companyId, officeId } = useParams();

    const dispatch = useDispatch();
    const eventReducer = useSelector(state => state.eventReducer);

    const [deleteRec, setDeleteRec] = useState(false);
    const [deleteEvent, setDeleteEvent] = useState(false);

    const handleClose = () => {
        if (deleteRec)
            setDeleteRec(false);
        else if (deleteEvent)
            setDeleteEvent(false);
        else
            setOpen(false);
    }

    const handleDeleteRec = () => {
        dispatch(eventActions.remove(event.id, cycle.id));
    }

    const handleDeleteEvent = () => {
        let startDate = new Date();
        let endDate = new Date(Math.max(...event.cycles.map(cycle => new Date(cycle.start_date))));

        dispatch(eventActions.removeAllNext(event.id, { startDate, endDate }));
    }

    useEffect(() => {
        if (eventReducer.action === 'event/removeSuccess' ||
        eventReducer.action === 'event/removeAllNextSuccess')
            dispatch(eventActions.getAll(companyId, officeId));
    }, [eventReducer, dispatch, companyId, officeId]);

    useEffect(() => {
        if (eventReducer.action === 'event/getAllSuccess') {
            dispatch(eventActions.clearSuccess());
            setDeleteRec(false);
            setDeleteEvent(false);
            setOpen(false);
        }
    }, [dispatch, eventReducer, setOpen]);

    return (
        <Dialog open={open} onClose={handleClose} className={classes.dialog} maxWidth='md' fullWidth>
            <DialogTitle>
                {
                    deleteRec
                    ? 'Supression de l\'événement sélectionné'
                    : deleteEvent
                        ? 'Supression de l\'événement sélectionné et des événements récurrents à venir'
                        : 'Que souhaitez vous supprimer ?'
                }
            </DialogTitle>
            <DialogContent>
                {
                    deleteRec
                    ? <h2 className={classes.dialogContent}>Voulez-vous supprimer la récurrence du {stringToLocalDate(cycle.start_date)} de l'événement {cycle.label} ?</h2>
                    : deleteEvent
                        ? <h2 className={classes.dialogContent}>Voulez-vous supprimer toutes les récurrences futures de l'événement {cycle.label} ?</h2>
                        : ''
                }
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <div>
                    <Button className={classes.button} color='secondary' onClick={handleClose}>Retour</Button>
                </div>
                <div>
                    {
                        deleteRec
                        ? <Button className={classes.deleteButton} variant='outlined' onClick={handleDeleteRec}>Supprimer</Button>
                        : deleteEvent
                            ? <Button className={classes.deleteButton} variant='outlined' onClick={handleDeleteEvent}>Supprimer</Button>
                            : <>
                                <Button className={classes.button} color='secondary' variant='outlined' onClick={() => setDeleteRec(true)}>l'événement sélectionné</Button>
                                <Button className={classes.button} color='secondary' variant='outlined' onClick={() => setDeleteEvent(true)}>l'événement sélectionné et les événements récurrents à venir</Button>
                            </>
                    }
                </div>
            </DialogActions>
        </Dialog>
    );
}
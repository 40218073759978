import { Api } from '@config/api';

export const certificationService = {
    create,
    update,
    getAll
};

function create(properties) {
    return Api.post('/certifications', properties).then(handleResponse);
}

function update(id, properties) {
    return Api.patch(`/certifications/${id}`, properties).then(handleResponse);
}

function getAll() {
    return Api.get('/certifications').then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;

        return Promise.reject(error);
    }
}
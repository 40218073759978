import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { userActions } from '@actions/user.actions';

const useStyles = makeStyles(() => ({
    content: {
        fontSize: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dialogContent: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    deleteButton: {
        margin: '0 10px',
        color: '#ff0021'
    },
    button: {
        margin: '0 10px'
    }
}));

export default function DeleteUser({ open, setOpen, user }) {
    const classes = useStyles();

    const dispatch = useDispatch();
    const userReducer = useSelector(state => state.userReducer);

    const handleClose = () => setOpen(false);

    const handleDelete = () => {
        dispatch(userActions.remove(user.id));
    }

    useEffect(() => {
        if (userReducer.action === 'user/removeSuccess') {
            dispatch(userActions.clearSuccess());
            setOpen(false);
        }
    }, [userReducer, dispatch, setOpen]);

    return (
        <Dialog open={open} onClose={handleClose} className={classes.dialog} maxWidth='md' fullWidth>
            <DialogTitle>
                Confirmation de suppression
            </DialogTitle>
            <DialogContent>
                <h2>Souhaitez-vous réellement supprimer l'utilisateur {user.lastname} {user.firstname}</h2>
            </DialogContent>
            <DialogActions className={classes.buttonContainer}>
                <Button className={classes.button} color='secondary' onClick={handleClose}>Retour</Button>
                <Button className={classes.deleteButton} color='secondary' variant='outlined' onClick={handleDelete}>Supprimer</Button>
            </DialogActions>
        </Dialog>
    );
}
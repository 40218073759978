import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { billActions } from '@actions/bill.actions';

export const UpdateBill = ({ setUpdate, eventId, cycleId, bill }) => {
    const { register, handleSubmit } = useForm();

    const dispatch = useDispatch();
    const billReducer = useSelector(state => state.billReducer);

    // eslint-disable-next-line
    const [fileData, setFileData] = useState(null);
    const [keepData, setKeepData] = useState({
        company_intervening_label: bill.company_intervening.label,
        bill: bill.bill
    });

    let keepDataChanged = {
        company_intervening_label: keepData.company_intervening_label,
        bill: keepData.bill
    };

    const onSubmit = (properties) => {
        properties.bill = properties.bill ?? keepDataChanged.bill;
        properties.company_intervening_label = properties.company_intervening_label ?? keepDataChanged.company_intervening_label;
        const updateBill = {
            company_intervening: {
                label: properties.company_intervening_label
            },
            bill: properties.bill
        }
        dispatch(billActions.update(eventId, cycleId, bill.id, updateBill));
        setKeepData(keepDataChanged);
    }

    const handleFileChanged = (e) => {
        setFileData(e.target.files[0]);
        setKeepData(keepDataChanged);
    }

    const handleChange = (e) => {
        keepDataChanged = { ...keepDataChanged, [e.target.name]: e.target.value };
    }

    const onClick = () => setUpdate(false);

    const form = {
        title: "Modification de la facture",
        lines: [
            [
                { label: 'Société', field: 'company_intervening_label', data: keepDataChanged.company_intervening_label }
            ],
            [
                { label: 'Facture (en €)', field: 'bill', data: keepDataChanged.bill }
            ]
        ]
    }

    useEffect(() => {
        if (billReducer.action === 'bill/updateSuccess') {
            setUpdate(false);
        }
    }, [dispatch, setUpdate, billReducer]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                {form.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={4}>
                        <Grid item xs={10} style={{ textAlign: 'center' }}>
                            <CustomForm title={''} lines={form.lines} register={register} handleFileChanged={handleFileChanged} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={10}>
                            <Grid container justifyContent="center" >
                                <SaveButton />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion } from '@mui/material';
import { AccordionDetails, AccordionSummary, Grid, Toolbar, Typography, IconButton, Button } from '@material-ui/core';
import { ExpandMore, Add } from '@material-ui/icons';
import Popover from '@mui/material/Popover';
import { Apartment, Explore, LocalFireDepartment } from '@mui/icons-material';

import { Buttons } from '../Button/Buttons';
import { VisualizeButton } from '../Button/VisualizeButton';
import { UpdateButton } from '../Button/UpdateButton';
import {SwitchButton} from "../Button/SwitchButton";

const useStyles = makeStyles({
    root: {
        '&:Mui-disabled': {
            pointerEvents: 'auto'
        }
    },
    gridAlign: {
        margin: 'auto'
    }
});

const columnsCompanies = [
    { field: 'name', headerName: 'Société', size: 2, style: { 'textTransform': 'uppercase' } },
    { field: 'contactPerson.lastname', headerName: 'Contact', size: 1, style: { 'textTransform': 'uppercase' } },
    { field: 'contactPerson.firstname', headerName: '', size: 1, style: { 'textTransform': 'capitalize' } },
    { field: 'contactPerson.job', headerName: 'Fonction', size: 2 },
    { field: 'contactPerson.email', headerName: 'E-mail', size: 2, style: { 'textTransform': 'lowercase' } },
    { field: 'contactPerson.phone', headerName: 'Téléphone', size: 2 },
    { field: '', headerName: 'Actions', type: 'actions', size: 2 },
];

const columnsOffices = [
    { field: 'label', headerName: 'Office', size: 2, style: { 'textTransform': 'uppercase' } },
    { field: 'contactPerson.lastname', headerName: 'Contact', size: 1, style: { 'textTransform': 'uppercase' } },
    { field: 'contactPerson.firstname', headerName: '', size: 1, style: { 'textTransform': 'capitalize' } },
    { field: 'contactPerson.job', headerName: 'Fonction', size: 2 },
    { field: 'location', headerName: 'Adresse', size: 3, style: { 'textTransform': 'capitalize' } },
    { field: 'icons', headerName: 'Fichiers', size: 2, type: 'officeIcons' },
    { field: '', headerName: 'Actions', size: 1, type: 'actions' }
];

function CustomColumnsAccordion({ columns }) {
    return (
        <Grid container style={{ marginBottom: 20 }}>
            {
                columns.map((column, index) => {
                    return <Grid item xs={column.size} component='div' key={index} align='center'>
                        <Typography style={{ fontWeight: 500 }}>
                            {column.headerName}
                        </Typography>
                    </Grid>
                })
            }
        </Grid>
    );
}

function compareDate(fileArray, year, row) {
    const pastYears = new Date(new Date().setFullYear(new Date().getFullYear() - year));
    let stockDates = fileArray.map(file => new Date(file.created_at));
    let latestDate = new Date(Math.max(...stockDates.map(e => new Date(e))));

    if (latestDate.getTime() < pastYears.getTime())
        return null;
    return fileArray[fileArray.findIndex(file => new Date(file.created_at).getTime() === latestDate.getTime())].id;
}

function CustomAccordionCell({ column, row, onDelete, onUpdate, onVisualize, handleFiles, company }) {
    const classes = useStyles();

    const [value, setValue] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const firePermits = row.fire_permits && row.fire_permits.length > 0 ? compareDate(row.fire_permits, 1, row) : null;
    const visitPlans = row.visit_plans && row.visit_plans.length > 0 ? compareDate(row.visit_plans, 1, row) : null;
    const visitReports = row.visit_reports && row.visit_reports.length > 0 ? compareDate(row.visit_reports, 3, row) : null;

    let content = undefined;

    const handlePopoverOpen = (event, newValue) => {
        setValue(newValue);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setValue('');
    };

    const open = Boolean(anchorEl);

    const types = {
        'officeIcons': <>
            <span
                onMouseEnter={(e) => handlePopoverOpen(e, 'Rapport de visite')}
                onMouseLeave={handlePopoverClose}
            >
                <IconButton
                    onClick={() => handleFiles(company, row, 'visit_reports', visitReports)}
                    disabled={visitReports === null}
                >
                    <Apartment style={visitReports === null ? { color: '#d3d3d3' } : { color: '#86c5da' }} />
                </IconButton>
            </span>
            <span
                onMouseEnter={(e) => handlePopoverOpen(e, 'Plan de suivi et de visite')}
                onMouseLeave={handlePopoverClose}
            >
                <IconButton
                    onClick={() => handleFiles(company, row, 'visit_plans', visitPlans)}
                >
                    <Explore style={visitPlans === null ? { color: '#d3d3d3' } : { color: '#f2a900' }} />
                </IconButton>
            </span>
            <span
                onMouseEnter={(e) => handlePopoverOpen(e, 'Permis de feu')}
                onMouseLeave={handlePopoverClose}
            >
                <IconButton
                    onClick={() => handleFiles(company, row, 'fire_permits', firePermits)}
                >
                    <LocalFireDepartment style={firePermits === null ? { color: '#d3d3d3' } : { color: '#e25822' }} />
                </IconButton>
            </span>
            <Popover
                sx={{ pointerEvents: 'none' }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography style={{ padding: 10, color: '#fff', background: '#18284D' }}>{value}</Typography>
            </Popover>
        </>,
        'actions': <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <UpdateButton row={row} onUpdate={onUpdate} />
            <VisualizeButton row={row} onVisualize={onVisualize} />
            <SwitchButton row={row} onDelete={onDelete} />
        </div>
    };

    if (types[column.type] !== undefined) {
        content = types[column.type];
    } else {
        let properties = column.field.split('.');

        content = row;
        properties.forEach(property => {
            content = content[property];
        });
    }

    return (
        <Grid item xs={column.size} component="div" align='center' className={classes.gridAlign} style={column.style}>
            {content}
        </Grid>
    )
}

export default function CustomAccordion({ companies,onSoftDelete, onCreate, onVisualize, handleFiles, onCreateOffice, onDeleteCompany, onUpdateCompany, onUpdateOffice, onDepositExport, onReminderExport }) {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (key) => {
        setExpanded(expanded === key ? false : key);
    };

    return (
        <div>
            <Toolbar style={{ marginBottom: 20 }}>
                <Grid container align="flex-start" spacing={1}>
                    <Grid item xs={4}>
                        <Typography variant="h5" component="div" style={{ marginTop: 20, fontWeight: 500 }}>
                            Vos sociétés
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{ marginTop: 20 }}>
                        <Buttons.New createLabel={'Exporter les marchandises pour compte de tiers'} onCreate={onDepositExport} />
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: 20 }}>
                        <Buttons.New createLabel={'Exporter les événements'} onCreate={onReminderExport} />
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: 20 }}>
                        <Buttons.New createLabel={'Créer une société'} onCreate={onCreate} />
                    </Grid>
                </Grid>
            </Toolbar>
            <CustomColumnsAccordion columns={columnsCompanies} />
            {
                companies.map((company, index) => {
                    return <Accordion key={index} expanded={expanded === index} onClick={() => handleChange(index)}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                        >
                            {
                                columnsCompanies.map((column, index) => {
                                    return <CustomAccordionCell
                                        key={index}
                                        column={column}
                                        row={company}
                                        onUpdate={onUpdateCompany}
                                        onDelete={onDeleteCompany}
                                    />
                                })
                            }
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <CustomColumnsAccordion columns={columnsOffices} key={index} />
                                {
                                    company.offices.map(office => {
                                        return columnsOffices.map((column, secondIndex) => {
                                            return <CustomAccordionCell
                                                key={secondIndex}
                                                column={column}
                                                row={office}
                                                company={company}
                                                handleFiles={handleFiles}
                                                onVisualize={onVisualize}
                                                onUpdate={onUpdateOffice}
                                            />
                                        });
                                    })
                                }
                                <Grid item xs={5}></Grid>
                                {
                                    onCreateOffice
                                        ? <Grid item xs={2}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                fullWidth={true}
                                                startIcon={<Add />}
                                                onClick={() => onCreateOffice(company)}
                                            >
                                                Créer un site
                                            </Button>
                                        </Grid>
                                        : <></>
                                }
                                <Grid item xs={5}></Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                })
            }
        </div>
    );
}
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Toolbar, Grid, Typography, Paper
} from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DeleteButton } from '@components/Button/DeleteButton';
import { Buttons } from '@components/Button/Buttons';
import { UpdateButton } from '@components/Button/UpdateButton';
import { VisualizeButton } from '@components/Button/VisualizeButton';
import CustomTableCell from './CustomTableCell';
import canModif from '@helpers/canModif';

const useStyles = makeStyles({
    container: {
        maxHeight: () => window.innerHeight * 0.8 + 'px',
        overflow: 'auto',
    },
    end: {
        background: 'rgba(50, 50, 50, 0.1)',
        opacity: 0.4
    },
    headerColumn: {
        display: 'flex',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer'
        }
    }
});


const CustomTableColumns = (({ columns, rows, isActions }) => {
    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {
                    columns.map((column, index) => {
                        return <TableCell
                            key={index}
                            align='center'
                            fontWeight="fontWeightBold"
                            onClick={columns.some(e => e.headerName.toLowerCase() === 'événement')
                                ? () => column.sortColumn(index, rows)
                                : null}
                        >
                            <div className={columns.some(e => e.headerName.toLowerCase() === 'événement') ? classes.headerColumn : null}>
                                {column.headerName}
                                {columns.some(e => e.headerName.toLowerCase() === 'événement') ? <ArrowDropDownIcon id={'arrow-' + index} /> : <></>}
                            </div>
                        </TableCell>
                    })
                }
                {
                    isActions
                        ? <TableCell align='right' fontWeight="fontWeightBold">Actions</TableCell>
                        : <></>
                }
            </TableRow>
        </TableHead>
    );
});

export default function CustomTable({ columns, rows, title, onVisualize, onCreate, createLabel, onUpdate, onDelete, onChange,
    onReserve, onExport, onClickIcon, handleUpload, handleDownload, handleChangeRole, handleChangeStatus,
    handleChangeWorking, setOpen }) {

    const classes = useStyles();

    const user = useSelector(state => state.userReducer.userInfos);

    return (
        <TableContainer component={Paper} className={classes.container}  >
            <Toolbar>
                <Grid container align="flex-start">
                    <Grid item xs={onExport ? 7 : 10}>
                        <Typography
                            className={classes.title}
                            variant="h5"
                            component="div"
                            style={{ marginTop: 20, fontWeight: 500 }}
                        >
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Buttons.New createLabel={createLabel} onCreate={onCreate} />
                    </Grid>
                    {
                        onExport
                            ? <Grid item xs={2} style={{ marginLeft: 50 }}>
                                <Buttons.New createLabel={'Exporter la liste d\'événements'} onCreate={onExport} />
                            </Grid>
                            : <></>
                    }
                </Grid>
            </Toolbar>
            <Table className={classes.table} size='medium' stickyHeader aria-label="sticky table">
                <CustomTableColumns columns={columns} rows={rows} isActions={onUpdate === undefined && onVisualize === undefined && onDelete === undefined ? false : true} />
                <TableBody>
                    {
                        rows.map((row, index) => (
                            <TableRow key={`${index}-row`} className={(row && row.status && row.status !== 0) || row.waiver ? classes.end : classes.pending}>
                                {
                                    columns.map((column, index) => {
                                        return <CustomTableCell
                                            key={index}
                                            onChange={onChange}
                                            column={column}
                                            row={row}
                                            onClickIcon={onClickIcon}
                                            handleUpload={handleUpload}
                                            handleDownload={handleDownload}
                                            handleChangeRole={handleChangeRole}
                                            handleChangeStatus={handleChangeStatus}
                                            handleChangeWorking={handleChangeWorking}
                                            onReserve={onReserve}
                                            setOpen={setOpen}
                                        />
                                    })
                                }
                                <TableCell component="th" scope="row" align='right'>
                                    <VisualizeButton row={row} onVisualize={onVisualize} />
                                    {
                                        canModif(row, user)
                                            ? <>
                                                <UpdateButton row={row} onUpdate={onUpdate} />
                                                <DeleteButton row={row} onDelete={onDelete} />
                                            </>
                                            : <></>
                                    }
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
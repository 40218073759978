import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {Grid, Typography, Button, Checkbox} from '@material-ui/core';
import SendIcon from '@mui/icons-material/Send';
import { CustomForm } from '@components/Form/CustomForm';
import { companyActions } from '@actions/company.actions';
import {alertActions} from "../../../actions/alert.actions";

export default function CreateCompany() {
    const { register, handleSubmit, reset } = useForm();
    const history = useHistory();

    const dispatch = useDispatch();
    const company = useSelector(state => state.companyReducer);

    const [keepData, setKeepData] = useState({
        name: undefined,
        contractRC: undefined,
        propertyDamage: undefined,
        limitAmountOfCapitals: 0,
        contactPerson: {
            lastname: undefined,
            firstname: undefined,
            phone: undefined,
            email: undefined,
            job: undefined
        },
        accountingPerson: {
            lastname: undefined,
            firstname: undefined,
            phone: undefined,
            email: undefined,
            job: undefined
        },
        rate: 0,
        comment: ''
    });

    const [showGoods, setShowGoods] = useState(false);

    const handleGoodsCheckboxChange = (event) => {
        setShowGoods(event.target.checked);
    };
    const form = {
        title: "Création d`une société",
        lines: [
            [
                { label: `Société`, field: `name`, data: keepData.name }
            ],
        ]
    }

    const contactPersonForm = {
        lines: [
            [
                { label: `Nom de la personne de contact`, field: `contactPerson.lastname`, data: keepData?.contactPerson?.lastname },
                { label: `Prénom de la personne de contact`, field: `contactPerson.firstname`, data: keepData?.contactPerson.firstname }
            ],
            [
                { label: `Téléphone de la personne de contact`, field: `contactPerson.phone`, data: keepData?.contactPerson.phone },
                { label: `E-mail de la personne de contact`, field: `contactPerson.email`, data: keepData?.contactPerson.email },
                { label: `Role de la personne de contact`, field: `contactPerson.job`, data: keepData?.contactPerson.job }
            ]
        ],
    }

    const contactAccountingForm = {
        lines: [
            [
                { label: `Nom de la personne de comptabilité`, field: `accountingPerson.lastname`, data: keepData?.accountingPerson?.lastname },
                { label: `Prénom de la personne de comptabilité`, field: `accountingPerson.firstname`, data: keepData?.accountingPerson?.firstname }
            ],
            [
                { label: `Téléphone de la personne de comptabilité`, field: `accountingPerson.phone`, data: keepData?.accountingPerson?.phone },
                { label: `E-mail de la personne de comptabilité`, field: `accountingPerson.email`, data: keepData?.accountingPerson?.email },
                { label: `Role de la personne de comptabilité`, field: `accountingPerson.job`, data: keepData?.accountingPerson?.job }
            ],
        ]
    }

    const depositForm = {
        lines: [
            [
                { label: `Limite des montants de capitaux`, field: `limitAmountOfCapitals`, data: keepData?.limitAmountOfCapitals, type: 'euro' },
            ],
            [
                { label: 'Taux annuel TTC (‰)', field: 'rate', data: keepData?.rate },
            ],
            [
                { label: `RC`, field: `contractRC`, data: keepData?.contractRC },
                { label: `Dommages aux biens`, field: `propertyDamage`, data: keepData?.propertyDamage }
            ],
        ]
    }

    const onSubmit = (properties) => {
        if (
            properties.contactPerson.lastname !== '' &&
            properties.contactPerson.firstname !== '' &&
            properties.contactPerson.phone !== '' &&
            properties.contactPerson.email !== '' &&
            properties.contactPerson.job !== ''
        ) {
            if (!showGoods) {
                properties.limitAmountOfCapitals = 0;
                properties.rate = 0;
                properties.accountingPerson = {
                    lastname: '',
                    firstname: '',
                    phone: '',
                    email: '',
                    job: ''
                };
            }

            properties.name = properties.name ?? keepData.name;
            properties.contractRC = properties.contractRC ?? keepData.contractRC;
            properties.propertyDamage = properties.propertyDamage ?? keepData.propertyDamage;

            properties.contactPerson = {
                lastname: properties.contactPerson.lastname !== '' ? properties.contactPerson.lastname : keepData.contactPerson.lastname,
                firstname: properties.contactPerson.firstname !== '' ? properties.contactPerson.firstname : keepData.contactPerson.firstname,
                phone: properties.contactPerson.phone !== '' ? properties.contactPerson.phone : keepData.contactPerson.phone,
                email: properties.contactPerson.email !== '' ? properties.contactPerson.email : keepData.contactPerson.email,
                job: properties.contactPerson.job !== '' ? properties.contactPerson.job : keepData.contactPerson.job
            };

            properties.accountingPerson = {
                firstname: properties.accountingPerson.firstname ?? keepData?.accountingPerson?.firstname,
                lastname: properties.accountingPerson.lastname ?? keepData?.accountingPerson?.lastname,
                phone: properties.accountingPerson.phone ?? keepData?.accountingPerson?.phone,
                email: properties.accountingPerson.email ?? keepData?.accountingPerson?.email,
                job: properties.accountingPerson.job ?? keepData?.accountingPerson?.job
            };

            properties.offices = [];

            dispatch(companyActions.create(properties));
            setKeepData(properties);
            reset({});
        } else {
            dispatch(alertActions.error('Les informations concernant la personne de contact sont obligatoires'));
        }
    };



    const onClick = () => {
        history.goBack();
    }

    useEffect(() => {
        if (company.action === 'company/createSuccess') {
            dispatch(companyActions.clearSuccess());
            history.goBack();
        }
    }, [company, dispatch, history]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container style={{ margin: '50px 0' }}>

                <Grid item xs={12}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9}>
                            <Typography variant="h6" fontWeight="fontWeightBold">
                                Information d'une société
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button variant="outlined" color='secondary' onClick={onClick}>Retour</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={10}>
                            <Grid container spacing={3}>

                                <Grid item xs={12}>
                                    <CustomForm title={''} lines={form.lines} register={register} />
                                </Grid>

                                <Grid item xs={12}>
                                    <CustomForm title={'Contact'} lines={contactPersonForm.lines} register={register} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Checkbox checked={showGoods} onChange={handleGoodsCheckboxChange} />
                                    <label>Afficher la partie Marchandises pour compte de Tiers et Comptabilité</label>
                                </Grid>
                                {showGoods && (
                                    <Grid item xs={12}>
                                        <CustomForm title={'Comptabilité'} lines={contactAccountingForm.lines} register={register} />
                                    </Grid>
                                )}
                                {showGoods && (
                                    <Grid item xs={12}>
                                        <CustomForm title={'Marchandises'} lines={depositForm.lines} register={register} />
                                    </Grid>
                                )}
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>


                <Grid item xs={12} style={{ margin: 50 }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center">
                            <Button size="large" variant='outlined' color='secondary' startIcon={<SendIcon />} type="submit">
                                SAUVEGARDER
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}
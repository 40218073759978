import { Api } from '@config/api';

export const exportService = {
    getExportedDeposits,
};

function getExportedDeposits(properties) {
    return Api.post(`/companies/deposits`, properties).then(handleResponse);
}

function handleResponse(response) {
    if (response.status === 200 || response.status === 201)
        return response.data;
    else if (response.status === 401) {
        return;
    } else {
        const error = (response && response.message) || response.statusText;

        return Promise.reject(error);
    }
}
import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    loading: {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        display: 'block',
        zIndex: 10000,
        overflow: 'hidden',
        top: 0,
        left: 0,
        background: 'rgba(0, 0, 0, 0.2)'
    },
    loadingCircle: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
}));

function Loading() {
    const classes = useStyles();

    return (
        <div className={classes.loading}>
            <CircularProgress color='secondary' className={classes.loadingCircle} />
        </div>
    );
}

export default Loading;
const initialState = {
    action: '',
    loading: false,
    error: {},
    bills: []
}

export default function billReducer(state = initialState, action) {
    switch (action.type) {
        case 'bill/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true,
            };
        case 'bill/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                bills: [...state.bills, action.bill],
            };
        case 'bill/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'bill/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true,
            };
        case 'bill/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'bill/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'bill/removeRequest':
            return {
                ...state,
                action: action.type,
                loading: false
            };
        case 'bill/removeSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                bills: [...state.bills.filter(bill => bill.id !== action.id)]
            };
        case 'bill/removeFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'bill/getAllRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'bill/getAllSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                bills: action.bills
            };
        case 'bill/getAllFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'bill/getFileRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'bill/getFileSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                file: action.file
            };
        case 'bill/getFileFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'bill/clearSuccess':
            return {
                ...state,
                action: action.type
            }
        default:
            return state;
    }
}
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Button} from '@material-ui/core';
import { CustomForm } from '@components/Form/CustomForm';
import { SaveButton } from '@components/Button/SaveButton';
import { officeActions } from '@actions/office.actions';
import OfficeSubAppBar from '../Office';
import Header from '../Header/Header';

export default function NewFire() {
    const { register, handleSubmit } = useForm();
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const { companyId, officeId } = useParams();
    const history = useHistory();

    const [fileData, setFileData] = useState(null);
    const [keepData, setKeepData] = useState({
        comment: undefined,
    });
    let keepDataChanged = {
        comment: keepData.comment,
    };

    const dispatch = useDispatch();
    const officeReducer = useSelector(state => state.officeReducer);

    const handleFileChanged = (e) => {
        setFileData(e.target.files[0]);
        setKeepData(keepDataChanged);
    }

    const onSubmit = () => {
        setIsButtonDisabled(true);
        const data = new FormData();

        data.append('file', fileData);
        dispatch(officeActions.uploadDocument(companyId, officeId, 'fire_permits', data));
        setKeepData(keepDataChanged);
    }
    useEffect(() => {
        if (officeReducer.action === 'office/uploadDocumentSuccess') {
            setIsButtonDisabled(false);
        } else if (officeReducer.action === 'office/uploadDocumentFailure') {
            setIsButtonDisabled(false);
        }
    }, [officeReducer, dispatch]);

    const handleChange = (e) => {
        keepDataChanged = {...keepDataChanged, [e.target.name]: e.target.value};
    }

    const onClick = () => history.goBack();

    const form = {
        title: "Ajout d'un permis de feu",
        lines: [
            [
                { label: 'Fichier', field: 'file', type: 'file', data: fileData}
            ]
        ]
    }

    useEffect(() => {
        if (officeReducer.action === 'office/uploadDocumentSuccess') {
            dispatch(officeActions.clearSuccess());
            history.goBack();
        }
    }, [officeReducer, history, dispatch]);

    return (
        <div>
            <OfficeSubAppBar companyId={companyId} officeId={officeId} />
            <Header />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={9}>
                                <Typography variant="h6" fontWeight="fontWeightBold">
                                    {form.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="outlined" onClick={onClick}>Retour</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ margin: '50px 0' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={11} style={{ textAlign: 'center'}}>
                                <CustomForm title={''} lines={form.lines} register={register} handleFileChanged={handleFileChanged} onChange={handleChange} />
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: 50 }}>
                                <Grid container justifyContent="center">
                                    <SaveButton disabled={isButtonDisabled} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
const initialState = {
    action: '',
    loading: false,
    error: {},
    certifications: []
}

export default function certificationReducer(state = initialState, action) {
    switch (action.type) {
        case 'certification/createRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'certification/createSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                certifications: [...state.certifications, action.certification]
            };
        case 'certification/createFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'certification/updateRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'certification/updateSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {}
            };
        case 'certification/updateFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'certification/getAllRequest':
            return {
                ...state,
                action: action.type,
                loading: true
            };
        case 'certification/getAllSuccess':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: {},
                certifications: action.certifications
            };
        case 'certification/getAllFailure':
            return {
                ...state,
                action: action.type,
                loading: false,
                error: action.error
            };
        case 'certification/clearSuccess':
            return {
                ...state,
                action: action.type
            };
        default:
            return state;
    }
}
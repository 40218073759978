import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CloudUpload } from '@material-ui/icons';
import { Button, Card, CardContent, CardHeader, Divider, Grid, makeStyles } from '@material-ui/core';
import FileModal from '@components/Modal/FileModal.js';

const useStyles = makeStyles(() => ({
    fileCard: {
        width: '100vw',
        margin: 20
    },
    fileContent: {
        textAlign: 'center'
    },
    fileUpload: {
        marginTop: 20,
        width: 300,
        height: 50
    },
    fileName: {
        '& > h2': {
            fontSize: 20,
            fontWeight: 400
        }
    }
}));

function NoFeedBack({ event, eventId, cycleId }) {
    const classes = useStyles();

    const user = useSelector(state => state.userReducer.userInfos);

    const [open, setOpen] = useState(false);
    const [imagesArray, setImagesArray] = useState([]);
    const [formData, setFormData] = useState([]);

    const onImageChange = (e) => setImagesArray([...imagesArray, e.target.files]);

    const onFileUploaded = (e) => {
        e.preventDefault();
        const data = new FormData();
        let names = [];
        let dataTmp = [];

        for (const key of Object.keys(imagesArray)) {
            data.append('files', imagesArray[key][0]);
            dataTmp.push(imagesArray[key][0]);
            names.push(imagesArray[key][0].name);
        }
        setFormData(data);
        setOpen(true);
    }

    return (
        <div>
            <Card className={classes.fileCard}>
                <CardHeader title='Pièce(s) justificative(s)' />
                <CardContent className={classes.cardContent}>
                    <Divider variant='fullWidth' />
                    <Grid container className={classes.fileContent}>
                        {
                            user.role === 'viewer'
                            ? <></>
                            : <Grid item xs={12}>
                                <input
                                    style={{ display: 'none' }}
                                    id='file-event'
                                    multiple
                                    type='file'
                                    onChange={onImageChange}
                                />
                                <label htmlFor='file-event'>
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        component='span'
                                        className={classes.fileUpload}
                                        startIcon={<CloudUpload />}
                                        type='file'
                                    >
                                        Ajouter
                                    </Button>
                                </label>
                            </Grid>
                        }
                        {
                            imagesArray.length > 0
                            ? <Grid item xs={12}>
                                {imagesArray.map(image => <h4 key={image[0].name}>{image[0].name}</h4>)}
                                <Button
                                    variant='outlined'
                                    color='secondary'
                                    component='span'
                                    onClick={onFileUploaded}
                                    className={classes.fileUpload}
                                    startIcon={<CloudUpload />}
                                    type='submit'
                                >
                                    Mettre en ligne
                                </Button>
                            </Grid>
                            : <></>
                        }
                        <Grid className={classes.fileName} item xs={12}>
                            {
                                user.role === 'viewer'
                                ? <h2>Aucune pièce justificative ajoutée pour le moment.</h2>
                                : <h2>Choisissez un fichier et ajoutez une pièce justificative</h2>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <FileModal
                open={open}
                setOpen={setOpen}
                event={event}
                eventId={eventId}
                cycleId={cycleId}
                filesData={formData}
            />
        </div>
    );
}

export default NoFeedBack;